<template>
  <div>
    <textarea-input-vertical
      :id="id"
      :name="name"
      :label="label"
      :rules="textareaRules"
      :rows="rows"
      :placeholder="placeholder"
      :value="value"
      @input="onInput">
      <template #errors="{ errors }">
        <div class="col-xs-6 error-text top-5 no-padding">
          {{ errors }}
        </div>
      </template>
    </textarea-input-vertical>

    <div class="text-right dark-gray-text top-5">
      <span :class="{ 'error-text': wordLimitPassed }"><i class="counter">{{ wordCount }} / {{ maxWords }} Words</i></span>
    </div>
  </div>
</template>

<script>
import TextareaInputVertical from 'vue-app/shared/components/textarea-input-vertical.vue';
import { words } from 'lodash';

export default {
  name: 'TextareaInputVerticalWithWordCount',

  components: {
    TextareaInputVertical
  },

  props: {
    id: {
      type: String,
      required: true
    },

    value: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      required: true
    },

    label: {
      type: String,
      required: false
    },

    maxWords: {
      type: Number,
      default: 200
    },

    rules: {
      type: String,
      default: ''
    },

    rows: {
      type: Number,
      default: 4
    },

    placeholder: {
      type: String,
      required: false
    }
  },

  computed: {
    wordLimitPassed() {
      return this.wordCount > this.maxWords;
    },

    wordCount() {
      return words(this.value).length;
    },

    textareaRules() {
      return `${this.rules}|maxWords:${this.maxWords}`;
    }
  },

  methods: {
    onInput(newValue) {
      this.$emit('input', newValue);
    }
  }
};
</script>
