<template>
  <div>
    <div class="row top-20">
      <div class="col-sm-10 col-sm-offset-1">
        <div class="shadowed-box">
          <div class="box-content variable-padded">
            <div class="bottom-30 bold-weight size-text-28px">
              Welcome to Priori!
            </div>

            <div class="purple-label bold-weight bottom-30 size-text-15px">
              Great, we&rsquo;ve received your profile. Check your email for next steps!
            </div>

            <div class="bold-weight size-text-18px">
              Our Process
            </div>

            <hr>

            <div class="size-text-14px">
              <div class="bottom-20">
                Thank you for taking the time to submit the Priori Information Form. You will receive a confirmation email with a link asking you to confirm your email address.
              </div>

              <div>
                This will allow you to login to your profile and keep your information up to date. We&rsquo;ll be in touch as soon as we have feedback on the opportunity you are interested in, but if you have any questions in the meantime, let us know at <a class="pseudo-link-purple bold-weight" href="mailto:admin@priorilegal.com">admin@priorilegal.com</a>.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ai-experience-modal
      ref="aiExperienceModal"
      :resume-id-token="resumeIdToken">
    </ai-experience-modal>
  </div>
</template>

<script>
import AiExperienceModal from 'vue-app/marketplace/lawyer/shared/ai-experience-modal.vue';

export default {
  name: 'LawyerBaseProfileSubmitted',

  components: {
    AiExperienceModal
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      resumeIdToken: this.initData.resumeIdToken
    };
  },

  mounted() {
    if (this.resumeIdToken) {
      this.$refs.aiExperienceModal.openModal();
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/globals/variables";

  hr {
    margin-top: 10px;
    border-color: $medium-gray
  }
</style>
