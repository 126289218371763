<template>
  <div>
    <label class="size-text-13px">Bar Licensing
      <span v-if="requireBarAdmission" class="inline-help required">*</span>
      <span v-else class="missing-text normal-weight">(Optional)</span>
    </label>

    <div v-for="(bar, index) in lawyerApplication.barAdmissionsAttributes">
      <div v-if="index > 0" class="top-20 bottom-20">
        <hr class="kenny-hr no-margin">
      </div>

      <div class="row md-columns">
        <div class="col-sm-3">
          <typeahead-vertical
            :id="`bar-license-country-${index}`"
            :options="countries"
            :force-select="true"
            placeholder="Country"
            :hide-icon="true"
            :rules="{ required: true, valueInOptions: { options: countries } }"
            v-model="bar.country"
            :initial-value="bar.country"
            @input="barCountrySelected(bar, index)">
          </typeahead-vertical>
        </div>

        <div class="col-sm-3 top-10-xs">
          <typeahead-vertical
            :ref="`stateTypeahead${index}`"
            :id="`bar-license-state-${index}`"
            :options="states"
            :force-select="true"
            :placeholder="bar.country === 'United States' ? 'State' : 'N/A'"
            :hide-icon="true"
            :disabled="bar.country !== 'United States'"
            :rules="{ required: bar.country === 'United States', valueInOptions: { options: states } }"
            v-model="bar.state"
            :initial-value="bar.state">
          </typeahead-vertical>
        </div>

        <div class="col-sm-3 top-10-xs">
          <year-select-vertical
            :id="`bar-license-year-${index}`"
            placeholder="Year"
            rules="required"
            :value="getYear(bar.admitDate)"
            @input="year => updateYear(bar, year)">
          </year-select-vertical>
        </div>

        <div class="col-sm-3 top-10-xs">
          <text-input-vertical
            :id="`bar-license-number-${index}`"
            placeholder="License no. (optional)"
            v-model="bar.license">
          </text-input-vertical>

          <span v-if="canRemoveBarLicense(index)" @click="onRemoveBarLicense(index)">
            <svg-icon name="x3" class="deselect-icon"></svg-icon>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import YearSelectVertical from 'vue-app/shared/components/year-select-vertical.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'BarLicenseSection',

  components: {
    TypeaheadVertical,
    YearSelectVertical,
    SvgIcon
  },

  props: {
    lawyerApplication: {
      type: Object,
      required: true
    },

    countries: {
      type: Array,
      required: true
    },

    states: {
      type: Array,
      required: true
    },

    requireBarAdmission: {
      type: Boolean,
      required: true
    },

    onRemoveBarLicense: {
      type: Function,
      required: true
    }
  },

  methods: {
    getYear(date) {
      return date ? Number(DateFilter.year(date)) : null;
    },

    updateYear(bar, year) {
      this.$set(bar, 'admitDate', `${year}-01-01`);
    },

    canRemoveBarLicense(index) {
      return !this.requireBarAdmission || index > 0;
    },

    barCountrySelected(barAdmission, index) {
      if (barAdmission.country !== 'United States') {
        barAdmission.state = '';
        this.$refs[`stateTypeahead${index}`][0].reset();
      }
    }
  }
};
</script>

<style scoped lang="scss">
  .deselect-icon {
    right: 1px;
  }
</style>
