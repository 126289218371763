<template>
  <div class="box-content">
    <loading-section name="comparisonView">
      <div class="row a-form">
        <div class="col-sm-4">
          <div class="comparison-header bottom-15">
            Category
          </div>

          <label class="check-option vertical medium-large">
            <span class="normal-weight size-text-12px">Average Recommendation Score</span>
            <input type="radio" id="recommendation" name="categorySelection" value="recommendation" v-model="selectedComparison">
            <span class="check c-radio"></span>
          </label>

          <label v-for="object in categoryList" :key="object" class="check-option vertical medium-large">
            <span class="normal-weight size-text-12px">{{ object }}</span>
            <input type="radio" :id="object" name="categorySelection" :value="object" v-model="selectedComparison">
            <span class="check c-radio"></span>
          </label>
        </div>

        <div class="col-sm-8 top-30-xs">
          <comparison-table
            :comparison-data="comparisonObject"
            :selected-comparison="selectedComparison"
            :resource-type="resourceType"
            :on-item-selected="onItemSelected">
          </comparison-table>

          <priori-modal v-if="resultIds.length > 10" :title="formattedTitle" class="top-10" @on-modal-open="showAll">
            <template #modal-trigger="{ openModal }">
              <a href="" class="bold-weight" @click.prevent="openModal">Show more ({{ resultsCount }})</a>
            </template>

            <comparison-table
              :comparison-data="allComparisonData"
              :selected-comparison="selectedComparison"
              :resource-type="resourceType"
              :on-item-selected="onItemSelected">
            </comparison-table>
          </priori-modal>
        </div>
      </div>
    </loading-section>
  </div>
</template>

<script>
import ComparisonTable from 'vue-app/scout/client/shared/comparison-module/comparison-table.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import ScoutLawyerBridge from 'resources/scout/scout-lawyer-bridge.js';
import ScoutFirmBridge from 'resources/scout/scout-firm-bridge.js';

export default {
  name: 'PerformanceComparison',

  components: {
    LoadingSection,
    ComparisonTable,
    PrioriModal
  },

  props: {
    resultIds: {
      type: Array,
      required: true
    },

    resourceType: {
      type: String,
      required: true
    },

    onItemSelected: {
      type: Function,
      required: true
    },

    onComparisonDataLoaded: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedComparison: 'recommendation',
      resultItems: [],
      comparisonData: {},
      allComparisonData: []
    };
  },

  computed: {
    comparisonObject() {
      return (this.comparisonData[this.selectedComparison] || []).slice(0, 10);
    },

    categoryList() {
      // removing recommendation as that's treated differently
      return Object.keys(this.comparisonData).filter((key) => key !== 'recommendation');
    },

    resultsCount() {
      return Math.min(this.resultIds.length, 100);
    },

    isRecommendationSelected() {
      return this.selectedComparison === 'recommendation';
    },

    formattedTitle() {
      return this.isRecommendationSelected ? 'Average Recommendation Score' : this.selectedComparison;
    }
  },

  mounted() {
    LoadingService.loading('comparisonView');

    const bridge = this.resourceType === 'law_firms' ? ScoutFirmBridge : ScoutLawyerBridge;

    bridge.reviewComparison({ result_list: JSON.stringify(this.resultIds), view: 'review_summary' })
      .then((response) => {
        const data = JSON.parse(response.request.response);
        this.comparisonData = data['top_by_field'];
        this.onComparisonDataLoaded(data);
      })
      .finally(() => {
        LoadingService.done('comparisonView');
      });
  },

  methods: {
    showAll() {
      this.allComparisonData = this.comparisonData[this.selectedComparison];
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .comparison-header {
    border-bottom: 1px solid $gray-7;
    font-weight: 600;
    padding-bottom: 4px;
  }

  .a-form .check-option.vertical {
    margin-bottom: 15px;
  }
</style>
