<template>
  <div class="row top-20">
    <div class="col-sm-10 col-sm-offset-1">
      <div class="shadowed-box">
        <div class="box-content variable-padded">
          <div class="bottom-30 bold-weight size-text-28px">
            Welcome to Priori!
          </div>

          <div class="purple-label bold-weight bottom-30 size-text-15px">
            Great, we&rsquo;ve received your profile. Check your email for next steps!
          </div>

          <div class="bold-weight size-text-18px">
            Our Process
          </div>

          <hr>

          <div class="size-text-14px">
            <div class="bottom-20">
              Thank you for joining the Priori network. Please confirm your email address by clicking the link in the email we just sent you. Sometimes this email goes into spam or junk folders, so please check there if you don&rsquo;t see the email. While you&rsquo;re at it, please also add <a href="mailto:admin@priorilegal.com" class="pseudo-link-purple bold-weight">admin@priorilegal.com</a> to your contacts list to ensure that future emails arrive in the proper inbox. After confirming your email address, you will be able to access your account by visiting <a href="https://app.priorilegal.com/sign-in" class="pseudo-link-purple bold-weight">https://app.priorilegal.com/sign-in</a> and logging in using the email and password you provided.
            </div>

            <div>
              Please don&rsquo;t hesitate to message us if you have any questions as you set up your account. You can also learn more about our platform by checking out our <a href="https://docs.lawyer.priorilegal.com" class="pseudo-link-purple bold-weight">Guide</a>.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LawyerSignupSubmittedObo'
};
</script>

<style scoped lang="scss">
  @import "stylesheets/globals/variables";

  hr {
    margin-top: 10px;
    border-color: $medium-gray
  }
</style>
