<template>
  <div class="shadowed-box">
    <div class="box-content size-text-13px variable-padded">
      <div class="size-text-18px bold-weight">
        Next Steps
      </div>

      <hr class="top-10 bottom-15">

      <div class="bottom-30 size-text-14px">
        Below are the next steps for getting started and submitting your application. We will reach out to you via email to schedule your video call and reference check once you’ve submitted your application.
      </div>

      <div class="d-flex col-gap-20 bottom-30">
        <span class="purple-label nowrap bold-weight size-text-15px">
          Step 1
        </span>

        <span>
          <div class="size-text-15px bold-weight bottom-5">
            Application Review
          </div>

          <div>
            Once we have reviewed your application, we’ll let you know by email if we’re moving forward to schedule an interview or if your application is on hold for the moment. Until we’ve fully reviewed your application, we won’t generally consider matching you with open projects.
          </div>
        </span>
      </div>

      <div class="d-flex col-gap-20 bottom-30">
        <span class="purple-label nowrap bold-weight size-text-15px">
          Step 2
        </span>

        <span>
          <div class="size-text-15px bold-weight bottom-5">
            Prepare References
          </div>

          <div>
            As part of the application process, attorneys must provide two professional references: one from a recent client and one from a recent attorney colleague. References are taken by email, so selecting and notifying these references in advance helps speed up our review of your application.
          </div>
        </span>
      </div>

      <div class="d-flex col-gap-20 bottom-40">
        <span class="purple-label nowrap bold-weight size-text-15px">
          Step 3
        </span>

        <span>
          <div class="size-text-15px bold-weight bottom-5">
            Additional Profile Details
          </div>

          <div>
            You can explore your pending <a href="/lawyer-app/profile" class="bold-weight purple-link" target="_self">Priori profile</a> and <a href="/lawyer-app/matching-preferences" class="bold-weight purple-link" target="_self">matching information</a> to add important details (e.g., industry experience, proficiencies, representative matters) to be considered during the application review period. This information isn’t required to process your application, but additional detail is helpful in expediting the vetting and matching processes.
          </div>
        </span>
      </div>

      <button type="button" class="primary-btn-purple a-button-size fit-content" @click="goToDashboard">Go to Dashboard</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NextStepsPage',

  methods: {
    goToDashboard() {
      window.location.href = '/lawyer-app/dashboard';
    }
  }
};
</script>

<style scoped lang="scss">
  @use "stylesheets/globals/colors";

  .purple-link {
    color: colors.$purple-5;

    &:hover, &:focus {
      color: colors.$purple-5;
    }
  }
</style>
