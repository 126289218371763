import BaseResource from 'resources/base-resource';
import axios from 'axios';

class LawyerApplication extends BaseResource {
  static baseUrl = '/lawyer_applications/:id.json';

  static submit(id, lawyer_application) {
    return axios.post(`/lawyer_applications/${id}/submit.json`, { lawyer_application });
  }
}

export default LawyerApplication;

