<template>
  <div>
    <label :for="`question-${question.id}-min`" :class="{ 'disabled-text': !question.isIncluded }">
      {{ question.questionText }} <span class="inline-help required" v-if="question.isRequired">*</span>
      <scout-tag v-if="showQuestionUpdateTag" :scout-tag="tagData"></scout-tag>
    </label>

    <div class="left-10">
      <div class="row tight-columns">
        <div :class="{'col-sm-5': isAdminForm, 'col-sm-3': !isAdminForm}">
          <currency-input-vertical
            v-model="answerMin"
            :id="`question-${question.id}-min`"
            :name="`question${question.id}Min`"
            placeholder="Bottom of range"
            :rules="validationRulesMin"
            @input="handleInput"
            validation-mode="eager"
            :disabled="!question.isIncluded">
          </currency-input-vertical>
        </div>

        <div class="col-sm-1 text-center top-10 hidden-xs">
          &mdash;
        </div>

        <div :class="['top-10-xs', {'col-sm-5': isAdminForm, 'col-sm-3': !isAdminForm}]">
          <currency-input-vertical
            v-model="answerMax"
            :id="`question-${question.id}-max`"
            :name="`question${question.id}Max`"
            placeholder="Top of range (optional)"
            :rules="validationRulesMax"
            :custom-error-messages="{ minValue: `* Can't be less than bottom of range` }"
            @input="handleInput"
            validation-mode="eager"
            :disabled="!question.isIncluded || !answerMin">
          </currency-input-vertical>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyInputVertical from 'vue-app/shared/components/currency-input-vertical.vue';
import ScoutTag from 'vue-app/scout/shared/scout-tags/scout-tag.vue';

import { startCase } from 'lodash';

export default {
  name: 'QuoteFormQuestionRange',

  components: {
    CurrencyInputVertical,
    ScoutTag
  },

  props: {
    minValue: {
      type: [String, Number]
    },

    maxValue: {
      type: [String, Number]
    },

    isRange: {
      type: Boolean,
      default: false
    },

    question: {
      type: Object,
      required: true
    },

    onChange: {
      type: Function,
      required: true
    },

    showQuestionUpdateTag: {
      type: Boolean,
      default: false
    },

    isAdminForm: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      answerMin: this.minValue ? Number.parseFloat(this.minValue).toFixed(2) : null,
      answerMax: this.maxValue ? Number.parseFloat(this.maxValue).toFixed(2) : null
    };
  },

  computed: {
    validationRulesMin() {
      return {
        minValue: 0.01,
        required: this.question.isIncluded && (this.question.isRequired || !!this.answerMax)
      };
    },

    validationRulesMax() {
      return {
        minValue: `@question${this.question.id}Min`
      };
    },

    tagData() {
      return {
        color: 'yellow',
        name: this.activityText
      };
    },

    activityText() {
      return startCase(this.question.latestActivity?.activityType);
    }
  },

  methods: {
    handleInput() {
      this.onChange([this.answerMin, this.answerMax]);
    }
  }
};
</script>
