<template>
  <div>
    <div class="row md-columns bottom-30">
      <div class="col-sm-6">
        <label class="size-text-13px">{{ labelText }} <span class="inline-help required">*</span></label>

        <typeahead-vertical
          placeholder="School Name"
          id="law-school"
          input-name="schoolName"
          :options="schoolNames"
          :limit="8"
          :force-select="false"
          :hide-icon="true"
          v-model="lawyerApplication.schoolsAttributes[0].name"
          :initial-value="lawyerApplication.schoolsAttributes[0].name"
          rules="required"
          @input="onSchoolSelected">
        </typeahead-vertical>
      </div>

      <div class="col-sm-6 top-30-xs">
        <label class="size-text-13px">Degree <span class="inline-help required">*</span></label>

        <typeahead-vertical
          :options="degrees"
          :force-select="false"
          :hide-icon="true"
          v-model="lawyerApplication.schoolsAttributes[0].degree"
          :initial-value="lawyerApplication.schoolsAttributes[0].degree"
          id="school-degree"
          option-value-key="abbreviation"
          option-label-key="label"
          input-name="shoolDegree"
          placeholder="Degree"
          rules="required">
        </typeahead-vertical>
      </div>
    </div>

    <div class="row md-columns">
      <div class="col-sm-6">
        <year-select-vertical
          :rules="`required|maxDate:${getYear(lawyerApplication.schoolsAttributes[0].dateAttained)}`"
          :value="getYear(lawyerApplication.schoolsAttributes[0].dateEntered)"
          @input="year => updateYear('dateEntered', year)"
          label="Start Year"
          name="schoolStartYear"
          id="school-start-year">
        </year-select-vertical>
      </div>

      <div class="col-sm-6 top-30-xs">
        <year-select-vertical
          :rules="`required|minDate:${getYear(lawyerApplication.schoolsAttributes[0].dateEntered)}`"
          :value="getYear(lawyerApplication.schoolsAttributes[0].dateAttained)"
          @input="year => updateYear('dateAttained', year)"
          label="End Year"
          name="schoolEndYear"
          id="school-end-year">
        </year-select-vertical>
      </div>
    </div>
  </div>
</template>

<script>
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import YearSelectVertical from 'vue-app/shared/components/year-select-vertical.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'LawSchoolSection',

  components: {
    TypeaheadVertical,
    YearSelectVertical
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    lawyerApplication: {
      type: Object,
      required: true
    },

    educationalInstitutes: {
      type: Array,
      required: true
    },

    degrees: {
      type: Array,
      required: true
    }
  },

  computed: {
    labelText() {
      return this.lawyer.accountType === 'lawyer' ? 'Law School' : 'Institution of Highest Award';
    },

    schoolNames() {
      return this.educationalInstitutes.map(institute => institute.name);
    }
  },

  methods: {
    onSchoolSelected(value) {
      const institute = this.educationalInstitutes.find(e => e.name === value);
      this.lawyerApplication.schoolsAttributes[0].educationalInstituteId = institute?.id;
    },

    getYear(date) {
      return date ? Number(DateFilter.year(date)) : null;
    },

    updateYear(key, year) {
      this.$set(this.lawyerApplication.schoolsAttributes[0], key, `${year}-01-01`);
    }
  }
};
</script>

