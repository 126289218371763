<template>
  <edit-modal :title="title" :input="note" :on-save="onSave">
    <template #modal-trigger="{ openModal }">
      <slot name="modal-trigger" :open-modal="openModal">
        <a href="" class="semibold-weight size-text-12px" @click.prevent="openModal">Edit</a>
      </slot>
    </template>

    <template #default="{ inputCopy: noteCopy }">
      <div class="pill-gray-text bottom-30">
        Enter a note title and text below.
      </div>

      <div class="bottom-30">
        <text-input-vertical
          label="Title"
          v-model="noteCopy.title">
        </text-input-vertical>
      </div>

      <label for="note-content">
        Note <span class="inline-help required">*</span>
      </label>

      <validation-provider rules="requiredHtml" :mode="passiveAggressive" v-slot="{ errors }">
        <tiptap-editor
          id="note-content"
          v-model="noteCopy.content">
        </tiptap-editor>

        <div class="error-text top-5">
          {{ errors[0] }}
        </div>
      </validation-provider>
    </template>

    <template #errorMessage>
      * One or more required fields are empty. Please fill in the required fields indicated above.
    </template>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import TiptapEditor from 'vue-app/scout/shared/tiptap-editor.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'EditNoteModal',

  components: {
    EditModal,
    TiptapEditor,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    title: {
      type: String,
      required: true
    },

    note: {
      type: Object,
      default: () => ({
        title: '',
        content: ''
      })
    },

    onSave: {
      type: Function,
      required: true
    }
  }
};
</script>
