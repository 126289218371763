<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit, failed: formInvalid }">
    <form role="form" name="form" class="a-form" novalidate>
      <div class="bottom-30">
        <div class="bottom-30 normal-weight pill-gray-text">
          Add individuals below to receive a notification about this RFP. Note they will need to request access to the RFP from their law firm.
        </div>

        <div class="small-bold-header">
          Law Firm Name
        </div>

        <div class="top-5 bottom-15">
          <hr class="no-margin">
        </div>

        <div class="semibold-weight bottom-30">
          {{ firmName }}
        </div>

        <div class="small-bold-header">
          Send Notification To:
        </div>

        <hr class="top-5">

        <div v-for="recipient in newAdditionalRecipients" class="field-group bottom-20">
          <contact-firm-additional-recipient
            :recipient="recipient"
            :on-remove="removeRecipient"
            :provisioned-emails="provisionedEmails"
            :additional-recipients="additionalRecipients.concat(newAdditionalRecipients)">
          </contact-firm-additional-recipient>
        </div>

        <a href="" class="bold-weight blue-text" @click.prevent="addAdditionalRecipient">+ Additional Notification</a>
      </div>

      <div class="row tight-columns">
        <div class="col-sm-4">
          <loading-button name="saveRecipients" lb-class="primary-btn-blue" :disabled="newAdditionalRecipients.length < 1" @lb-click="handleSubmit(save)">
            {{ saveButtonText }}
          </loading-button>
        </div>

        <div class="col-sm-3 top-20-xs">
          <button type="button" class="nv-button-white" @click="cancel">Cancel</button>
        </div>
      </div>

      <div v-if="formInvalid" class="error-text top-10">
        * One or more required fields are empty or invalid.
      </div>
    </form>
  </validation-observer>
</template>

<script>
import ContactFirmAdditionalRecipient from 'vue-app/scout/client/rfqs/contact-firm-additional-recipient.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import NotificationService from 'vue-app/shared/services/notification-service.js';
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'RfqAdditionalRecipientsForm',

  components: {
    ContactFirmAdditionalRecipient,
    LoadingButton,
    ValidationObserver
  },

  props: {
    firmName: {
      type: String,
      required: true
    },

    onCancel: {
      type: Function,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },

    provisionedEmails: {
      type: Array,
      default: () => []
    },

    additionalRecipients: {
      type: Array,
      default: () => []
    },

    saveButtonText: {
      type: String,
      default: 'Send'
    }
  },

  data() {
    return {
      newAdditionalRecipients: this.defaultAdditionalUsersState()
    };
  },

  methods: {
    save() {
      this.onSave(this.newAdditionalRecipients)
        .then(() => {
          this.reset();
          NotificationService.clear();
        })
        .catch(() => {
          // empty catch prevents runtime error when an error is thrown in StepReview
        });
    },

    cancel() {
      this.reset();
      this.onCancel();
    },

    reset() {
      this.$refs.observer?.reset();
      this.newAdditionalRecipients = this.defaultAdditionalUsersState();
    },

    removeRecipient(recipient) {
      const index = this.newAdditionalRecipients.indexOf(recipient);

      if (index > -1) {
        this.newAdditionalRecipients.splice(index, 1);
      }
    },

    defaultAdditionalUsersState() {
      return [this.emptyUser()];
    },

    addAdditionalRecipient() {
      this.newAdditionalRecipients.push(this.emptyUser());
    },

    emptyUser() {
      return {
        firstName: null,
        lastName: null,
        email: null
      };
    }
  }
};
</script>
