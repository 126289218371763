<template>
  <div>
    <label class="size-text-13px">Location <span class="inline-help required">*</span></label>

    <div class="row md-columns">
      <div class="col-sm-4">
        <typeahead-vertical
          id="location-address-country"
          :initial-value="lawyerApplication.addressesAttributes[0].country"
          :options="countries"
          :force-select="true"
          placeholder="Country"
          :hide-icon="true"
          :rules="{ required: true, valueInOptions: { options: countries } }"
          v-model="lawyerApplication.addressesAttributes[0].country"
          @input="resetLocation">
        </typeahead-vertical>
      </div>

      <div class="col-sm-4 top-15-xs">
        <typeahead-vertical
          id="location-address-state"
          ref="locationState"
          :initial-value="lawyerApplication.addressesAttributes[0].state"
          :options="states"
          :force-select="true"
          :hide-icon="true"
          :rules="{ required: usAddress, valueInOptions: { options: states } }"
          :disabled="!usAddress"
          :placeholder="lawyerApplication.addressesAttributes[0].country === 'United States' ? 'State' : 'N/A'"
          v-model="lawyerApplication.addressesAttributes[0].state">
        </typeahead-vertical>
      </div>

      <div class="col-sm-4 top-15-xs">
        <text-input-vertical
          id="location-address-city"
          placeholder="City"
          rules="required"
          v-model="lawyerApplication.addressesAttributes[0].city">
        </text-input-vertical>
      </div>
    </div>
  </div>
</template>

<script>
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';

export default {
  name: 'LocationSection',

  components: {
    TypeaheadVertical,
    TextInputVertical
  },

  props: {
    lawyerApplication: {
      type: Object,
      required: true
    },

    states: {
      type: Array,
      required: true
    },

    countries: {
      type: Array,
      required: true
    }
  },

  computed: {
    usAddress() {
      return this.lawyerApplication.addressesAttributes[0].country === 'United States';
    }
  },

  methods: {
    resetLocation() {
      if (!this.usAddress) {
        this.lawyerApplication.addressesAttributes[0].state = '';
      }
    }
  }
};
</script>
