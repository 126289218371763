<template>
  <edit-modal title="Editing Experience" :input="lawyer" :on-save="handleOnSave" @on-modal-open="openModal">
    <div class="bottom-30">
      <div class="section-header">
        Notable Experience
      </div>

      <div class="text-italic pill-gray-text size-text-12px bottom-15">
        Please use the dropdown menu below to select any notable experience that you want to appear on your profile.
      </div>

      <loading-section name="notable-experience">
        <div class="row bottom-10">
          <div class="col-sm-6">
            <dropdown class="k-dropdown" v-model="notableExperienceDropdownOpen" :not-close-elements="elementsToNotCloseOn">
              <button type="button" role="button" class="dropdown-toggle">
                <span>Select all that apply</span>
                <svg-icon name="caret-down" class="caret-down"></svg-icon>
                <svg-icon name="caret-up" class="caret-up"></svg-icon>
              </button>

              <template #dropdown>
                <div class="menu-item-container" v-uiv-scrollable-dropdown="notableExperienceDropdownOpen">
                  <li role="menuitem" v-for="profileTagging in notableExperienceProfileTaggings" :key="profileTagging.id" ref="dropdown-li">
                    <label class="check-option vertical medium-large">
                      {{ profileTagging.presentedName }}
                      <input type="checkbox" :id="`notable-exp-tagging-${profileTagging.id}`" :value="profileTagging.internalName" v-model="selectedNotableExperiences">
                      <span class="check c-box"></span>
                    </label>
                  </li>
                </div>
              </template>
            </dropdown>
          </div>
        </div>

        <div v-if="selectedNotableExperiences.length">
          <tag-list :tags="selectedNotableExperiences" :value-fn="getNotableExperienceValue" :on-remove="removeNotableExperience"></tag-list>
        </div>
      </loading-section>
    </div>

    <input-with-tag-list
      ref="inputWithTagList1"
      class="bottom-30"
      header="Government Experience"
      sub-header="Please list any government experience that you want to appear on your profile."
      :array-data="governmentExperience"
      :tag-value-fn="getStringValue"
      @add-string="addStringToArray(governmentExperience, $event)"
      @remove-string="removeStringFromArray(governmentExperience, $event)">
    </input-with-tag-list>

    <input-with-tag-list
      ref="inputWithTagList2"
      class="bottom-30"
      header="Practice Area Certifications"
      sub-header="Please list any practice area certifications that you want to appear on your profile."
      :array-data="practiceAreaCertifications"
      :tag-value-fn="getStringValue"
      @add-string="addStringToArray(practiceAreaCertifications, $event)"
      @remove-string="removeStringFromArray(practiceAreaCertifications, $event)">
    </input-with-tag-list>

    <div class="bottom-30">
      <div class="section-header">
        Privacy Certifications
      </div>

      <div class="text-italic pill-gray-text size-text-12px bottom-15">
        Please use the dropdown menu below to select any privacy certifications that you want to appear on your profile.
      </div>

      <loading-section name="privacy-certification">
        <div class="row bottom-10">
          <div class="col-sm-6">
            <dropdown class="k-dropdown" v-model="privacyCertificationsDropdownOpen" :not-close-elements="elementsToNotCloseOn">
              <button type="button" role="button" class="dropdown-toggle">
                <span>Select all that apply</span>
                <svg-icon name="caret-down" class="caret-down"></svg-icon>
                <svg-icon name="caret-up" class="caret-up"></svg-icon>
              </button>

              <template #dropdown>
                <div class="menu-item-container" v-uiv-scrollable-dropdown="privacyCertificationsDropdownOpen">
                  <li role="menuitem" v-for="profileTagging in privacyCertificationProfileTaggings" :key="profileTagging.id" ref="dropdown-li">
                    <label class="check-option vertical medium-large">
                      {{ profileTagging.presentedName }}
                      <input type="checkbox" :id="`privacy-cert-tagging-${profileTagging.id}`" :value="profileTagging.internalName" v-model="selectedPrivacyCertifications">
                      <span class="check c-box"></span>
                    </label>
                  </li>
                </div>
              </template>
            </dropdown>
          </div>
        </div>

        <div v-if="selectedPrivacyCertifications.length">
          <tag-list :tags="selectedPrivacyCertifications" :value-fn="getPrivacyCertificationValue" :on-remove="removePrivacyCertification"></tag-list>
        </div>
      </loading-section>
    </div>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import ProfileTagging from 'resources/profile-tagging';
import uivScrollableDropdown from 'vue-app/shared/directives/uiv-scrollable-dropdown';
import InputWithTagList from 'vue-app/scout/shared/input-with-tag-list';
import TagList from 'vue-app/scout/shared/tag-list';
import DataLock from 'resources/data-lock';
import { changes } from 'misc/resource-helpers';
import { compact, some, upperFirst } from 'lodash';

export default {
  name: 'EditLawyerExperience',

  components: {
    EditModal,
    InputWithTagList,
    TagList
  },

  directives: {
    uivScrollableDropdown: uivScrollableDropdown
  },

  props: {
    lawyer: {
      type:     Object,
      required: true
    },

    onSave: {
      type:     Function,
      default:  () => {}
    }
  },

  data() {
    return {
      elementsToNotCloseOn: [],

      notableExperienceDropdownOpen: false,
      selectedNotableExperiences: [],
      notableExperienceProfileTaggings: [],

      privacyCertificationsDropdownOpen: false,
      selectedPrivacyCertifications: [],
      privacyCertificationProfileTaggings: [],

      governmentExperience: [...this.lawyer.sanitizedGovernmentExperience],
      practiceAreaCertifications: [...this.lawyer.sanitizedPracticeAreaCertifications],

      lockables: {
        governmentExperience: 'government_experience',
        practiceAreaCertifications: 'practice_area_certifications'
      }
    };
  },

  mounted() {
    this.fetchNotableExperienceProfileTaggings();
    this.fetchPrivacyCertificationProfileTaggings();
  },

  methods: {
    openModal() {
      this.setElementsToNotCloseOn();
      this.resetForm();
    },

    setElementsToNotCloseOn() {
      if (this.$refs['dropdown-li'] && !this.elementsToNotCloseOn.length) {
        this.elementsToNotCloseOn = this.$refs['dropdown-li'];
      }
    },

    fetchNotableExperienceProfileTaggings() {
      LoadingService.loading('notable-experience');
      ProfileTagging.query({ category: 'experience', sub_category: 'notable' })
        .then(response => {
          this.notableExperienceProfileTaggings = response;
          this.addLawyersNotableExperience();
          LoadingService.done('notable-experience');
        })
        .catch(() => {
          LoadingService.done('notable-experience');
        });
    },

    fetchPrivacyCertificationProfileTaggings() {
      LoadingService.loading('privacy-certification');
      ProfileTagging.query({ category: 'awards_and_certifications', sub_category: 'privacy_certification' })
        .then(response => {
          this.privacyCertificationProfileTaggings = response;
          this.addLawyerPrivacyCertifications();
          LoadingService.done('privacy-certification');
        })
        .catch(() => {
          LoadingService.done('privacy-certification');
        });
    },

    addLawyersNotableExperience() {
      let notableExperience = {};

      this.notableExperienceProfileTaggings.forEach(profileTagging => {
        notableExperience[profileTagging.id] = profileTagging;
      });

      this.lawyer.notableExperience.forEach(profileTagging => {
        if (notableExperience[profileTagging.id]) {
          this.selectedNotableExperiences.push(profileTagging.internalName);
        }
      });
    },

    addLawyerPrivacyCertifications() {
      let privacyCertifications = {};

      this.privacyCertificationProfileTaggings.forEach(profileTagging => {
        privacyCertifications[profileTagging.id] = profileTagging;
      });

      this.lawyer.privacyCertifications.forEach(profileTagging => {
        if (privacyCertifications[profileTagging.id]) {
          this.selectedPrivacyCertifications.push(profileTagging.internalName);
        }
      });
    },

    removeNotableExperience(index) {
      this.selectedNotableExperiences.splice(index, 1);
    },

    removePrivacyCertification(index) {
      this.selectedPrivacyCertifications.splice(index, 1);
    },

    getNotableExperienceValue(internalName) {
      const notableExperience = this.notableExperienceProfileTaggings.find(profileTagging => profileTagging.internalName === internalName);

      if (notableExperience) {
        return notableExperience.presentedName;
      }
    },

    getPrivacyCertificationValue(internalName) {
      const privacyCertification = this.privacyCertificationProfileTaggings.find(profileTagging => profileTagging.internalName === internalName);

      if (privacyCertification) {
        return privacyCertification.presentedName;
      }
    },

    // Note: this function is passed to AddTagInput component as valueFn prop.
    // AddTagInput renders the tags by calling getValue function in the component where
    // the tags are expected to be objects with a name property by default. getValue calls valueFn
    // and passes the tag as an argument instead if valueFn is passed as a prop. Since "tag" is
    // a string and not an object in this case, getStringValue serves to simply return the string
    // that is passed as an argument in order for AddTagInput to render the returned string.
    getStringValue(string) {
      return string;
    },

    addStringToArray(array, string) {
      array.push(string);
    },

    removeStringFromArray(array, index) {
      array.splice(index, 1);
    },

    resetTagInputs() {
      const refs = [
        'inputWithTagList1',
        'inputWithTagList2'
      ];

      refs.forEach(ref => { this.$refs[ref].resetTag(); });
    },

    handleOnSave() {
      const flagsToSet = compact(Object.keys(this.lockables).map((lockable) => {
        if (this.lawyer[lockable] === undefined) {
          const unsanitizedField = 'unsanitized' + upperFirst(lockable);
          return this.checkForLockableChange(unsanitizedField, lockable);
        }
        else {
          return this.checkForLockableChange(lockable, lockable);
        }
      }));

      if (some(changes(this.lawyer.notableExperience.map(code => code.internalName), this.selectedNotableExperiences))) { flagsToSet.push('notable_experience'); }
      if (some(changes(this.lawyer.privacyCertifications.map(code => code.internalName), this.selectedPrivacyCertifications))) { flagsToSet.push('privacy_certifications'); }

      const lawyerAttribute = {
        scoutLawyerProfilesAttributes: {
          id: this.lawyer.scoutLawyerProfileId,
          unsanitizedGovernmentExperience: this.governmentExperience,
          unsanitizedPracticeAreaCertifications: this.practiceAreaCertifications,
          notableExperienceCodes: this.selectedNotableExperiences,
          privacyCertificationCodes: this.selectedPrivacyCertifications
        }
      };

      this.onSave(lawyerAttribute).then(
        ()=>{
          if (flagsToSet.length > 0) {
            DataLock.save({
              dataLock: {
                flag:         flagsToSet,
                resourceId:   this.lawyer.id,
                resourceType: 'lawyer'
              }
            });
          }
        });
    },

    resetForm() {
      this.governmentExperience = [...this.lawyer.sanitizedGovernmentExperience];
      this.practiceAreaCertifications = [...this.lawyer.sanitizedPracticeAreaCertifications];
      this.selectedNotableExperiences = [];
      this.selectedPrivacyCertifications = [];
      this.addLawyersNotableExperience();
      this.addLawyerPrivacyCertifications();
      this.resetTagInputs();
    },

    checkForLockableChange(lockableField, lockable) {
      if (some(changes(this.lawyer[lockableField], this[lockable]))) { return this.lockables[lockable]; }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .section-header {
    border-bottom: 1px solid $k-light-gray;
    font-weight: 700;
    margin-bottom: 5px;
    padding-bottom: 5px;
    text-transform: uppercase;
  }

  .menu-item-container {
    padding: 5px;

    li {
      padding: 5px 10px;
    }
  }
</style>
