<template>
  <edit-modal
    title="Edit Contact Information"
    :input="lawyer"
    save-button-class="primary-btn-purple"
    save-button-text="Update"
    cancel-button-class="nv-button-white"
    v-model="lawyerCopy"
    :on-save="save"
    :on-cancel="cancel">
    <template #modal-trigger="{ openModal }">
      <a href="" role="button" class="size-text-13px pill-gray-text semibold-weight" @click.prevent="openModal">Edit</a>
    </template>

    <template #default>
      <div class="bottom-30">
        <text-input-vertical
          id="firstName"
          label="First Name"
          rules="required"
          v-model="lawyerCopy.firstName">
        </text-input-vertical>
      </div>

      <div class="bottom-30">
        <text-input-vertical
          id="lastName"
          label="Last Name"
          rules="required"
          v-model="lawyerCopy.lastName">
        </text-input-vertical>
      </div>

      <div class="bottom-30 email-input-container">
        <text-input-vertical
          id="email"
          input-type="email"
          rules="required|email"
          :disabled="true"
          v-model="lawyerCopy.email">
        </text-input-vertical>

        <div class="over-input text-italic">
          Verified - Can’t Edit
        </div>
      </div>

      <div class="bottom-30">
        <text-input-vertical
          id="officeNumber"
          label="Phone Number"
          type="tel"
          rules="required"
          v-model="officeNumber">
        </text-input-vertical>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/shared/components/edit-modal.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';

export default {
  name: 'EditLawyerContactInfo',

  components: {
    EditModal,
    TextInputVertical
  },

  props: {
    lawyer: {
      type:     Object,
      required: true
    },

    lawyerApplication: {
      type:     Object,
      required: true
    },

    onSave: {
      type:     Function,
      required: true
    }
  },

  data() {
    return {
      officeNumber: this.lawyerApplication.officeNumber,
      lawyerCopy: null
    };
  },

  methods: {
    save() {
      return this.onSave({
        lawyer: this.lawyerCopy,
        lawyerApplication: { officeNumber: this.officeNumber }
      });
    },

    cancel() {
      this.officeNumber = this.lawyerApplication.officeNumber;
    }
  }
};
</script>

<style scoped lang="scss">
  @use "stylesheets/globals/colors";

  .email-input-container {
    position: relative;

    .over-input {
      position: absolute;
      top: 50%;
      right: 16px;
      font-style: italic;
      transform: translateY(-50%);
      color: colors.$purple-5;
    }
  }
</style>
