<template>
  <div class="shadowed-box">
    <validation-observer v-slot="{ handleSubmit }">
      <form id="profile_editor" role="form" class="a-form" novalidate>
        <div class="box-content variable-padded">
          <div class="size-text-28px bold-weight bottom-20">
            Your Account
          </div>

          <hr>

          <div class="size-text-13px bold-weight text-uppercase bottom-20">
            Contact Information
          </div>

          <div class="row md-columns">
            <div class="col-sm-6 bottom-20">
              <text-input-vertical label="First Name" id="first-name" input-name="firstName" placeholder="First Name" rules="required" v-model="lawyerCopy.firstName" :disabled="true"></text-input-vertical>
            </div>

            <div class="col-sm-6 bottom-20">
              <text-input-vertical label="Last Name" id="last-name" input-name="lastName" placeholder="Last Name" rules="required" v-model="lawyerCopy.lastName" :disabled="true"></text-input-vertical>
            </div>
          </div>

          <div class="bottom-20">
            <text-input-vertical input-type="email" id="email" label="Email" input-name="email" placeholder="Email Address" rules="required|email" v-model="lawyerCopy.email" :disabled="true"></text-input-vertical>
          </div>

          <div class="bottom-40">
            <text-input-vertical label="Firm Name" id="firm" input-name="firm" :value="lawyerCopy.firmName" disabled></text-input-vertical>
          </div>

          <div class="row tight-columns size-text-13px bottom-20">
            <div class="col-sm-3 bold-weight text-uppercase">
              Account Password
            </div>

            <div class="col-sm-9 missing-text">
              (By providing a password, you&rsquo;ll be able to log in to your profile to keep your information up to date.)
            </div>
          </div>

          <div class="row md-columns">
            <div class="col-sm-6">
              <label for="password">Password</label>

              <validation-provider vid="password" v-slot="{ errors, failedRules, changed }" :bails="false" rules="required|oneNumber|min:15|oneUppercaseChar|oneLowercaseChar|oneSpecialChar">
                <input type="password" label="Password" name="password" id="password" :class="['form-control', { 'has-error': errors.length && submitClicked }]" placeholder="Password" autocomplete="off" v-model="lawyerCopy.password">

                <div class="error-text top-5" v-if="errors.length && submitClicked">
                  <span v-if="lawyerCopy.password && lawyerCopy.password.length">Password doesn't meet requirements</span>
                  <span v-else>{{ errors[0] }}</span>
                </div>

                <div class="top-15 size-text-12px dark-gray-text semibold-weight">
                  <div class="bottom-5">
                    Password must have:
                  </div>

                  <ul>
                    <li v-for="(constraint, rule) in passwordRules" :key="rule" class="bottom-5">
                      <svg-icon v-if="isValid(rule, failedRules) && changed" name="checkmark" class="base-icon success"></svg-icon>

                      <svg-icon v-else name="exclamation" class="base-icon"></svg-icon>
                      {{ constraint }}
                    </li>
                  </ul>
                </div>
              </validation-provider>
            </div>

            <div class="col-sm-6">
              <label for="password-confirmation">Confirm Password</label>

              <validation-provider vid="passwordConfirmation" v-slot="{ errors }" rules="required|confirmed:password">
                <input type="password" label="Password Confirmation" name="passwordConfirmation" id="password-confirmation" :class="['form-control', { 'has-error': errors.length && submitClicked }]" placeholder="Password Confirmation" autocomplete="off" v-model="lawyerCopy.passwordConfirmation">

                <div class="error-text top-5" v-if="errors.length && submitClicked">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </div>

          <div class="top-30" v-if="needsTerms && hasTermsText">
            <div class="vertical-center">
              <label class="check-option vertical no-margin">
                I have read and agree to the

                <input type="checkbox" name="agreeToTerms" id="agree" v-model="lawyerCopy.agreeToTerms" @change="onClickAgreeToTerms">
                <span class="check c-box"></span>
              </label>

              <priori-modal
                ref="termsModal"
                modal-id="terms-modal"
                modal-size="lg"
                title="Law Firm Agreement">
                <template #modal-trigger>
                  <button type="button" class="pseudo-link-blue semibold-weight size-text-13px left-5" @click="showTerms">Terms &amp; Conditions</button>
                </template>

                <div class="text-center bottom-30">
                  <span class="medium-page-heading semibold-weight">Terms & Conditions</span>
                </div>

                <div class="row bottom-30">
                  <div class="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
                    <div class="size-text-13px" v-html="terms.content"></div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-4 col-sm-offset-4">
                    <button type="button" class="nv-button-blue a-button-size" @click="closeTerms">Close</button>
                  </div>
                </div>
              </priori-modal>
            </div>

            <div class="error-text">
              <div class="top-5" v-show="submitClicked && !lawyerCopy.agreeToTerms">* You must agree to the Terms & Conditions before submitting</div>
            </div>
          </div>

          <div class="row top-30">
            <div class="col-sm-4">
              <loading-button name="submitScoutProfile" lb-class="nv-button-blue a-button-size" @lb-click="setSubmitClicked() && handleSubmit(submit)">Submit</loading-button>
            </div>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'ScoutProfileEditor',

  components: {
    ValidationObserver,
    ValidationProvider,
    TextInputVertical,
    PrioriModal
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    needsTerms: {
      type: Boolean,
      required: true
    },

    terms: {
      type: Object,
      required: false
    },

    onScoutProfileSubmitted: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      lawyerCopy: this.lawyer,

      passwordRules: {
        min: 'Minimum of 15 characters',
        oneNumber: 'At least one number',
        oneUppercaseChar: 'At least one uppercase character',
        oneLowercaseChar: 'At least one lowercase character',
        oneSpecialChar: 'At least one special character'
      },

      viewedTerms: false,

      submitClicked: false
    };
  },

  methods: {
    isValid(rule, failedRules) {
      return !Object.keys(failedRules).includes(rule);
    },

    hasTermsText() {
      return this.terms && this.terms.content;
    },

    onClickAgreeToTerms() {
      if (!this.viewedTerms) { this.showTerms(); }
    },

    showTerms() {
      this.viewedTerms = true;
      this.$refs.termsModal.openModal();
    },

    closeTerms() {
      this.$refs.termsModal.closeModal();
    },

    submit() {
      if (this.needsTerms && this.hasTermsText && !this.lawyer.agreeToTerms) { return; }
      this.onScoutProfileSubmitted({ lawyer: this.lawyer });
    },

    setSubmitClicked() {
      this.submitClicked = true;
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  label {
    padding-left: 0;
  }

  h1 {
    margin-top: 0;
  }

  ul {
    list-style: none;
    padding-inline-start: 0;

    li {
      display: flex;
      column-gap: 7px;

      .base-icon {
        fill: $k-dark-gray;
        width: 16px;

        &.success {
          fill: $k-green;
        }
      }
    }
  }
</style>
