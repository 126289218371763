<template>
  <div>
    <div class="kenny-styles">
      <div class="top-notification notification-panel-success" v-show="showNotification">
        {{ notificationMessage }}<a :href="`/scout-company/rfps/${newRfqId}`" class="green-link bold-text left-10">View RFP</a>
      </div>
    </div>

    <scout-titlebar
      title="RFPs">
      <template #actions>
        <a href="/scout-company/rfps/new" class="secondary-btn-blue titlebar-button">Create New RFP</a>
      </template>
    </scout-titlebar>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="bottom-20">
            <span class="semibold-weight">{{ totalRfqCount }}</span>
            <span>{{ pluralizedResultsString }}</span>
          </div>

          <client-rfq-index-table
            :rfqs="rfqs"
            :current-page="currentPage"
            :change-page="changePage"
            :on-sort="sortBy"
            :total-rfq-count="totalRfqCount"
            :per-page="perPage"
            :on-delete="deleteRfq"
            :change-rfq-status="changeRfqStatus">
          </client-rfq-index-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientRfqIndexTable from 'vue-app/scout/client/rfqs/client-rfq-index-table.vue';
import ScoutTitlebar from 'vue-app/scout/shared/scout-titlebar.vue';
import RFQ from 'resources/scout/rfq.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';

export default {
  name: 'ClientRfqIndex',

  components: {
    ClientRfqIndexTable,
    ScoutTitlebar
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      rfqs: [],
      totalRfqCount: 0,
      currentPage: 1,
      perPage: 10,
      sortOptions: {
        sort_by: 'date_sent',
        sort_direction: 'desc'
      },
      params: this.initData.params,
      showNotification: false,
      newRfqId: null,
      notificationMessage: ''
    };
  },

  computed: {
    pluralizedResultsString() {
      return this.$pluralize('result', this.totalRfqCount);
    }
  },

  mounted() {
    if (this.params.newRfq === 'true') {
      const rfqSentCount = parseInt(this.params.rfqSentCount);
      const sentToMarketplace = this.params.sentToMarketplace === 'true';

      let recipientText = '';

      if (rfqSentCount === 0 && sentToMarketplace) {
        recipientText = 'Marketplace';
      }
      else {
        const updatedRfqCount = sentToMarketplace ? rfqSentCount + 1 : rfqSentCount;
        recipientText = `${updatedRfqCount} law firm${updatedRfqCount > 1 ? 's' : ''}`;
      }

      this.showNotification = true;
      this.newRfqId = this.params.rfqId;
      this.notificationMessage = `RFP was sent to ${recipientText}.`;
    }

    this.loadRfqs();
  },

  methods: {
    loadRfqs() {
      LoadingService.loading('rfqs');

      RFQ.query({ page: this.currentPage, view: 'index', ...this.sortOptions }).then((response) => {
        this.rfqs = response.rfqs;
        this.totalRfqCount = parseInt(response.totalCount);
        this.perPage = parseInt(response.perPage);

        LoadingService.done('rfqs');
      });
    },

    changePage(page) {
      if (this.currentPage !== page && page > 0) {
        this.currentPage = page;

        this.loadRfqs();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },

    sortBy(sortOptions) {
      this.currentPage = 1;
      this.sortOptions = sortOptions;

      this.loadRfqs();
    },

    deleteRfq(params) {
      return RFQ.delete(params).then(() => {
        this.loadRfqs();
        NotificationService.success('You successfully deleted the draft RFP.');
      });
    },

    changeRfqStatus(rfq) {
      let event;

      if (rfq.status === 'closed') {
        event = 'reopen';
      }
      else {
        event = 'close';
      }

      return RFQ.update({ id: rfq.id, rfq: rfq, event: event, view: 'show' }).then((response) => {
        NotificationService.success(`You successfully ${response.status === 'closed' ? 'closed' : 'reopened'} the RFP.`);
        this.loadRfqs();
      });
    }
  }
};
</script>
