<template>
  <div class="shadowed-box">
    <div class="box-content size-text-13px variable-padded">
      <div class="size-text-18px bold-weight">
        Review Your Application
      </div>

      <hr class="top-10 bottom-15">

      <div class="size-text-14px bottom-40">
        Please review your application. If you need to edit any sections, you will be taken back to your application to make the changes.
      </div>

      <div class="bottom-25">
        <label class="purple-label bold-weight size-text-15px right-15">
          Contact Information
        </label>

        <span class="inline-block">
          <edit-lawyer-contact-info-modal
            :lawyer="lawyer"
            :lawyer-application="lawyerApplication"
            :on-save="onSaveContactInfo">
          </edit-lawyer-contact-info-modal>
        </span>
      </div>

      <div class="bottom-30">
        <div class="row bottom-10">
          <div class="col-sm-1 bold-weight">
            Name
          </div>

          <div class="col-sm-11">
            {{ lawyer.fullName }}
          </div>
        </div>

        <div class="row bottom-10">
          <div class="col-sm-1 bold-weight">
            Email
          </div>

          <div class="col-sm-11">
            {{ lawyer.email }} &mdash; <span class="purple-5-text text-italic">Verified</span>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-1 bold-weight">
            Phone
          </div>

          <div class="col-sm-11">
            <span :class="{ 'missing-text': !lawyerApplication.officeNumber }">{{ lawyerApplication.officeNumber || 'Office number not provided' }}</span>
          </div>
        </div>
      </div>

      <hr class="bottom-40">

      <div class="bottom-25">
        <label class="purple-label bold-weight size-text-15px right-15">
          Practice
        </label>

        <a href="" role="button" @click.prevent="onMoveToStep(1)" class="size-text-13px pill-gray-text semibold-weight">Edit</a>
      </div>

      <div class="bottom-30">
        <div class="bold-weight bottom-5">
          Your Bio
        </div>

        <span class="simple-text">{{ lawyerApplication.practiceDescription }}</span>
      </div>

      <div v-if="barAdmissions.length" class="bottom-30">
        <div class="bold-weight bottom-10">
          Bar Licensing
        </div>

        <div class="list-spacing-10 vertical-center-not-xs" v-for="(bar, index) in barAdmissions" :key="index">
          <svg-icon name="checkmark" class="base-icon checkmark right-10"></svg-icon><span class="semibold-weight">{{ bar.country === 'United States' ? bar.state : bar.country }}</span><span>, {{ dateFilter.year(bar.admitDate) }}</span><span v-if="bar.license">, License No. {{ bar.license }}</span>
        </div>
      </div>

      <div v-if="!isLegalOperationsProfessional" class="bottom-30">
        <div class="bold-weight bottom-10">
          Please choose at least one primary practice area.
        </div>

        <div class="list-spacing-10 vertical-center-not-xs" v-for="(practiceArea, index) in nonEmptyPracticeAreas" :key="index">
          <svg-icon name="checkmark" class="base-icon checkmark right-10"></svg-icon><span class="semibold-weight">{{ practiceAreaName(practiceArea.practiceAreaId) }}</span>
        </div>
      </div>

      <div class="bottom-30">
        <div class="bold-weight bottom-5">
          If you offer services on an hourly basis, what is your standard rate (USD)?
        </div>

        <div v-if="lawyerApplication.standardRate">
          ${{ lawyerApplication.standardRate }}/hr
        </div>

        <div v-if="lawyerApplication.onlyFlatRates" class="top-5">
          I only offer fixed fees for my services.
        </div>
      </div>

      <div class="bottom-30" v-if="!isLegalOperationsProfessional">
        <div class="bold-weight bottom-5">
          Do you carry malpractice insurance?
        </div>

        {{ lawyerApplication.malpracticeInsurancePolicyAttributes.hasSome ? 'Yes' : 'No' }}
      </div>

      <div class="bottom-30">
        <div class="bold-weight bottom-5">
          Have you ever received a reprimand, censure, suspension or any other form of discipline from any Bar or Court?
        </div>

        {{ lawyerApplication.receivedDiscipline ? 'Yes' : 'No' }}
      </div>

      <hr class="bottom-40">

      <div class="bottom-25">
        <label class="purple-label bold-weight size-text-15px right-15">
          Employment
        </label>

        <a href="" role="button" @click.prevent="onMoveToStep(2)" class="size-text-13px pill-gray-text semibold-weight">Edit</a>
      </div>

      <div class="bottom-30">
        <div class="bold-weight bottom-5">
          Resume
        </div>

        <a :href="lawyerApplication.resume.url" class="purple-link bold-weight" target="_blank">{{ lawyerApplication.resume.name }}</a>
      </div>

      <div class="bottom-30">
        <div class="bold-weight bottom-5">
          Your LinkedIn URL
        </div>

        <div :class="{ 'missing-text': !lawyerApplication.linkedInUrl }">
          {{ lawyerApplication.linkedInUrl || 'Not provided' }}
        </div>
      </div>

      <div class="bottom-30">
        <div class="bold-weight bottom-10">
          Relevant Work History
        </div>

        <div class="d-flex col-gap-10 list-spacing-20" v-for="firm in lawyerApplication.employmentHistoriesAttributes" :key="firm.firmName">
          <span>
            <svg-icon name="checkmark" class="base-icon checkmark"></svg-icon>
          </span>

          <span>
            <div class="bold-weight bottom-5">
              {{ firm.firmName }}
            </div>

            <div class="semibold-weight bottom-10">
              {{ firm.title }}
            </div>

            <ul class="review-list">
              <li>{{ `${getMonthName(firm.fromDate)} ${dateFilter.year(firm.fromDate)}` }} – {{ firm.currentPosition ? 'Present' : `${getMonthName(firm.toDate)} ${dateFilter.year(firm.toDate)}` }}</li>
              <li>{{ firm.positionDescription }}</li>
              <li>{{ firm.city }}<span v-if="firm.country === 'United States'">, {{ firm.state }}</span><span v-else>, {{ firm.country }}</span></li>
              <li :class="{ 'missing-text': !firm.webAddress }">{{ firm.webAddress || 'Website URL not provided' }}</li>
              <li>{{ firm.firmSize }}</li>
            </ul>
          </span>
        </div>
      </div>

      <div class="bottom-30">
        <div class="bold-weight bottom-5">
          Do you have experience working as a {{ professionalTypes }} in an Am Law 100 law firm?
        </div>

        {{ lawyerApplication.amLawExperience ? 'Yes' : 'No' }}
      </div>

      <div class="bottom-30" v-if="!isLegalOperationsProfessional">
        <div class="bold-weight bottom-5">
          Do you have experience working as a Partner at a law firm of 25+ lawyers?
        </div>

        {{ lawyerApplication.partnerExperience ? 'Yes' : 'No' }}
      </div>

      <div class="bottom-30">
        <div class="bold-weight bottom-5">
          Do you have experience working as a {{ professionalTypes }} in-house?
        </div>

        {{ lawyerApplication.inHouseExperience ? 'Yes' : 'No' }}
      </div>

      <div class="bottom-30">
        <div class="bold-weight bottom-5">
          Do you have experience working as a {{ professionalTypes }} in a Fortune 500 company?
        </div>

        {{ lawyerApplication.fortuneExperience ? 'Yes' : 'No' }}
      </div>

      <hr class="bottom-40">

      <div class="bottom-25">
        <label class="purple-label bold-weight size-text-15px right-15">
          Education
        </label>

        <a href="" role="button" @click.prevent="onMoveToStep(3)" class="size-text-13px pill-gray-text semibold-weight">Edit</a>
      </div>

      <div class="bottom-40">
        <div class="bold-weight bottom-10">
          Please list relevant school history.
        </div>

        <div class="d-flex col-gap-10 list-spacing-20" v-for="(school, index) in lawyerApplication.schoolsAttributes" :key="index">
          <span>
            <svg-icon name="checkmark" class="base-icon checkmark"></svg-icon>
          </span>

          <span>
            <div class="bold-weight bottom-5">
              {{ school.name }}
            </div>

            <div class="semibold-weight bottom-10">
              {{ school.degree }}
            </div>

            <ul class="review-list">
              <li>{{ dateFilter.year(school.dateEntered) }} – {{ dateFilter.year(school.dateAttained) }}</li>
              <li v-if="school.awards">{{ school.awards }}</li>
            </ul>
          </span>
        </div>
      </div>

      <div>
        <loading-button name="submitApplication" lb-class="primary-btn-purple a-button-size fit-content right-10" @lb-click="onSubmit">Submit</loading-button>

        <loading-button name="saveApplication" :dark="true" lb-class="nv-button-clear-alt fit-content" @lb-click="onSave">Save</loading-button>
      </div>
    </div>
  </div>
</template>

<script>
import EditLawyerContactInfoModal from 'vue-app/marketplace/lawyer/application/edit-contact-information-modal.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import { get, find } from 'lodash';

export default {
  name: 'ReviewPage',

  components: {
    EditLawyerContactInfoModal,
    LoadingButton,
    SvgIcon
  },

  props: {
    lawyerApplication: {
      type: Object,
      required: true
    },

    lawyer: {
      type: Object,
      required: true
    },

    isParalegal: {
      type: Boolean,
      required: true
    },

    isLegalOperationsProfessional: {
      type: Boolean,
      required: true
    },

    practiceAreas: {
      type: Array,
      required: true
    },

    onMoveToStep: {
      type: Function,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },

    onSaveContactInfo: {
      type: Function,
      required: true
    },

    onSubmit: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      dateFilter: DateFilter
    };
  },

  computed: {
    nonEmptyPracticeAreas() {
      return this.lawyerApplication.lawyersPracticeAreasAttributes.filter(p => p.practiceAreaId);
    },

    barAdmissions() {
      return this.lawyerApplication.barAdmissionsAttributes.filter(b => !b._destroy);
    },

    professionalTypes() {
      if (this.isParalegal) {
        return 'lawyer or paralegal';
      }
      else if (this.isLegalOperationsProfessional) {
        return 'lawyer, paralegal, or legal operations role';
      }
      else {
        return 'lawyer';
      }
    }
  },

  methods: {
    practiceAreaName(practiceAreaId) {
      return get(find(this.practiceAreas, { id: practiceAreaId }), 'name');
    },

    getMonthName(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString('default', { month: 'long' });
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/globals/variables";

  .review-list {
    padding-inline-start: 10px;
    margin-bottom: 0;

    li {
      font-size: 13px;
      list-style-type: none;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    li:before {
      content: "•";
      font-size: 80%;
      padding-right: 10px;
    }
  }

  .checkmark {
    width: 16px;
    height: 16px;
  }

  .purple-link {
    color: $purple-5;

    &:hover, &:focus {
      color: $purple-5;
    }
  }

</style>
