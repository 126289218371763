<template>
  <div>
    <div class="grid-container">
      <div class="keep">
        <div class="category-wrapper" v-for="quote in renderedQuotes" :key="quote.id">
          <div class="row tight-columns vertical-center">
            <div class="col-xs-3 tight-lines text-right score-label">
              <a href="" @click.prevent="viewQuote(quote)">{{ quoteLabel(quote) }}</a>
            </div>
            <div class="col-xs-9">
              <hourly-rate-chart-bar
                :quote="quote"
                :range-data="rangeData"
                :fee-structure-question-included="feeStructureQuestionIncluded">
              </hourly-rate-chart-bar>
            </div>
          </div>
        </div>
      </div>
      <div class="background-grid"></div>
    </div>
    <div class="x-axis-row">
      <div class="x-axis-col" v-for="legend in chartLegend" :key="legend.value">
        <span class="x-axis-item">{{ legend.value }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import HourlyRateChartBar from 'vue-app/scout/client/rfqs/hourly-rate-chart-bar.vue';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter.js';

export default {
  name: 'HourlyRateChart',

  components: {
    HourlyRateChartBar
  },

  props: {
    renderedQuotes: {
      type: Array,
      required: true
    },

    allQuotes: {
      type: Array,
      required: true
    },

    feeStructureQuestionIncluded: {
      type: Boolean,
      required: true
    },

    viewQuote: {
      type: Function,
      required: true
    }
  },

  computed: {
    allIncludedLawyers() {
      return this.allQuotes
        .filter(quote => ['quote_submitted', 'selected_by_client', 'declined_by_client'].includes(quote.status))
        .flatMap(quote => {
          return quote.suggestedLawyers.filter(lawyer => lawyer.includedInQuote && lawyer.proposedHourlyRate != undefined);
        });
    },

    rangeData() {
      const hourlyRates = this.allIncludedLawyers.map(lawyer => lawyer.proposedHourlyRate);
      const interval = 15;

      if (hourlyRates.length === 0) {
        return {
          min: 0,
          max: 0,
          interval: interval
        };
      }

      let min = Math.min(...hourlyRates);
      min = Math.max(min - interval, 0);

      let max = Math.max(...hourlyRates);
      max += 1;

      const roundedMin = Math.floor(min / interval) * interval;
      let roundedMax = Math.ceil(max / interval) * interval;

      if (roundedMin === roundedMax) {
        return {
          min: roundedMin,
          max: roundedMax + interval,
          interval: interval
        };
      }

      let tempMax;
      let tempInterval;

      for (let i = interval; roundedMin + (i * 3) < roundedMax + (interval * 3); i += interval) {
        tempInterval = i;
        tempMax = roundedMin + (i * 3);
      }
      return {
        min: roundedMin,
        max: tempMax,
        interval: tempInterval
      };
    },

    chartLegend() {
      const min = this.rangeData.min;
      const interval = this.rangeData.interval;

      return [
        { value: `${CurrencyFilter.filter(min, null, 'onlyDollars')}/hr` },
        { value: `${CurrencyFilter.filter(min + interval, null, 'onlyDollars')}/hr` },
        { value: `${CurrencyFilter.filter(min + (interval * 2), null, 'onlyDollars')}/hr` },
        { value: `${CurrencyFilter.filter(min + (interval * 3), null, 'onlyDollars')}/hr` }
      ];
    }
  },

  methods: {
    quoteLabel(quote) {
      return (quote.fromMarketplace && !quote.marketplaceLawyer.addedAsFirm) ? quote.marketplaceLawyer.fullName : quote.lawFirm.name;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .grid-container {
    position: relative;
    padding-bottom: 30px;

    .category-wrapper {
      position: relative;
      z-index: 1;

      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }

    .background-grid {
      position: absolute;
      top: 0;
      left: calc(25% + 2.5px);
      width: calc(75% - 2.5px);
      height: calc(100% - 28px);
      background-image:
        repeating-linear-gradient(transparent 0 1px, transparent 1px 100%),
        repeating-linear-gradient(90deg, $k-light-gray 0 1px, transparent 1px 100%);
      background-size: 33.33% 100%;
      border-right: 1px solid $k-light-gray;
      z-index: 0;
    }

    .score-label {
      font-size: 12px;
      overflow-wrap: break-word;
      hyphens: auto;
    }
  }

  .x-axis-row {
    display: flex;
    text-align: right;
    height: 20px;
    font-size: 11px;
    line-height: 1.3;

    .x-axis-col {
      float: left;
      width: calc(100% / 3);
    }

    .x-axis-item {
      display: inline-block;
      white-space: nowrap;
      width: 10px;
      cursor: pointer;
      text-align: center;
    }
  }
</style>
