<template>
  <div :class="['messaging-center-action-container', { 'half-height': isExpanded || (!isLoadingReceipts && !hasReceipts)}]">
    <div v-if="isLoadingReceipts || hasReceipts" class="collapse-tab text-center" @click="toggleExpanded">
      <svg-icon name="caret-down" class="base-icon" v-if="isExpanded"></svg-icon>
      <svg-icon name="caret-up" class="base-icon" v-else></svg-icon>
    </div>

    <div class="info-bar size-text-11px bottom-10 text-right dark-gray-text">
      <span v-if="isNewMessage">New Message</span>

      <span v-else>
        Last saved: <span class="semibold-weight right-10">{{ lastSaved }}</span>
        <button type="button" v-if="!isDraftSaved" class="pseudo-link-blue" @click="setDraftSaved">Save Draft</button>
      </span>
    </div>

    <div :class="['expandable-input', { 'collapsed': !isExpanded && (isLoadingReceipts || hasReceipts), 'focused': isMessageFocused }]">
      <textarea id="reply" placeholder="Enter message..." v-model="draftMessageCopy" @focus="onInputFocus" @blur="onInputBlur" @input="setDraftMessage"></textarea>
    </div>

    <div class="top-20">
      <button type="button" class="nv-button-blue disabled-gray smaller" :disabled="!conversation.draftMessage.body.length || isSending" @mousedown="sendMessage">Send Message<span class="spinner left-10" v-show="isSending"></span></button>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';
import { some } from 'lodash';
import { DateTime } from 'luxon';

export default {
  name: 'ConversationActions',

  components: {
    SvgIcon
  },

  props: {
    conversation: {
      type: Object,
      required: true
    },

    onToggleExpanded: {
      type: Function,
      required: true
    },

    onSaveDraft: {
      type: Function,
      required: true
    },

    onSend: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      draftMessageCopy: this.conversation.draftMessage.body,
      isDraftSaved: true,
      isMessageFocused: false,
      isExpanded: false,
      isSending: false
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    receipts() {
      return this.conversation.receipts || [];
    },

    hasReceipts() {
      return some(this.receipts, (r) => { return !r.message.draft; });
    },

    isLoadingReceipts() {
      return LoadingService._state.loaders['receipts'] > 0;
    },

    isNewMessage() {
      return !this.conversation.id || !this.conversation.draftSubject.length;
    },

    lastSaved() {
      const dt = DateTime.fromISO(this.conversation.draftMessage.updatedAt);

      if (dt.diffNow().as('seconds') > -1) {
        return 'just now'; // show for 1 second (prevents '0 seconds ago')
      }
      else {
        return dt.toRelative();
      }
    }
  },

  methods: {
    toggleExpanded() {
      this.isExpanded ? this.collapseEditor() : this.expandEditor();
    },

    expandEditor() {
      if (this.isExpanded || !this.hasReceipts) { return; }

      this.isExpanded = true;
      this.onToggleExpanded();
    },

    collapseEditor() {
      if (!this.isExpanded) { return; }

      this.isExpanded = false;
      this.onToggleExpanded(true);
    },

    setDraftMessage() {
      this.isDraftSaved = false;
      this.conversation.draftMessage.body = this.draftMessageCopy;
    },

    setDraftSaved() {
      this.isDraftSaved = true;
      this.conversation.draftMessage.updatedAt = DateTime.now().toString();
    },

    onInputFocus() {
      this.isMessageFocused = true;
      this.expandEditor();
    },

    onInputBlur() {
      this.isMessageFocused = false;
      this.onSaveDraft();
    },

    sendMessage() {
      this.isSending = true;

      this.onSend().then(() => {
        this.draftMessageCopy = '';
        this.isSending = false;
        this.collapseEditor();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .info-bar {
    line-height: 15px;
  }
</style>
