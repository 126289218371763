<template>
  <div>
    <div class="step-header">
      <div v-for="step in steps" :key="step.number" :class="['step-item', { 'selected': isActiveStep(step.number), 'invalid': isInvalid(step) }]" @click="goToStep(step.number)">
        <div :class="['vertical-center', { 'add-spacer-after': step.number !== steps.length }]">
          <span :class="['stepper-label', statusClass(step.number), { 'invalid': isInvalid(step) }]">
            <svg-icon name="checkmark-no-circle" class="base-icon" v-if="stepIsComplete(step.number) && !isInvalid(step)"></svg-icon>
            <span v-else class="size-text-13px">{{ step.number }}</span>
          </span>
          <span :class="['stepper-label-text', { 'invalid': isInvalid(step) && !isActiveStep(step.number) }]">{{ step.label }}</span>
        </div>
      </div>
    </div>

    <slot
      :active-step="activeStep"
      :move-to-next-step="nextStep"
      :move-to-prev-step="previousStep">
    </slot>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ClickableStepper',

  components: {
    SvgIcon
  },

  props: {
    steps: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      activeStep: 1
    };
  },

  watch: {
    activeStep(_, oldVal) {
      this.$emit('step-change', this.steps.find(step => step.number === oldVal));
    }
  },

  mounted() {
    this.setSpacerCustomCssProperties();

    window.addEventListener('resize', this.setSpacerCustomCssProperties);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setSpacerCustomCssProperties);
  },

  methods: {
    previousStep() {
      if (this.activeStep > 0) {
        this.activeStep--;
      }
    },

    nextStep() {
      if (this.activeStep < this.steps.length) {
        this.activeStep++;
      }
    },

    goToStep(step) {
      this.activeStep = step;
    },

    isActiveStep(stepIndex) {
      return stepIndex === this.activeStep;
    },

    stepStatus(stepIndex) {
      if (stepIndex === this.activeStep) {
        return 'active';
      }
      else if (stepIndex < this.activeStep) {
        return 'complete';
      }
      else {
        return 'incomplete';
      }
    },

    statusClass(stepIndex) {
      return {
        active: 'status-active',
        incomplete: 'status-incomplete',
        complete: 'status-complete'
      }[this.stepStatus(stepIndex)];
    },

    stepIsComplete(stepIndex) {
      return this.stepStatus(stepIndex) === 'complete';
    },

    setSpacerCustomCssProperties() {
      const stepHeader = document.querySelector('.step-header');

      if (stepHeader) {
        const stepHeaderWidth = window.getComputedStyle(stepHeader).width;
        document.documentElement.style.setProperty('--step-header-width', stepHeaderWidth);
      }

      const stepItems = document.querySelectorAll('.step-item');

      let totalWidth = 0;
      stepItems.forEach((stepItem) => {
        const stepItemWidth = stepItem.offsetWidth + 15;

        totalWidth += stepItemWidth;
      });
      document.documentElement.style.setProperty('--step-items-sum', totalWidth + 'px');
      document.documentElement.style.setProperty('--number-of-steps', this.steps.length);
    },

    isInvalid(step) {
      return step.validationStatus === 'invalid';
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/globals/variables";

  .step-header {
    @media (min-width: $screen-sm-min) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .step-item {
    display: none;
    padding-right: 5px;
    cursor: pointer;

    @media (min-width: $screen-sm-min) {
      display: inline-block;
      padding-left: 5px;
      padding-bottom: 15px;
    }

    .stepper-label-text {
      font-size: 15px;
      font-weight: 600;

      &.invalid {
        color: $k-red;
      }
    }

    &.selected {
      display: inline-block;
      border-bottom: 4px solid $purple-5;
      padding-bottom: 11px;
    }
  }

  .add-spacer-after {
    position: relative;

    &::after {
      @media (min-width: $screen-sm-min) {
        content: "";
        position: absolute;
        top: 52%;
        transform: translateY(-50%);
        left: calc(100% + 15px);
        height: 1px;
        background: $k-gray;
        // this is the calculation for the space between objects that have
        // display:flex and justify-content: space-between.
        width: calc((var(--step-header-width) - var(--step-items-sum)) / (var(--number-of-steps) - 1));
      }
    }
  }

  .stepper-label {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    color: $white;
    border-radius: 50%;
    font-weight: 700;
    text-align: center;
    line-height: 21px;

    &.status-active {
      display: inline-block;
      background: $purple-5;
    }

    &.status-incomplete {
      background: $pill-gray;

      &.invalid {
        background: $k-red;
      }
    }

    &.status-complete {
      background: $purple-5;

      .base-icon {
        position: absolute;
        top: 5px;
        left: 5px;
        width: 14px;
        height: 14px;
        fill: $white;
      }

      &.invalid {
        background: $k-red;
      }
    }
  }
</style>
