<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-sm-4 text-uppercase vertical-center">
          Reviews
          <svg-icon v-if="toolTipText" id="lawyer-matters-tooltip" name="info" class="base-icon smaller left-5"></svg-icon>
          <tooltip target="#lawyer-matters-tooltip" custom-class="tooltip-white" placement="bottom" :text="toolTipText"></tooltip>
        </div>

        <div class="col-sm-8 text-right-not-xs">
          <div class="top-20-xs">
            <a href="" :class="['subtab-link', { 'selected': selectedTabIsMatter }]" @click.prevent="selectTab('matters')">Matter Reviews ({{ timekeepings.length }})</a>

            <a href="" :class="['subtab-link', { 'selected': selectedTabIsGeneral }]" @click.prevent="selectTab('general')">General Reviews ({{ generalReviews.length }})</a>
          </div>
        </div>
      </div>
    </div>

    <div v-show="selectedTabIsMatter">
      <lawyer-matter-review-list-filters
        :on-apply-filters="onApplyFilters"
        :practice-area-options="practiceAreaOptions"
        :matter-manager-options="matterManagerOptions">
      </lawyer-matter-review-list-filters>

      <div class="resource-list clearfix">
        <loading-section name="matterReviews">
          <lawyer-matter-reviews
            v-for="timekeeping in timekeepings"
            :key="`timekeeping-${timekeeping.id}`"
            :timekeeping="timekeeping"
            :review-template="reviewTemplate">
          </lawyer-matter-reviews>

          <div v-if="!timekeepings.length" class="gray-text text-center size-text-16px top-30">
            No matters
          </div>
        </loading-section>
      </div>

      <div class="box-content symmetrical">
        <div class="row tight-columns review-list-item">
          <div class="col-sm-6 size-text-14px top-4">
            Total spent with this lawyer
          </div>

          <div class="col-sm-6 text-right-not-xs size-text-18px bold-weight">
            {{ formattedDollarsSpent }}
          </div>
        </div>
      </div>
    </div>

    <div v-show="selectedTabIsGeneral">
      <lawyer-general-review-list-filters
        :filters-data="filtersData"
        :on-apply-filters="onApplyFilters">
      </lawyer-general-review-list-filters>

      <div class="resource-list clearfix">
        <loading-section name="generalReviews">
          <lawyer-general-review
            v-for="review in generalReviews"
            :key="`review-${review.id}`"
            :review="review"
            :review-template="reviewTemplate">
          </lawyer-general-review>

          <div v-if="!generalReviews.length" class="gray-text text-center size-text-16px top-30 bottom-30">
            No reviews
          </div>
        </loading-section>
      </div>
    </div>
  </div>
</template>

<script>
import LawyerMatterReviews from 'vue-app/scout/client/lawyers/reviews/lawyer-matter-reviews.vue';
import LawyerGeneralReview from 'vue-app/scout/client/lawyers/reviews/lawyer-general-review.vue';
import LawyerGeneralReviewListFilters from 'vue-app/scout/client/lawyers/reviews/lawyer-general-review-list-filters.vue';
import LawyerMatterReviewListFilters from 'vue-app/scout/client/lawyers/reviews/lawyer-matter-review-list-filters.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'LawyerMatters',

  components: {
    SvgIcon,
    LawyerGeneralReview,
    LawyerMatterReviews,
    LawyerGeneralReviewListFilters,
    LawyerMatterReviewListFilters,
    LoadingSection
  },

  props: {
    lawyer: {
      type:     Object,
      required: true
    },

    timekeepings: {
      type:     Array,
      required: true
    },

    generalReviews: {
      type:     Array,
      required: true
    },

    practiceAreaOptions: {
      type:     Array,
      required: true
    },

    reviewTemplate: {
      type: Object,
      required: true
    },

    filtersData: {
      type: Object,
      required: true
    },

    onApplyFilters: {
      type: Function,
      required: false
    },

    matterManagerOptions: {
      type:     Array,
      required: true
    }
  },

  data() {
    return {
      selectedTab: 'matters'
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    toolTipText() {
      return this.currentClient.workspace.scoutLawyersReviewsTooltipText;
    },

    formattedDollarsSpent() {
      return CurrencyFilter.filter(this.lawyer.totalSpend / 100);
    },

    selectedTabIsMatter() {
      return this.selectedTabIs('matters');
    },

    selectedTabIsGeneral() {
      return this.selectedTabIs('general');
    }
  },

  methods: {
    selectTab(tab) {
      if (tab === this.selectedTab) { return; }

      this.selectedTab = tab;
    },

    selectedTabIs(tab) {
      return this.selectedTab === tab;
    }
  }
};
</script>

<style lang="scss" scoped>
  :deep {
    .loading-modal {
      padding: 30px
    }
  }
</style>
