import { render, staticRenderFns } from "./review-page.vue?vue&type=template&id=325eda0f&scoped=true"
import script from "./review-page.vue?vue&type=script&lang=js"
export * from "./review-page.vue?vue&type=script&lang=js"
import style0 from "./review-page.vue?vue&type=style&index=0&id=325eda0f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "325eda0f",
  null
  
)

export default component.exports