<template>
  <priori-modal ref="modal" modal-size="md" @on-modal-open="setRenderContext" @on-modal-close="reset">
    <template #modal-trigger="{ openModal }">
      <slot name="modal-trigger" :open-modal="openModal">
        <button type="button" class="nv-button-white" @click="openModal"><svg-icon name="note" class="base-icon right-5"></svg-icon>{{ noteTitle }}</button>
      </slot>
    </template>

    <template #title>
      <div v-if="!renderFormTemplate && !renderDeleteTemplate">
        <div>Note: {{ quote.lawFirm.name }}</div>
        <div>RFP {{ rfq.id }} - {{ rfq.name }}</div>
      </div>

      <div v-if="renderFormTemplate">
        {{ selectedNote.id ? "Edit" : "Add" }} Note
      </div>

      <div v-if="renderDeleteTemplate">
        Delete Note
      </div>
    </template>

    <template #default="{ closeModal }">
      <validation-observer v-show="renderFormTemplate" v-slot="{ handleSubmit, failed }" ref="validationObserver">
        <form role="form" name="form" class="a-form" novalidate @submit.prevent>
          <div class="bottom-30">
            <text-input-vertical
              label="Title"
              v-model="selectedNote.title">
            </text-input-vertical>
          </div>

          <label for="note-content">
            Note <span class="inline-help required">*</span>
          </label>

          <validation-provider rules="requiredHtml" :mode="passiveAggressive" v-slot="{ errors }">
            <tiptap-editor
              id="note-content"
              v-model="selectedNote.content">
            </tiptap-editor>

            <div class="error-text top-5">
              {{ errors[0] }}
            </div>
          </validation-provider>

          <div class="row tight-columns top-30">
            <div class="col-sm-4">
              <loading-button name="saveNote" lb-class="primary-btn-blue" @lb-click="handleSubmit(saveNote)">Save</loading-button>
            </div>

            <div class="col-sm-3 top-20-xs">
              <button type="button" class="nv-button-white" @click="cancelEdit">Cancel</button>
            </div>
          </div>

          <div class="error-text top-10" v-if="failed">
            <div class="size-text-12px">
              * One or more required fields are empty. Please fill in the required fields indicated above.
            </div>
          </div>
        </form>
      </validation-observer>

      <div v-show="!renderFormTemplate && !renderDeleteTemplate" class="box-content symmetrical">
        <button @click.prevent="renderFormTemplate = true" class="pseudo-link-blue bold-weight bottom-15">
          + Add New Note
        </button>

        <div class="scrollable-notes">
          <div v-for="note in quote.notes" :key="note.id" class="gray-lighter-panel list-spacing-10">
            <div class="row tight-columns vertical-center-not-xs bottom-15">
              <div class="col-sm-10 pill-gray-text size-text-12px text-italic">
                Last Updated {{ note.updatedAt }} by {{ note.lastUpdatedBy }}
              </div>

              <div class="col-sm-2 d-flex flex-end-not-xs col-gap-15 top-5-xs">
                <a href="" class="semibold-weight size-text-12px" @click.prevent="editNote(note)">Edit</a>
                <a href="" @click.prevent="showDeleteTemplate(note)"><svg-icon name="trash" class="delete-btn base-icon"></svg-icon></a>
              </div>
            </div>

            <div v-if="note.title" class="bold-weight size-text-13px bottom-5">
              {{ note.title }}
            </div>

            <collapsable-space ref="collapsableSpace" :max-height="115">
              <div v-html="note.content" class="no-margin"></div>
            </collapsable-space>
          </div>
        </div>

        <div class="success-message" v-show="successMessage">
          <svg-icon name="checkmark" class="base-icon"></svg-icon>
          {{ successMessage }}
        </div>

        <div class="row tight-columns top-30">
          <div class="col-sm-3">
            <button type="button" class="primary-btn-blue" @click="closeModal">Close</button>
          </div>
        </div>
      </div>

      <div v-show="renderDeleteTemplate" class="size-text-14px">
        Are you sure you want to delete this note?

        <div class="row tight-columns top-30">
          <div class="col-sm-4">
            <loading-button name="deleteNote" lb-class="primary-btn-red" @lb-click="deleteNote">Delete Note</loading-button>
          </div>

          <div class="col-sm-3 top-20-xs">
            <button type="button" class="nv-button-white" @click="cancelDelete">Cancel</button>
          </div>
        </div>
      </div>
    </template>
  </priori-modal>
</template>

<script>
import CollapsableSpace from 'vue-app/shared/components/collapsable-space.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import ScoutQuoteNote from 'resources/scout/client/scout-quote-note.js';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import TiptapEditor from 'vue-app/scout/shared/tiptap-editor.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'EditNoteModal',

  components: {
    CollapsableSpace,
    PrioriModal,
    LoadingButton,
    TiptapEditor,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    quote: {
      type: Object,
      required: true
    },

    rfq: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      successMessage: '',
      renderFormTemplate: !this.quote.notes.length,
      renderDeleteTemplate: false,
      selectedNote: {
        title: '',
        content: ''
      }
    };
  },

  computed: {
    noteTitle() {
      if (this.quote.notes.length === 0) {
        return 'Leave Note';
      }

      return `Notes (${this.quote.notes.length})`;
    }
  },

  methods: {
    setRenderContext() {
      this.renderFormTemplate = !this.quote.notes.length;
      this.resizeCollapsableSpace();
    },

    /*
    * CollapsableSpace decides whether to show a "Show All" link at the time the element is rendered.  However,
    * since we're in a modal that is hidden initially, the content height is always 0.  We need to manually
    * check the content height at the time that we open the modal.
    *
    * Since the @on-modal-open event fires before the next DOM update cycle, we need to wait for the next tick.
    */
    resizeCollapsableSpace() {
      if (!this.$refs.collapsableSpace) { return; }

      this.$nextTick().then(() => {
        this.$refs.collapsableSpace.forEach(element => {
          element.updateContentHeight();
        });
      });
    },

    saveNote() {
      this.selectedNote.rfqLawFirmQuoteId = this.quote.id;

      LoadingService.loading('saveNote');

      let action;
      let actionText;

      if (this.selectedNote.id) {
        action = ScoutQuoteNote.update(this.selectedNote);
        actionText = 'updated';
      }
      else {
        action = ScoutQuoteNote.save(this.selectedNote);
        actionText = 'saved';
      }

      return action.then(() => {
        return this.queryNotes().then(() => {
          this.reset();
          LoadingService.done('saveNote');

          this.successMessage = `Your note was ${actionText} successfully!`;
        });
      });
    },

    cancelEdit() {
      this.selectedNote = {
        title: '',
        content: ''
      };

      this.successMessage = '';
      this.$refs.validationObserver.reset();

      if (this.quote.notes.length) {
        this.renderFormTemplate = false;
      }
      else {
        this.$refs.modal.closeModal();
      }
    },

    editNote(note) {
      this.selectedNote = Object.assign({}, note);
      this.renderFormTemplate = true;
    },

    reset() {
      this.selectedNote = {
        title: '',
        content: ''
      };

      this.successMessage = '';
      this.renderFormTemplate = !this.quote.notes.length;
      this.renderDeleteTemplate = false;
      this.$refs.validationObserver.reset();
    },

    deleteNote() {
      LoadingService.loading('deleteNote');

      return ScoutQuoteNote.delete(this.selectedNote).then(() => {
        return this.queryNotes().then(() => {
          this.reset();

          LoadingService.done('deleteNote');

          this.successMessage = 'Your note was deleted successfully!';
        });
      });
    },

    showDeleteTemplate(note) {
      this.renderDeleteTemplate = true;
      this.selectedNote = note;
    },

    cancelDelete() {
      this.renderDeleteTemplate = false;
      this.selectedNote = {
        title: '',
        content: ''
      };
    },

    queryNotes() {
      return ScoutQuoteNote.query({ rfqLawFirmQuoteId: this.quote.id }).then(notes => {
        this.quote.notes = notes;
      });
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .success-message {
    background-color: $k-light-green-bg;

    margin-top: 30px;
    margin-bottom: 30px;
    padding: 10px 20px;
    border-radius: $border-radius-large;
    border-left: 6px solid $green-4;

    svg {
      fill: $green-4;
      margin-right: 12px;
    }
  }

  .scrollable-notes {
    padding: 20px;
    max-height: 300px;
    overflow-x: hidden;
    border: 1px solid $k-gray;
    border-radius: $border-radius-large;
  }

  .base-icon.delete-btn {
    width: 12px;
    height: 12px;
    margin-bottom: 3px;
    fill: $k-blue;
  }

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: $k-gray;
    -webkit-border-radius: 1ex;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  @supports (not selector(::-webkit-scrollbar)) {
    html {
      scrollbar-width: thin;
      scrollbar-gutter: stable;
      scrollbar-color: transparent;
    }
  }

  :deep(.faded-section.fade-down)::after {
    background: linear-gradient(180deg, rgba($white, 0) 0%, $k-lighter-gray 100%);
  }
</style>

