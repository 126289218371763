<template>
  <popover
    :target="target"
    trigger="hover-focus"
    placement="bottom"
    :transition="0"
    :custom-class="computedCustomClass">
    <slot></slot>

    <template #popover>
      <slot name="popover-content">
        <div class="row tight-columns vertical-center bottom-10">
          <div class="col-xs-9 col-sm-10">
            {{ scoreLabel }}
          </div>

          <div class="col-xs-3 col-sm-2 text-right">
            {{ score || 'N/A' }}
          </div>
        </div>

        <div class="row tight-columns vertical-center bottom-10">
          <div class="col-xs-9 col-sm-10">
            {{ compLabel }}
          </div>

          <div class="col-xs-3 col-sm-2 text-right">
            {{ compScore || 'N/A' }}
          </div>
        </div>

        <div class="row tight-columns vertical-center">
          <div class="col-xs-9 col-sm-10">
            {{ rangeLabel }}
          </div>

          <div class="col-xs-3 col-sm-2 text-right">
            {{ rangeDisplay }}
          </div>
        </div>
      </slot>
    </template>
  </popover>
</template>

<script>
import { compact } from 'lodash';

export default {
  name: 'RatingBarTooltip',

  props: {
    scoreLabel: {
      type: String,
      required: true
    },

    compLabel: {
      type: String,
      required: true
    },

    rangeLabel: {
      type: String,
      required: true
    },

    score: {
      type: Number,
      required: true
    },

    compScore: {
      type: Number,
      required: true
    },

    rangeMin: {
      type: Number,
      required: true
    },

    rangeMax: {
      type: Number,
      required: true
    },

    target: {
      type: String,
      required: false
    },

    customClass: {
      type: String,
      default: ''
    }
  },

  computed: {
    rangeDisplay() {
      return compact([this.rangeMin, this.rangeMax]).join(' - ') || 'N/A';
    },

    computedCustomClass() {
      return ['tooltip-popover', 'wide', this.customClass].join(' ');
    }
  }
};
</script>
