<template>
  <label :class="['check-option', { 'disabled': disabled }]">
    <slot name="label">
      <span v-if="label" class="normal-weight">{{ label }}</span>
    </slot>

    <input type="checkbox" :name="name" :id="id" :checked="isChecked" @change="toggle">

    <span class="check c-box"><svg-icon name="check-small" class="base-icon"></svg-icon></span>
  </label>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'CheckmarkCheckbox',

  components: {
    SvgIcon
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },

    id: {
      type: String,
      required: false
    },

    name: {
      type: String,
      required: false
    },

    label: {
      type: String,
      required: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isChecked() {
      return this.value;
    }
  },

  methods: {
    toggle() {
      this.$emit('input', !this.isChecked);
    }
  }
};
</script>

<style scoped lang="scss">
  @use "stylesheets/globals/colors";

  .a-form {
    .check-option input[type="checkbox"] {
      top: 1px;

      ~ .check {
        top: 1px;
        width: 16px;
        height: 16px;
        border: 1px solid colors.$gray-3;

        &:hover {
          border-color: colors.$purple-5;
        }
      }
    }

    .check-option input:checked ~ .check {
      border-color: colors.$purple-5;
      background-color: colors.$purple-5;
    }

    .check-option input[type="checkbox"]:checked ~ .check {
      svg {
        position: relative;
        top: -4px;
        left: -2px;
        fill: white;
      }
    }

    .check-option input[type="checkbox"]:not(:checked) ~ .check {
      svg {
        display: none;
      }
    }
  }
</style>
