<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row">
        <div class="col-sm-8 text-uppercase">
          Recipients
        </div>

        <div class="col-sm-4 text-right-not-xs">
          <rfq-add-recipients
            v-if="canEditRfq && onlySentToMarketplace"
            :rfq="rfq"
            :available-firms="getAvailableFirms"
            :add-recipients="addRecipients">
          </rfq-add-recipients>
        </div>
      </div>
    </div>

    <div class="box-content symmetrical">
      <div class="row gutter-30 d-flex-not-xs">
        <div class="col-sm-3 border-right-medium-gray">
          <div class="canvas-container">
            <doughnut-chart :chart-data="chartData" class="bottom-20" width="112px" height="112px" :cutout-percentage="77">
              <div class="quote-count">
                <div v-if="rfq" class="size-text-30px pill-gray-text">
                  {{ quoteCount }}
                </div>
              </div>
            </doughnut-chart>

            <div class="legend">
              <chart-legends
                :legend-data="chartData"
                :column-count="1"
                :initial-max-legends="6">
              </chart-legends>
            </div>
          </div>
        </div>

        <div class="col-sm-9 top-30-xs">
          <div v-if="!onlySentToMarketplace">
            <div class="size-text-15px bold-weight bottom-15 d-flex-not-xs">
              <div class="right-15">
                Your Firms
              </div>

              <rfq-add-recipients
                v-if="canEditRfq"
                class="top-5-xs"
                :rfq="rfq"
                :available-firms="getAvailableFirms"
                :add-recipients="addRecipients">
              </rfq-add-recipients>
            </div>

            <quote-group
              :rfq="rfq"
              :quotes="scoutLawFirmQuotes"
              :view-quote="viewQuote">
            </quote-group>
          </div>

          <rfq-recipients-marketplace
            v-if="isFeatureLab || marketplaceRfqsEnabled"
            :class="{ 'top-30': !onlySentToMarketplace }"
            :rfq="rfq"
            :can-edit-rfq="canEditRfq"
            :marketplace-law-firm-quotes="marketplaceLawFirmQuotes"
            :send-to-marketplace="sendToMarketplace"
            :view-quote="viewQuote">
          </rfq-recipients-marketplace>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DoughnutChart from 'vue-app/shared/components/doughnut-chart.vue';
import QuoteGroup from 'vue-app/scout/client/rfqs/quote-group.vue';
import RfqAddRecipients from 'vue-app/scout/client/rfqs/add-recipients.vue';
import RfqRecipientsMarketplace from 'vue-app/scout/client/rfqs/rfq-recipients-marketplace.vue';
import ChartLegends from 'vue-app/shared/components/chart-legends.vue';
import PrioriColors from 'vue-app/shared/misc/priori-colors';
import { sortBy } from 'lodash';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'RfqRecipients',

  components: {
    DoughnutChart,
    QuoteGroup,
    RfqAddRecipients,
    RfqRecipientsMarketplace,
    ChartLegends
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    availableFirms: {
      type: Array,
      required: true
    },

    addRecipients: {
      type: Function,
      default: () => {}
    },

    canEditRfq: {
      type: Boolean,
      required: true
    },

    onlySentToMarketplace: {
      type: Boolean,
      required: true
    },

    sendToMarketplace: {
      type: Function,
      required: true
    },

    viewQuote: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentProduct: 'currentProduct', user: 'currentUser' }),

    marketplaceRfqsEnabled() {
      return this.user.workspace.marketplaceRfqsEnabled;
    },

    isFeatureLab() {
      return this.currentProduct.isFeatureLab;
    },

    lawFirmQuotes() {
      return this.rfq.lawFirmQuotes || [];
    },

    hasLawFirmQuotes() {
      return this.lawFirmQuotes.length > 0;
    },

    quoteCount() {
      return this.lawFirmQuotes.length;
    },

    getAvailableFirms() {
      if (this.rfq.lawFirmQuotes && this.rfq.lawFirmQuotes.length < 1) {
        return this.availableFirms;
      }

      const firmIdsSet = new Set(this.rfq.lawFirmQuotes.map(item => item.lawFirmId));
      return this.availableFirms.filter(item => !firmIdsSet.has(item.id));
    },

    receivedQuotesFromScout() {
      return this.lawFirmQuotes.filter(quote => {
        return !quote.fromMarketplace && this.quoteReceived(quote);
      });
    },

    receivedQuotesFromMarketplace() {
      return this.lawFirmQuotes.filter(quote => {
        return quote.fromMarketplace && this.quoteReceived(quote);
      });
    },

    pendingQuotes() {
      return this.lawFirmQuotes.filter(quote => {
        return this.quotePending(quote);
      });
    },

    declinedQuotes() {
      return this.lawFirmQuotes.filter(quote => {
        return this.quoteDeclined(quote);
      });
    },

    chartLabelsToDisplay() {
      if (this.onlySentToMarketplace) {
        return ['received-marketplace'];
      }
      else if (this.rfq.sentToMarketplace) {
        return ['received-scout', 'received-marketplace', 'pending', 'declined'];
      }
      else {
        return ['received-scout', 'pending', 'declined'];
      }
    },

    chartData() {
      return [
        {
          name: 'Quotes from Your Firms',
          label: 'received-scout',
          color: PrioriColors.scoutBlue(),
          value: this.receivedQuotesFromScout.length
        },
        {
          name: 'Quotes from Priori’s Marketplace Network',
          label: 'received-marketplace',
          color: PrioriColors.purple(),
          value: this.receivedQuotesFromMarketplace.length
        },
        {
          name: 'Pending Response',
          label: 'pending',
          color: PrioriColors.scoutGray(),
          value: this.pendingQuotes.length
        },
        {
          name: 'Declined',
          label: 'declined',
          color: PrioriColors.scoutRed(),
          value: this.declinedQuotes.length
        }
      ].filter(group => this.chartLabelsToDisplay.includes(group.label));
    },

    scoutLawFirmQuotes() {
      return this.sortByStatus(this.lawFirmQuotes.filter(quote => !quote.fromMarketplace));
    },

    marketplaceLawFirmQuotes() {
      return this.sortByDateReceived(this.lawFirmQuotes.filter(quote => quote.fromMarketplace));
    }
  },

  methods: {
    sortByStatus(quotes) {
      return sortBy(quotes, quote => {
        if (!quote.fromMarketplace && this.quoteReceived(quote)) {
          return 0;
        }

        if (quote.fromMarketplace && this.quoteReceived(quote)) {
          return 1;
        }

        if (this.quotePending(quote)) {
          return 2;
        }

        if (this.quoteDeclined(quote)) {
          return 3;
        }
      });
    },

    sortByDateReceived(quotes) {
      return sortBy(quotes, quote => new Date(quote.dateReceived));
    },

    quoteReceived(quote) {
      return ['quote_submitted', 'selected_by_client', 'declined_by_client'].includes(quote.status);
    },

    quotePending(quote) {
      return ['new_rfq', 'draft'].includes(quote.status);
    },

    quoteDeclined(quote) {
      return ['declined_by_firm'].includes(quote.status);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .border-right-medium-gray {
    @media (min-width: $screen-sm-min) {
      border-right: 1px solid $medium-gray;
    }
  }

  :deep(.doughnut-container) {
    margin: 0 auto;
  }

  .quote-count {
    position: absolute;
    top: 56px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 1.2;
  }
</style>
