<template>
  <div class="lawyer-panel">
    <div class="top-bar">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xs-10">
            Search Summary
          </div>

          <div class="col-xs-2 text-right">
            <a href="" @click.prevent="closePanel"><svg-icon name="x" id="close-icon" class="vertical-baseline base-icon smaller"></svg-icon></a>
          </div>
        </div>
      </div>
    </div>

    <div :class="['panel-content', { 'with-bottom-bar': lawyer.isBillingLawyer() }]">
      <div class="content-section text-center">
        <div class="bottom-20">
          <profile-photo :user="lawyer" :larger="true"></profile-photo>
        </div>

        <div class="size-text-20px">
          {{ lawyer.fullName }}
        </div>

        <div :class="['size-text-13px', { 'text-italic': lawyer.isBillingLawyer() }]">
          {{ lawyer.titleAndFirm(!currentUserIsClient) }}<span v-if="lawyer.isBillingLawyer()">*</span>
          &nbsp;
        </div>

        <div class="bottom-10">
          {{ lawyerLocation }}
          <span v-if="lawyerLocation && currentUserIsClient" class="bold-weight left-5 right-5">&#8231;</span>
          <span v-if="currentUserIsClient"> {{ $pluralize('matter', lawyer.matterCount, true) }}</span>
          &nbsp;
        </div>

        <div v-if="currentUserIsClient" class="vertical-center center-justified">
          <rating-circle :rating="rating" class="right-5"></rating-circle>

          <span v-if="recommendationScore" class="semibold-weight right-5">{{ recommendationScore }}</span>

          <span class="bold-weight right-5">&#8231;</span>

          <span :class="['size-text-12px', { 'dark-gray-text': !lawyer.reviews.count }]">{{ lawyer.reviews.count ? $pluralize('review', lawyer.reviews.count, true) : 'No reviews' }}</span>
        </div>

        <div v-if="rfqEnabled" class="top-10">
          <span class="text-uppercase dark-gray-text semibold-weight size-text-12px">RFP Enabled Law Firm: {{ lawyer.firmHasRfqEnabled ? 'Yes' : 'No' }}</span>
          <svg-icon id="rfp-permission-tooltip" name="info" class="base-icon smaller text-top"></svg-icon>
          <tooltip
            target="#rfp-permission-tooltip"
            placement="bottom"
            custom-class="tooltip-white"
            text="This status indicates whether or not this lawyer’s firm is eligible to receive RFPs. If RFP is not enabled, please contact your administrator to enable RFP for this firm.">
          </tooltip>
        </div>

        <div class="top-20">
          <a :href="profilePath" target="_blank" class="primary-btn-blue profile-link">View Full Profile</a>
        </div>
      </div>

      <hr class="no-margin">

      <div v-if="lawyer.explanation">
        <div class="content-section">
          <div class="row tight-columns">
            <div class="col-xs-6 bold-weight text-uppercase">
              Search Criteria Met
            </div>

            <div class="col-xs-6 text-right">
              <match-tag :score="lawyer.matchScore"></match-tag>
              <span class="semibold-weight">Match</span>
            </div>
          </div>

          <div v-if="searchFilters.matched.length" class="tight-lines top-20">
            <div v-for="(node, index) in searchFilters.matched">
              <div class="row tight-columns">
                <div class="col-xs-1">
                  <svg-icon name="checkmark" class="base-icon lighter-gray vertical-top-text"></svg-icon>
                </div>

                <div :class="['semibold-weight', { 'col-xs-5': !node.mappings && !node.highlights, 'col-xs-11': node.mappings || node.highlights }]">
                  {{ node.label }}
                </div>

                <div v-if="!node.mappings && !node.highlights" class="col-xs-6 text-right">
                  {{ node.value }}
                </div>
              </div>

              <div class="row tight-columns search-highlights text-italic top-15" v-if="node.highlights">
                <div class="col-xs-11 col-xs-offset-1">
                  <span v-for="(highlight, highlightIndex) in node.highlights"><span v-html="highlight"></span><span v-if="highlightIndex !== node.highlights.length - 1">; </span></span>
                </div>
              </div>

              <div class="row tight-columns" v-if="node.mappings">
                <div class="col-xs-11 col-xs-offset-1 text-italic">
                  <div class="top-5">
                    <span class="pill-gray-text">You searched for:</span>
                    &ldquo;{{ node.mappings.prioriMappedName }}&rdquo;
                  </div>

                  <div class="top-2">
                    <span class="pill-gray-text">This profile includes:</span>
                    &ldquo;{{ node.mappings.firmMappedName }}&rdquo;
                  </div>
                </div>
              </div>

              <hr v-if="index !== searchFilters.matched.length - 1" class="search-separator">
            </div>
          </div>

          <div v-if="searchHighlights.length" :class="['tight-lines', { 'top-20': !searchFilters.matched.length }]">
            <hr class="search-separator" v-if="searchFilters.matched.length">

            <div class="row tight-columns">
              <div class="col-xs-1">
                <svg-icon name="checkmark" class="base-icon lighter-gray vertical-top-text"></svg-icon>
              </div>

              <div class="col-xs-5 semibold-weight">
                Keyword
              </div>

              <div class="col-xs-6 text-right">
              </div>
            </div>

            <div class="row tight-columns search-highlights text-italic top-15" v-for="highlights in visibleSearchHighlights">
              <div class="col-xs-11 col-xs-offset-1">
                <span class="pill-gray-text">{{ normalizedField(highlights[0]) }}:</span>
                <span v-for="(highlight, index) in highlights[1]">
                  <span v-html="highlight"></span><span v-if="index !== highlights[1].length - 1">; </span>
                </span>
              </div>
            </div>

            <div class="row tight-columns search-highlights top-15" v-if="hiddenHighlightCount > 0">
              <div class="col-xs-11 col-xs-offset-1">
                <a href="" class="semibold-weight" @click.prevent="showAllHighlights">+&nbsp;{{ hiddenHighlightCount }}&nbsp;more</a>
              </div>
            </div>
          </div>

          <div class="missing-text top-15" v-if="!searchFilters.matched.length && !searchHighlights.length">
            None
          </div>
        </div>

        <hr class="no-margin">

        <div class="content-section">
          <div class="bold-weight text-uppercase">
            Search Criteria Not Met
          </div>

          <div class="tight-lines top-20" v-if="searchFilters.unmatched.length">
            <div v-for="(node, index) in searchFilters.unmatched">
              <div class="row tight-columns">
                <div class="col-xs-1">
                  <svg-icon name="reject" class="base-icon red vertical-top-text"></svg-icon>
                </div>

                <div class="col-xs-5 semibold-weight">
                  {{ node.label }}
                </div>

                <div class="col-xs-6 text-right">
                  {{ node.value }}
                </div>
              </div>

              <hr class="search-separator" v-if="index !== searchFilters.unmatched.length - 1">
            </div>
          </div>

          <div class="missing-text top-15" v-if="!searchFilters.unmatched.length">
            None
          </div>
        </div>
      </div>

      <div v-if="!lawyer.explanation">
        <div class="content-section">
          <div class="bold-weight text-uppercase">
            Search Criteria Met
          </div>

          <div class="missing-text top-15">
            None: add filters to set search criteria
          </div>
        </div>

        <hr class="no-margin">

        <div class="content-section">
          <div class="bold-weight text-uppercase">
            Search Criteria Not Met
          </div>

          <div class="missing-text top-15">
            None: add filters to set search criteria
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-bar" v-if="lawyer.isBillingLawyer()">
      <data-disclaimer-panel
        :firm-name="lawyer.firmName"
        :lawyer-name="lawyer.fullName">
      </data-disclaimer-panel>
    </div>
  </div>
</template>

<script>
import { compact, filter, partition, sortBy, startCase, toPairs } from 'lodash';
import { mapState }        from 'pinia';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import DataDisclaimerPanel from 'vue-app/scout/shared/data-disclaimer-panel.vue';
import MatchTag from 'src/vue-app/scout/shared/match-tag.vue';
import ProfilePhoto from 'vue-app/scout/shared/profile-photo.vue';
import RatingCircle from 'vue-app/scout/shared/rating-circle.vue';
import SharedLists from 'vue-app/shared/services/shared-lists-service.js';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'LawyerPanel',

  components: {
    DataDisclaimerPanel,
    MatchTag,
    ProfilePhoto,
    RatingCircle,
    SvgIcon
  },

  props: {
    closePanel: {
      type: Function,
      required: true
    },

    lawyer: {
      type: Object,
      required: true
    },

    workspace: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      highlightLimit: 3
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    rfqEnabled() {
      return this.currentUser.workspaceHasRfqEnabled;
    },

    lawyerLocation() {
      let stateOrCountry;

      if (this.lawyer.address) {
        if (this.lawyer.address.country === 'United States') {
          stateOrCountry = SharedLists.stateAbbreviationFromName(this.lawyer.address.state);
        }
        else {
          stateOrCountry = this.lawyer.address.country;
        }

        return compact([this.lawyer.address.city, stateOrCountry]).join(', ');
      }
    },

    profilePath() {
      let pathPrefix = window.location.pathname.split('/')[1];

      if (this.workspace) {
        pathPrefix += '/clients/' + this.workspace.id;
      }
      return pathPrefix + '/lawyers/' + this.lawyer.id;
    },

    currentUserIsClient() {
      return this.currentUser.klass === 'Client';
    },

    rating() {
      return { percent: this.recommendationScore * 10 };
    },

    recommendationScore() {
      return Number(this.lawyer.reviews.score).toFixed(1);
    },

    visibleSearchHighlights() {
      return this.searchHighlights.slice(0, this.highlightLimit);
    },

    hiddenHighlightCount() {
      return this.searchHighlights.length - this.highlightLimit;
    },

    explanation() {
      return partition(this.lawyer.explanation, { isMatch: true });
    },

    searchFilters() {
      return {
        matched: filter(this.explanation[0], { hide: false }),
        unmatched: this.explanation[1]
      };
    },

    searchHighlights() {
      const highlights = toPairs(this.lawyer.searchHighlights);
      return sortBy(highlights, (h) => { return h[0]; });
    }
  },

  methods: {
    normalizedField(field) {
      return startCase(field);
    },

    showAllHighlights() {
      this.highlightLimit = this.searchHighlights.length;
    }
  }
};
</script>
<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  #close-icon {
    vertical-align: baseline;
  }

  #rfp-permission-tooltip {
    fill: $gray-3;
    margin-left: 3px;
    margin-top: 2px;
  }
</style>
