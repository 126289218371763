<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit, failed }">
    <form name="lawyerForm" class="a-form" novalidate>
      <div v-if="currentAccountType !== 'admin'" class="bottom-30">
        <account-type-section
          :account-types="accountTypes"
          :lawyer-application="lawyerApplication"
          :invited="invited"
          :is-base-profile="isBaseProfile"
          :selected-account-type="selectedAccountType"
          @account-type-changed="updateAccountType"
          :on-resume-updated="setResumeUpdated">
        </account-type-section>
      </div>

      <div class="shadowed-box">
        <div class="box-content variable-padded">
          <resume-parser
            v-if="currentAccountType !== 'admin' && selectedAccountType.showLawyerFields"
            class="bottom-40"
            :lawyer-application="lawyerApplication"
            :resume-updated="resumeUpdated"
            :on-success="populateResumeData">
          </resume-parser>

          <div class="row md-columns vertical-center-not-xs">
            <div class="col-sm-6 bold-weight size-text-18px">
              Create Your Account
            </div>

            <div class="col-sm-6 d-flex-not-xs flex-end top-10-xs">
              <div v-if="aiGenerated" class="purple-label bold-weight size-text-15px vertical-center">
                <svg-icon name="priori-ai" class="base-icon medium right-10"></svg-icon>AI Generated
              </div>

              <span v-else class="size-text-13px semibold-weight">Already have an account? <a href="/sign-in" target="_self" class="pseudo-link-purple">Sign In</a></span>
            </div>
          </div>

          <div class="top-10 bottom-40">
            <hr class="kenny-hr no-margin">
          </div>

          <div class="size-text-15px d-flex purple-label bold-weight bottom-30">
            Contact Information
          </div>

          <contact-info-section
            class="bottom-40"
            :lawyer="lawyer"
            :lawyer-application="lawyerApplication"
            :has-email="hasEmail"
            :invited="invited">
          </contact-info-section>

          <div v-if="currentAccountType !== 'admin'">
            <div class="size-text-15px d-flex purple-label bold-weight bottom-30">
              Background Information
            </div>

            <div v-if="selectedAccountType.showLawyerFields" class="bottom-30">
              <location-section
                class="bottom-30"
                :lawyer-application="lawyerApplication"
                :countries="countries"
                :states="states">
              </location-section>

              <textarea-input-vertical-with-word-count
                id="practice-description"
                v-model="lawyerApplication.practiceDescription"
                name="practiceDescription"
                label="Practice Overview"
                placeholder="I am a former BigLaw attorney with in-house experience at a venture-backed technology company. In my current practice I represent technology companies and enterprise clients in commercial and technology contracts, data privacy, and transactional work. I also have experience with intellectual property licensing and corporate governance. Representative clients include..."
                :rows="5"
                :max-words="200"
                rules="required">
              </textarea-input-vertical-with-word-count>
            </div>

            <div class="row md-columns bottom-30">
              <div class="col-sm-6">
                <text-input-vertical
                  id="firm-name"
                  :label="`Name of ${ isIndividualLawyer ? 'current' : '' } ${ isAlspAccount ? 'company' : 'firm'}`"
                  v-model="lawyer.firmName"
                  rules="required">
                </text-input-vertical>
              </div>
            </div>

            <div class="bottom-30">
              <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
                <label>Size of current firm <span class="inline-help required">*</span></label>

                <div class="d-flex-not-xs wrap">
                  <label class="check-option medium-large size-text-13px">
                    <span class="normal-weight">1-25 {{ professionalTypeLabel }}</span>
                    <input type="radio" name="firm-size" id="firm-size-sm" v-model="lawyer.firmSize" @change="handleFirmSizeChange('default')" value="1-25 Lawyers" required>
                    <span class="check c-radio"></span>
                  </label>

                  <div class="visible-xs-block"></div>

                  <label class="check-option medium-large size-text-13px top-5-xs">
                    <span class="normal-weight">26-100 {{ professionalTypeLabel }}</span>
                    <input type="radio" name="firm-size" id="firm-size-md" v-model="lawyer.firmSize" @change="handleFirmSizeChange('firm_medium')" value="26-100 Lawyers" required>
                    <span class="check c-radio"></span>
                  </label>

                  <div class="visible-xs-block"></div>

                  <label class="check-option medium-large size-text-13px top-5-xs">
                    <span class="normal-weight">100+ {{ professionalTypeLabel }}</span>
                    <input type="radio" name="firm-size" id="firm-size-lg" v-model="lawyer.firmSize" @change="handleFirmSizeChange('firm_large')" value="100+ Lawyers" required>
                    <span class="check c-radio"></span>
                  </label>
                </div>

                <div class="error-text top-5" v-if="errors.length">
                  <error-message :errors="errors"></error-message>
                </div>
              </validation-provider>
            </div>

            <div v-if="selectedAccountType.showLawyerFields">
              <law-school-section
                class="bottom-30"
                :lawyer="lawyer"
                :lawyer-application="lawyerApplication"
                :educational-institutes="educationalInstitutes"
                :degrees="degrees">
              </law-school-section>

              <div class="bottom-30">
                <bar-license-section
                  class="bottom-10"
                  :lawyer-application="lawyerApplication"
                  :countries="countries"
                  :states="states"
                  :require-bar-admission="requireBarAdmission"
                  :on-remove-bar-license="removeBarLicense">
                </bar-license-section>

                <a href="" class="pseudo-link-purple semibold-weight size-text-13px" @click.prevent="addBarLicense">{{ lawyerApplication.barAdmissionsAttributes.length ? '+ Add Another Bar License' : '+ Add Bar License' }}</a>
              </div>

              <div>
                <label for="hourly-rate" class="size-text-13px">If you offer services on an hourly basis, what is your standard rate (USD)?<span v-if="!lawyerApplication.onlyFlatRates" class="red-text"> *</span></label>

                <div class="row md-columns bottom-15">
                  <div class="col-sm-6">
                    <currency-input-vertical
                      :custom-error-messages="{ minValue: `* Must be greater than $0` }"
                      :id="`hourly-rate`"
                      v-model="lawyerApplication.standardRate"
                      :rules="rateRules">
                    </currency-input-vertical>
                  </div>
                </div>

                <checkmark-checkbox
                  name="onlyFlatRates"
                  id="only-flat-rates"
                  v-model="lawyerApplication.onlyFlatRates"
                  class="vertical medium-large"
                  label="I only offer fixed fees for my services.">
                </checkmark-checkbox>
              </div>

              <div v-if="requireMalpracticeInsurance" class="top-30">
                <validation-provider rules="required" v-slot="{ errors }">
                  <label>Do you carry malpractice insurance? <span class="red-text">*</span></label>

                  <div>
                    <label class="check-option medium-large size-text-13px">
                      <span class="normal-weight">Yes</span>
                      <input type="radio" :value="true" name="hasMalpractice" id="has-malpractice-yes" v-model="lawyerApplication.malpracticeInsurancePolicyAttributes.hasSome" required>
                      <span class="check c-radio"></span>
                    </label>

                    <label class="check-option medium-large size-text-13px">
                      <span class="normal-weight">No</span>
                      <input type="radio" :value="false" name="hasMalpractice" id="has-malpractice-no" v-model="lawyerApplication.malpracticeInsurancePolicyAttributes.hasSome" required>
                      <span class="check c-radio"></span>
                    </label>
                  </div>

                  <div class="error-text" v-if="errors.length">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>
            </div>
          </div>

          <div class="size-text-18px bold-weight bottom-10 top-40">
            Create Password
          </div>

          <div class="top-10 bottom-15">
            <hr class="kenny-hr no-margin">
          </div>

          <div class="size-text-14px bottom-30">
            By providing a password, you&rsquo;ll be able to log in to your profile to keep your information up to date.
          </div>

          <div class="row md-columns bottom-30">
            <div class="col-sm-6">
              <label for="new-user-password">Password <span class="red-text">*</span></label>

              <validation-provider vid="password" v-slot="{ errors, failedRules, changed }" :bails="false" rules="required|oneNumber|min:15|oneUppercaseChar|oneLowercaseChar|oneSpecialChar">
                <input
                  type="password"
                  name="password"
                  id="new-user-password"
                  :class="['form-control', { 'has-error': errors.length && submitClicked }]"
                  placeholder="Password"
                  autocomplete="off"
                  v-model="lawyer.password">

                <div class="error-text top-5" v-if="errors.length">
                  <span v-if="lawyer.password && lawyer.password.length">* Password doesn&rsquo;t meet requirements</span>

                  <span v-else>{{ errors[0] }}</span>
                </div>

                <div class="top-15 size-text-12px dark-gray-text semibold-weight">
                  <div class="bottom-5">
                    Password must have:
                  </div>

                  <ul>
                    <li v-for="(constraint, rule) in passwordRules" :key="rule" class="list-spacing-5">
                      <svg-icon v-if="isValid(rule, failedRules) && changed" name="checkmark" class="base-icon success"></svg-icon>

                      <svg-icon v-else name="exclamation" class="base-icon"></svg-icon>
                      {{ constraint }}
                    </li>
                  </ul>
                </div>
              </validation-provider>
            </div>

            <div class="col-sm-6 top-20-xs">
              <label for="password-confirmation">Confirm Password <span class="red-text">*</span></label>

              <validation-provider vid="passwordConfirmation" v-slot="{ errors }" rules="required|confirmed:password">
                <input
                  type="password"
                  id="password-confirmation"
                  name="passwordConfirmation"
                  :class="['form-control', { 'has-error': errors.length && submitClicked }]"
                  placeholder="Password Confirmation"
                  autocomplete="off"
                  v-model="lawyer.passwordConfirmation">

                <div class="error-text top-5" v-if="errors.length && submitClicked">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </div>

          <div class="bottom-40">
            <validation-provider :rules="{ required: { allowFalse: false } }" :mode="passiveAggressive">
              <label for="agree-to-terms" class="check-option size-text-13px vertical medium-large">
                <span
                  v-if="selectedAccountType.showAgreement && !lawyer.alspAccount"
                  class="dark-grey-text"
                  :disabled="!lawyer.firmSize">
                  I have read and agree to the <a href="" class="pseudo-link-purple" @click.prevent="handleOpenModal">Terms &amp; Conditions</a>
                </span>

                <span
                  v-if="!selectedAccountType.showAgreement && lawyer.alspAccount"
                  class="dark-grey-text">
                  I confirm and acknowledge that my organization has previously entered into an agreement setting out the terms and conditions of joining the Priori Network and agree to abide by such Agreement.
                </span>

                <input type="checkbox" name="agreeToTerms" id="agree-to-terms" v-model="lawyer.agreeToTerms" @click.stop="toggleAgreeToTerms" @change="toggleTermsClicked">
                <span class="check c-box">
                  <svg-icon name="check-small" class="base-icon"></svg-icon>
                </span>
              </label>

              <div class="error-text" v-if="showFirmSizeError">
                * Please select a law firm/company size to view the Terms &amp; Conditions.
              </div>

              <div class="error-text" v-if="submitClicked && showAgreementError">
                * You must agree to the Terms &amp; Conditions before submitting.
              </div>
            </validation-provider>
          </div>

          <loading-button name="submitBaseProfile" lb-class="primary-btn-purple smaller fit-content right-5" @lb-click="setSubmitClicked() && handleSubmit(submit)">Submit</loading-button>

          <div v-if="failed" class="error-text top-10">
            * One or more fields are invalid. Please fix errors as indicated above.
          </div>
        </div>
      </div>

      <input type="checkbox" name="good_check" id="good_check" value="1" class="checking-input">
    </form>

    <lawyer-agreement-modal
      ref="termsModal"
      :lawyer-agreement="lawyerAgreement">
    </lawyer-agreement-modal>
  </validation-observer>
</template>

<script>
import AccountTypeSection from 'vue-app/public/lawyer-sign-up/account-type-section.vue';
import ContactInfoSection from 'vue-app/public/lawyer-sign-up/contact-info-section.vue';
import LocationSection from 'vue-app/public/lawyer-sign-up/location-section.vue';
import LawSchoolSection from 'vue-app/public/lawyer-sign-up/law-school-section.vue';
import BarLicenseSection from 'vue-app/public/lawyer-sign-up/bar-license-section.vue';
import LawyerAgreementModal from 'vue-app/public/lawyer-agreement-modal.vue';
import CurrencyInputVertical from 'vue-app/shared/components/currency-input-vertical.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import TextareaInputVerticalWithWordCount from 'vue-app/shared/components/textarea-input-vertical-with-word-count.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import CheckmarkCheckbox from 'vue-app/shared/components/checkmark-checkbox.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ErrorMessage from 'vue-app/shared/components/error-message.vue';
import ResumeParser from 'vue-app/marketplace/lawyer/shared/resume-parser.vue';
import SharedListsService from 'vue-app/shared/services/shared-lists-service';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import ApplicationFiller from 'services/marketplace/lawyer/application-filler.js';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'LawyerSignUpForm',

  components: {
    ValidationObserver,
    ValidationProvider,
    TextareaInputVerticalWithWordCount,
    TextInputVertical,
    CurrencyInputVertical,
    LoadingButton,
    CheckmarkCheckbox,
    SvgIcon,
    AccountTypeSection,
    ContactInfoSection,
    LocationSection,
    LawSchoolSection,
    BarLicenseSection,
    ResumeParser,
    ErrorMessage,
    LawyerAgreementModal
  },

  mixins: [
    interactionModes
  ],

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    lawyerApplication: {
      type: Object,
      required: true
    },

    educationalInstitutes: {
      type: Array,
      required: true
    },

    isBaseProfile: {
      type: Boolean,
      required: true
    },

    onBaseProfileSubmitted: {
      type: Function,
      required: true
    },

    hasAgreement: {
      type: Boolean,
      default: false
    },

    onUpdateTermsCriteria: {
      type: Function,
      required: true
    },

    invited: {
      type: Boolean,
      default: false
    },

    lawyerAgreement: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      passwordRules: {
        min: 'Minimum of 15 characters',
        oneNumber: 'At least one number',
        oneUppercaseChar: 'At least one uppercase character',
        oneLowercaseChar: 'At least one lowercase character',
        oneSpecialChar: 'At least one special character'
      },
      submitClicked: false,
      hasEmail: !!this.lawyer.email,
      selectedAccountType: {},
      currentAccountType: this.lawyer.accountType,
      showFirmSizeError: false,
      termsClicked: false,
      agreementType: this.lawyerAgreement.name,
      accountTypes: [
        {
          id: 'lawyer',
          type: 'lawyer',
          label: 'Lawyer',
          showAgreement: true,
          showLawyerFields: true
        },
        {
          id: 'paralegal',
          type: 'paralegal',
          label: 'Paralegal',
          showAgreement: true,
          showLawyerFields: true
        },
        {
          id: 'legal_operations_professional',
          type: 'legal_operations_professional',
          label: 'Legal Operations Professional',
          showAgreement: true,
          showLawyerFields: true
        },
        {
          id: 'law_firm_lawyer',
          type: 'lawyer',
          label: 'Lawyer applying on behalf of a Law Firm',
          showAgreement: true,
          showLawyerFields: true
        },
        {
          id: 'law_firm_admin',
          type: 'admin',
          label: 'Administrative professional applying on behalf of a Law Firm',
          showAgreement: true,
          showLawyerFields: false
        },
        {
          id: 'alsp',
          type: 'admin',
          label: 'Administrative professional applying on behalf of a Law/Tech Company',
          showAgreement: false,
          showLawyerFields: false
        }
      ],
      lawyerResume: null,
      aiGenerated: false,
      resumeUpdated: false
    };
  },

  computed: {
    rateRules() {
      return {
        minValue: 0.01,
        required: !this.lawyerApplication.onlyFlatRates
      };
    },

    countries() {
      return SharedListsService.listOfCountries();
    },

    states() {
      return SharedListsService.listOfStateObjects().map(state => state.name);
    },

    degrees() {
      return SharedListsService.degreesWithNames;
    },

    requireMalpracticeInsurance() {
      return this.requireBarAdmission;
    },

    isIndividualLawyer() {
      return ['lawyer', 'paralegal', 'legal_operations_professional'].includes(this.selectedAccountType.id);
    },

    isAlspAccount() {
      return this.lawyer.alspAccount;
    },

    isLegalOperationsProfessional() {
      return this.selectedAccountType.id === 'legal_operations_professional';
    },

    professionalTypeLabel() {
      if (this.isLegalOperationsProfessional) {
        return 'Employees';
      }
      else if (this.isAlspAccount) {
        return 'Professionals';
      }
      else {
        return 'Lawyers';
      }
    },

    showAgreementError() {
      if (this.showFirmSizeError) { return false; }

      return !this.lawyer.agreeToTerms && !this.termsClicked;
    },

    requireBarAdmission() {
      return !['paralegal', 'legal_operations_professional'].includes(this.lawyer.accountType);
    }
  },

  mounted() {
    this.$set(this.lawyer, 'accountType', this.lawyer.accountType || 'lawyer');

    if (this.lawyer.accountType === 'admin') {
      this.selectedAccountType = this.accountTypes.find(a => a.id === (this.isAlspAccount ? 'alsp' : 'law_firm_admin'));
    }
    else {
      this.selectedAccountType = this.accountTypes.find(a => a.type === this.lawyer.accountType);
    }
  },

  methods: {
    updateAccountType(accountType) {
      const previousAccountType            = this.lawyer.accountType;
      this.selectedAccountType             = accountType;
      this.lawyer.accountType              = this.selectedAccountType.type;
      this.lawyerApplication.practiceTypes = [this.selectedAccountType.label];
      this.lawyer.alspAccount              = this.selectedAccountType.id === 'alsp';
      this.lawyer.agreeToTerms             = false;

      if (['law_firm_admin', 'alsp'].includes(this.selectedAccountType.id)) {
        this.lawyer.status = 'base_profile';
      }
      else {
        this.lawyer.status = null;
      }

      this.updateTermsCriteria(this.agreementType);
      this.sanitizeBarAdmissions(previousAccountType);
    },

    toggleAgreeToTerms(event) {
      if (!this.lawyer.firmSize) {
        this.handleOpenModal();
        event.target.checked = false;
        return;
      }

      if (this.validateFirmSizeSelection() && !this.termsClicked && this.selectedAccountType.showAgreement) {
        event.target.checked = true;
        this.lawyer.agreeToTerms = true;
        this.handleOpenModal();
      }
    },

    toggleTermsClicked() {
      this.termsClicked = this.lawyer.agreeToTerms;
    },

    handleOpenModal() {
      if (this.lawyer.firmSize || this.currentAccountType === 'admin') {
        if (this.selectedAccountType.showAgreement) {
          this.$refs.termsModal.openModal();
        }

        this.lawyer.agreeToTerms = true;
        this.termsClicked = true;
        this.showFirmSizeError = false;
      }
      else {
        this.showFirmSizeError = true;
      }
    },

    addBarLicense() {
      this.lawyerApplication.barAdmissionsAttributes.push({
        country: '',
        state: '',
        year: '',
        license: ''
      });
    },

    removeBarLicense(index) {
      this.lawyerApplication.barAdmissionsAttributes.splice(index, 1);
    },

    setResumeUpdated() {
      this.resumeUpdated = true;
    },

    populateResumeData(lawyerResume) {
      const data = lawyerResume.structuredData;
      const filler = new ApplicationFiller(this.lawyerApplication, data, {
        educationalInstitutes: this.educationalInstitutes
      });

      this.lawyerResume = lawyerResume;

      return filler.populateBaseForm(this.lawyer).then(() => {
        if (this.lawyerApplication.addressesAttributes[0].country !== 'United States') {
          this.lawyerApplication.addressesAttributes[0].state = '';
        }

        if (this.requireBarAdmission && (!this.lawyerApplication.barAdmissionsAttributes || !this.lawyerApplication.barAdmissionsAttributes.length)) {
          this.lawyerApplication.barAdmissionsAttributes = [{ country: 'United States' }];
        }

        this.aiGenerated = true;
        this.resumeUpdated = false;
        this.$refs.observer.validate();
      });
    },

    isValid(rule, failedRules) {
      return !Object.keys(failedRules).includes(rule);
    },

    submit() {
      this.onBaseProfileSubmitted();
    },

    updateTermsCriteria(name) {
      if (!this.hasAgreement) {
        this.lawyer.agreeToTerms = false;
        this.termsClicked = false;
      }

      this.agreementType = name;
      this.onUpdateTermsCriteria(name, this.lawyer.accountType);
    },

    handleFirmSizeChange(firmSize) {
      this.validateFirmSizeSelection();
      this.updateTermsCriteria(firmSize);
    },

    validateFirmSizeSelection() {
      this.showFirmSizeError = !this.lawyer.firmSize;
      return !this.showFirmSizeError;
    },

    sanitizeBarAdmissions(previousAccountType) {
      if (!this.requireBarAdmission) {
        this.lawyerApplication.barAdmissionsAttributes = [];
      }
      else if (this.requireBarAdmission && ['paralegal', 'legal_operations_professional'].includes(previousAccountType)) {
        this.lawyerApplication.barAdmissionsAttributes = [{ country: 'United States' }];
      }
    },

    onPasswordChange(password, passwordConfirmation) {
      this.lawyer.password = password;
      this.lawyer.passwordConfirmation = passwordConfirmation;
    },

    setSubmitClicked() {
      this.submitClicked = true;
      return true;
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/globals/variables";

  ul {
    list-style: none;
    padding-inline-start: 0;

    li {
      display: flex;
      column-gap: 7px;

      .base-icon {
        fill: $k-dark-gray;
        width: 16px;

        &.success {
          fill: $k-green;
        }
      }
    }
  }

  :deep(.a-form) {
    .check-option input[type="checkbox"] {
      top: 1px;

      ~ .check {
        top: 1px;
        width: 16px;
        height: 16px;
        border: 1px solid $gray-3;

        &:hover {
          border-color: $purple-5;
        }
      }
    }

    .check-option input:checked ~ .check {
      border-color: $purple-5;
      background-color: $purple-5;
    }

    .check-option input[type="checkbox"]:checked ~ .check {
      svg {
        position: relative;
        top: -4px;
        left: -2px;
        fill: white;
      }
    }

    .check-option input[type="checkbox"]:not(:checked) ~ .check {
      svg {
        display: none;
      }
    }
  }

  :deep(i.counter) {
    font-size: 13px;
  }
</style>
