<template>
  <div v-if="modalOpen">
    <modal v-model="modalOpen" :size="size" :transition="0" :header="false" :footer="false" append-to-body>
      <modal-close-button :on-dismiss="dismissModal"></modal-close-button>

      <div class="notice-modal">
        <div class="notice-header">
          <slot name="title">
            {{ title }}
          </slot>
        </div>

        <div class="notice-regular-text">
          <div class="size-text-14px">
            <slot>
              {{ message }}
            </slot>
          </div>

          <div class="row tight-columns top-30">
            <div class="col-sm-4">
              <button type="button" class="nv-button-blue a-button-size" @click="confirm">{{ yesBtnText }}</button>
            </div>

            <div class="col-sm-3 top-20-xs">
              <button type="button" class="nv-button-white a-button-size" @click="dismissModal">{{ noBtnText }}</button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import modalToggle from 'vue-app/shared/mixins/modal-toggle.js';
import { uniqueId } from 'lodash';

export default {
  name: 'ConfirmationModalSecondary',

  components: {
    ModalCloseButton
  },

  mixins: [
    modalToggle
  ],

  props: {
    title: {
      type: String,
      default: 'Confirm'
    },

    message: {
      type: String,
      default: 'Are you sure?'
    },

    onConfirmAction: {
      type: Function,
      required: true
    },

    size: {
      type: String,
      default: 'md-sm'
    },

    yesBtnText: {
      type: String,
      default: 'Yes'
    },

    noBtnText: {
      type: String,
      default: 'No'
    }
  },

  computed: {
    modalId() {
      return uniqueId('confirmation-modal-');
    }
  },

  methods: {
    confirm() {
      this.onConfirmAction();
      this.closeModal();
    }
  }
};
</script>
