<template>
  <div :class="['rating-circle', sizeClass]">
    <div :class="['rating-indicator', colorClass]"></div>

    <div v-if="percentPresent" class="dot-container">
      <div class="dot-inner-container">
        <div :class="['dot', dotColorClass]"></div>
      </div>
    </div>

    <div class="inset">
      <div class="inset-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RatingCircle',

  props: {
    rating: {
      type: Object,
      required: true
    },

    size: {
      type: String,
      default:  null
    },

    colorClass: {
      type: String,
      default: null
    },

    dotColorClass: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      sizeToClassMap: {
        'medium': 'medium',
        'large':  'large'
      },

      hundredPercentEquivalent: 0.75 // 100% will be mapped to 75% in order to leave space for the bottom gap.
    };
  },

  computed: {
    sizeClass() {
      return this.sizeToClassMap[this.size];
    },

    mappedPercentage() {
      return this.rating.percent * this.hundredPercentEquivalent;
    },

    rotationPercentage() {
      return `${this.mappedPercentage}%`;
    },

    rotationDegrees() {
      return `rotate(${225 + (this.mappedPercentage * 360 / 100)}deg)`;
    },

    percentPresent() {
      return this.rating.percent;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .rating-circle {
    position: relative;
    width: 18px;
    height: 18px;

    .rating-indicator {
      background: $k-blue;
      background:
        conic-gradient(
          $k-blue 0%,
          $k-purple v-bind(rotationPercentage),
          $k-light-gray 0,
          $k-light-gray 75%,
          $white 75%,
          $white 100%
      );
      border-radius: 50%;
      display: inline-block;
      transform: rotate(225deg);
      width: 18px;
      height: 18px;
      -webkit-clip-path: path("M10.4038,4.19962c-.81338-.25368-1.40379-1.0127-1.40379-1.90963C9.00346,1.18836,9.89756,0.29,11,0.29c.19324,0,.38008.02741.55684.07854L11.66776,0L18,0v18h-18v-6.33224l.36854-.11092C0.31741,11.38008,0.29,11.19324,0.29,11c0-1.10244.89836-1.99654,1.99999-1.99999.89693,0,1.65595.59041,1.90963,1.40379M2.29,9l-.00639.00001C2.28574,9,2.28787,9,2.29,9ZM9.00001,2.28361L9,2.29c0-.00213,0-.00426.00001-.00639Z");
      clip-path: path("M10.4038,4.19962c-.81338-.25368-1.40379-1.0127-1.40379-1.90963C9.00346,1.18836,9.89756,0.29,11,0.29c.19324,0,.38008.02741.55684.07854L11.66776,0L18,0v18h-18v-6.33224l.36854-.11092C0.31741,11.38008,0.29,11.19324,0.29,11c0-1.10244.89836-1.99654,1.99999-1.99999.89693,0,1.65595.59041,1.90963,1.40379M2.29,9l-.00639.00001C2.28574,9,2.28787,9,2.29,9ZM9.00001,2.28361L9,2.29c0-.00213,0-.00426.00001-.00639Z");
    }

    .blue-rating-indicator {
      background:
        conic-gradient(
          $k-blue 0%,
          $night-blue v-bind(rotationPercentage),
          $k-light-gray 0,
          $k-light-gray 75%,
          $white 75%,
          $white 100%
      );
    }

    .dot-container {
      height: 18px;
      position: absolute;
      transform: v-bind(rotationDegrees);
      top: 0;
      width: 18px;
      z-index: 1;

      .dot-inner-container {
        .dot {
          background: $k-darker-gray;
          border: 1px solid $white;
          border-radius: 50%;
          height: 6px;
          left: 50%;
          position: absolute;
          transform: translate(-50%, 0);
          top: -1px;
          width: 6px;

          &.purple-dot {
            background: $k-purple;
          }

          &.blue-dot {
            background: $k-blue;
          }
        }
      }
    }

    .inset {
      position: absolute;
      top: 4px;
      left: 4px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $white;
    }

    &.medium {
      height: 32px;
      width: 32px;

      .rating-indicator {
        width: 32px;
        height: 32px;
        -webkit-clip-path: path("M4.15231,17.70501c-.1336-.9749-.96978-1.72593-1.98138-1.72593h-.00001c-1.10457,0-2,.89543-2,2c0,.11524.00975.2282.02846.33811l-.19826.0307L0,11.6885v20.29058h32v-32h-20.29058h6.62869v.19938C18.2282,0.15975,18.11524,0.15,18,0.15c-1.10457,0-2,.89543-2,2v.00001c0,1.0116.75103,1.84778,1.72593,1.98138");
        clip-path: path("M4.15231,17.70501c-.1336-.9749-.96978-1.72593-1.98138-1.72593h-.00001c-1.10457,0-2,.89543-2,2c0,.11524.00975.2282.02846.33811l-.19826.0307L0,11.6885v20.29058h32v-32h-20.29058h6.62869v.19938C18.2282,0.15975,18.11524,0.15,18,0.15c-1.10457,0-2,.89543-2,2v.00001c0,1.0116.75103,1.84778,1.72593,1.98138");
      }

      .dot-container {
        height: 32px;
        width: 32px;
      }

      .inset {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
      }
    }

    &.large {
      width: 150px;
      height: 150px;

      .rating-indicator {
        width: 150px;
        height: 150px;
        -webkit-clip-path: path("M12.78,81.25c0-3.45178-2.79822-6.25-6.25-6.25s-6.25,2.79822-6.25,6.25h-.28L0,150h150v-150h-68.75v.28C77.79822,0.28,75,3.07822,75,6.53s2.79822,6.25,6.25,6.25v4.95775L17.73775,81.25h-4.95775ZM81.25,17.73775M17.73775,81.25");
        clip-path: path("M12.78,81.25c0-3.45178-2.79822-6.25-6.25-6.25s-6.25,2.79822-6.25,6.25h-.28L0,150h150v-150h-68.75v.28C77.79822,0.28,75,3.07822,75,6.53s2.79822,6.25,6.25,6.25v4.95775L17.73775,81.25h-4.95775ZM81.25,17.73775M17.73775,81.25");
      }

      .dot-container {
        height: 150px;
        width: 150px;

        .dot-inner-container {
          .dot {
            border: 4px solid $white;
            height: 20px;
            top: -4.1px;
            width: 20px;
          }
        }
      }

      .inset {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 125px;
        height: 125px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
</style>
