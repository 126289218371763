<template>
  <confirmation-modal-secondary
    ref="modal"
    size="md"
    :title="`${deactivateOrReactivate} law firm`"
    :yes-btn-text="`yes, ${deactivateOrReactivate}`"
    no-btn-text="no, cancel"
    :on-confirm-action="submit">
    <div class="size-text-14px bottom-20">
      {{ message }}
    </div>

    <div class="size-text-14px bold-weight">
      Are you sure you would like to {{ deactivateOrReactivate }} this law firm?
    </div>
  </confirmation-modal-secondary>
</template>

<script>
import ConfirmationModalSecondary from 'vue-app/shared/components/confirmation-modal-secondary.vue';

export default {
  name: 'ToggleFirmActiveModal',

  components: {
    ConfirmationModalSecondary
  },

  props: {
    firm: {
      type: Object,
      default: () => {}
    },

    onSubmit: {
      type: Function,
      required: true
    }
  },

  computed: {
    isScoutConnectionActive() {
      return this.firm?.scoutConnection?.active;
    },

    message() {
      if (this.isScoutConnectionActive) {
        return 'After deactivating a law firm, your team will not be able to leave reviews or send RFPs to the firm. This law firm will be removed as a recipient from any outstanding RFP drafts. This law firm will still be able to respond to any RFPs which were outstanding at the time of deactivation. This firm and all lawyers at this firm will be removed from all lists. Profile information for the deactivated law firm (both law firm and lawyer level) will not be visible and will not appear in search results.';
      }
      else {
        return 'Reactivating this law firm will allow your team to leave reviews and send RFPs to this law firm. After the law firm is reactivated, profile information for the law firm (both law firm and lawyer level) will be visible and will appear in search results.';
      }
    },

    deactivateOrReactivate() {
      return this.isScoutConnectionActive ? 'deactivate' : 'reactivate';
    }
  },

  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },

    submit() {
      this.onSubmit(this.firm.scoutConnection.id);
    }
  }
};
</script>
