<template>
  <application-layout v-if="lawyerApplication.id">
    <top-section
      class="bottom-40"
      :lawyer="lawyer"
      :lawyer-application="lawyerApplication"
      :submitted="!!lawyerApplication.submitted"
      :on-save="updateResume">
    </top-section>

    <clickable-stepper
      v-if="applicationBuilt"
      v-show="!reviewing && !submitted"
      ref="stepper"
      :steps="steps"
      @step-change="step => onStepChange(step)">
      <template #default="{ activeStep, moveToPrevStep, moveToNextStep }">
        <div class="shadowed-box">
          <div class="box-content variable-padded">
            <div v-show="activeStep === 1">
              <practice-step
                ref="practiceFields"
                :lawyer-application="lawyerApplication"
                :practice-areas="practiceAreas"
                :is-paralegal="isParalegal"
                :is-legal-operations-professional="isLegalOperationsProfessional"
                :resume-updated="resumeUpdated"
                :ai-generated="aiGenerated"
                :on-populate-resume-data="populateResumeData"
                :on-move-next="moveToNextStep"
                :on-save="() => save(steps[0])">
              </practice-step>
            </div>

            <div v-show="activeStep === 2">
              <employment-step
                ref="employmentFields"
                :lawyer-application="lawyerApplication"
                :is-paralegal="isParalegal"
                :is-legal-operations-professional="isLegalOperationsProfessional"
                :ai-generated="aiGenerated"
                :on-move-previous="moveToPrevStep"
                :on-move-next="moveToNextStep"
                :on-save="() => save(steps[1])">
              </employment-step>
            </div>

            <div v-show="activeStep === 3">
              <education-step
                ref="educationFields"
                :lawyer-application="lawyerApplication"
                :educational-institutes="educationalInstitutes"
                :full-form-validation-status="fullFormValidationStatus"
                :ai-generated="aiGenerated"
                :on-move-previous="moveToPrevStep"
                :on-move-to-review="review"
                :on-save="() => save(steps[2])">
              </education-step>
            </div>
          </div>
        </div>
      </template>
    </clickable-stepper>

    <review-page
      v-if="reviewing && !submitted"
      :lawyer-application="lawyerApplication"
      :lawyer="lawyer"
      :is-paralegal="isParalegal"
      :is-legal-operations-professional="isLegalOperationsProfessional"
      :practice-areas="practiceAreas"
      :on-move-to-step="moveToStep"
      :on-save="save"
      :on-save-contact-info="saveContactInfo"
      :on-submit="submitApplication">
    </review-page>

    <next-steps-page
      v-if="submitted">
    </next-steps-page>

    <ai-experience-modal
      ref="aiExperienceModal"
      :resume-id-token="lawyerResume?.idToken">
    </ai-experience-modal>
  </application-layout>
</template>

<script>
import ApplicationLayout from 'vue-app/marketplace/lawyer/application/application-layout.vue';
import TopSection from 'vue-app/marketplace/lawyer/application/top-section.vue';
import NextStepsPage from 'vue-app/marketplace/lawyer/application/next-steps-page.vue';
import ClickableStepper from 'vue-app/marketplace/lawyer/application/clickable-stepper.vue';
import PracticeStep from 'vue-app/marketplace/lawyer/application/practice-step.vue';
import EmploymentStep from 'vue-app/marketplace/lawyer/application/employment-step.vue';
import EducationStep from 'vue-app/marketplace/lawyer/application/education-step.vue';
import ReviewPage from 'vue-app/marketplace/lawyer/application/review-page.vue';
import AiExperienceModal from 'vue-app/marketplace/lawyer/shared/ai-experience-modal.vue';
import LawyerApplication from 'resources/lawyer-application.js';
import Lawyer from 'resources/lawyer.js';
import PracticeArea from 'resources/practice-area.js';
import EducationalInstitute from 'resources/educational-institute.js';
import ApplicationFiller from 'services/marketplace/lawyer/application-filler.js';
import LoadingService from 'vue-app/shared/services/loading-service';
import NotificationService from 'vue-app/shared/services/notification-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState, mapActions } from 'pinia';
import { cloneDeep } from 'lodash';

export default {
  name: 'LawyerApplicationEditor',

  components: {
    ApplicationLayout,
    TopSection,
    NextStepsPage,
    ClickableStepper,
    PracticeStep,
    EmploymentStep,
    EducationStep,
    ReviewPage,
    AiExperienceModal
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      lawyerApplication: this.initData.lawyerApplication,
      lawyerResume: null,
      steps: [
        { number: 1, label: 'Practice',   validationStatus: 'pending', formRef: 'practiceFields' },
        { number: 2, label: 'Employment', validationStatus: 'pending', formRef: 'employmentFields' },
        { number: 3, label: 'Education',  validationStatus: 'pending', formRef: 'educationFields' }
      ],
      aiGenerated: false,
      resumeUpdated: true,
      practiceAreas: [],
      educationalInstitutes: [],
      firstInvalidStep: null,
      applicationBuilt: false,
      reviewing: false,
      fullFormValidationStatus: 'pending'
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { lawyer: 'currentUser' }),

    isLegalOperationsProfessional() {
      return this.lawyerApplication.practiceTypes.includes('Legal Operations Professional');
    },

    isParalegal() {
      return this.lawyerApplication.practiceTypes.includes('Paralegal');
    },

    submitted() {
      return this.lawyerApplication.submitted;
    }
  },

  mounted() {
    this.loadPracticeAreas();
    this.loadEducationalInstitutes();

    this.buildBarAdmissions();
    this.buildPracticeAreas();
    this.buildMalpracticePolicy();
    this.buildEmploymentHistory();
    this.buildSchools();

    this.applicationBuilt = true;
    this.$nextTick(() => { this.checkFullForm(); });
  },

  methods: {
    ...mapActions(useCurrentUserStore, { refetchCurrentUser: 'refetchCurrentUser' }),

    loadPracticeAreas() {
      PracticeArea.query().then((practiceAreas) => {
        this.practiceAreas = practiceAreas;
      });
    },

    loadEducationalInstitutes() {
      EducationalInstitute.query().then((response) => {
        this.educationalInstitutes = response;
      });
    },

    buildPracticeAreas() {
      const newPracticeAreasToAdd = 3 - this.lawyerApplication.lawyersPracticeAreasAttributes.length;

      for (let i = 0; i < newPracticeAreasToAdd; i++) {
        this.lawyerApplication.lawyersPracticeAreasAttributes.push({
          id: null,
          name: null,
          practiceAreaId: null
        });
      }
    },

    buildBarAdmissions() {
      if (this.lawyerApplication.barAdmissionsAttributes.length) {
        this.lawyerApplication.barAdmissionsAttributes = this.lawyerApplication.barAdmissionsAttributes.map((bar) => {
          return { ...bar, _destroy: false };
        });
      }
      else if (!this.isParalegal && !this.isLegalOperationsProfessional) {
        this.lawyerApplication.barAdmissionsAttributes.push({
          country: 'United States',
          state: null,
          admitDate: null,
          license: null,
          _destroy: false
        });
      }
    },

    buildMalpracticePolicy() {
      if (this.lawyerApplication.malpracticeInsurancePolicyAttributes || this.isLegalOperationsProfessional) { return; }

      this.lawyerApplication.malpracticeInsurancePolicyAttributes = {
        hasSome: null
      };
    },

    buildEmploymentHistory() {
      if (this.lawyerApplication.employmentHistoriesAttributes.length) { return; }

      this.lawyerApplication.employmentHistoriesAttributes.push({
        country: 'United States',
        state: null,
        city: null,
        title: null,
        firmName: null,
        firmSize: null,
        fromDate: null,
        toDate: null,
        currentPosition: false,
        positionDescription: null,
        webAddress: null
      });
    },

    buildSchools() {
      if (this.lawyerApplication.schoolsAttributes.length) { return; }

      this.lawyerApplication.schoolsAttributes.push({
        name: null,
        degree: null,
        dateEntered: null,
        dateAttained: null,
        awards: null,
        educationalInstituteId: null
      });
    },

    validate(step, silent = false) {
      return this.$refs[step.formRef].validate({ silent }).then((valid) => {
        if (!silent) {
          step.validationStatus = (valid ? 'valid' : 'invalid');
        }
        return valid;
      });
    },

    validateAll(silent = false) {
      return Promise.all([
        this.validate(this.steps[0], silent),
        this.validate(this.steps[1], silent),
        this.validate(this.steps[2], silent)
      ]).then(results => {
        if (results.every(valid => valid)) {
          return true;
        }
        else {
          this.firstInvalidStep = results.findIndex(valid => !valid) + 1;
          return false;
        }
      });
    },

    checkFullForm() {
      this.validateAll(true).then((allValid) => {
        if (allValid) {
          this.reviewing = true;
        }
        else {
          this.moveToStep(this.firstInvalidStep);
        }
      });
    },

    review() {
      this.validateAll().then((allValid) => {
        this.reviewing = allValid;
        this.fullFormValidationStatus = allValid ? 'valid' : 'invalid';

        if (this.reviewing) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      });
    },

    moveToStep(step) {
      this.reviewing = false;
      this.$refs.stepper.goToStep(step);
    },

    onStepChange(step) {
      this.fullFormValidationStatus = 'pending';
      step.validationStatus !== 'pending' && this.validate(step);
    },

    populateResumeData(lawyerResume) {
      const data = lawyerResume.structuredData;
      const filler = new ApplicationFiller(this.lawyerApplication, data, {
        isParalegal: this.isParalegal,
        isLegalOps: this.isLegalOperationsProfessional,
        practiceAreas: this.practiceAreas,
        educationalInstitutes: this.educationalInstitutes
      });

      this.lawyerResume = lawyerResume;

      return filler.populateFullForm().then(() => {
        this.aiGenerated = true;
        this.resumeUpdated = false;
        this.save();
      });
    },

    updateResume(application) {
      LoadingService.loading('saveApplication');

      return LawyerApplication.putForm({ id: application.id, lawyerApplication: application })
        .then((response) => {
          this.resumeUpdated = true;
          this.lawyerApplication.resume = response.resume;
        })
        .catch(() => {
          NotificationService.error('Something went wrong while updating your resume. Please try again.');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        })
        .finally(() => {
          LoadingService.done('saveApplication');
        });
    },

    save(step) {
      const validationPromise = (step ? this.validate(step) : this.validateAll());

      LoadingService.loading('saveApplication');

      return validationPromise.then(() => {
        return LawyerApplication.putForm({ id: this.lawyerApplication.id, lawyerApplication: this.lawyerApplication })
          .then((response) => {
            response.lawyersPracticeAreasAttributes.forEach((practiceArea) => {
              const index = this.lawyerApplication.lawyersPracticeAreasAttributes.findIndex(lpa => lpa.practiceAreaId === practiceArea.practiceAreaId);

              if (index > -1) {
                this.lawyerApplication.lawyersPracticeAreasAttributes.splice(index, 1, cloneDeep(practiceArea));
              }
            });
            response.lawyersPracticeAreasAttributes = this.lawyerApplication.lawyersPracticeAreasAttributes.filter(p => !p._destroy);

            this.lawyerApplication = response;
            this.buildBarAdmissions();
            this.buildMalpracticePolicy();
            this.buildEmploymentHistory();
            this.buildSchools();

            NotificationService.clear();

            return response;
          })
          .catch(() => {
            NotificationService.error('Something went wrong while saving your application. Please try again.');
            window.scrollTo({ top: 0, behavior: 'smooth' });
          })
          .finally(() => {
            LoadingService.done('saveApplication');
          });
      });
    },

    saveContactInfo(data) {
      const lawyerPromise = Lawyer.update({ id: this.lawyer.id, lawyer: data.lawyer });
      const applicationPromise = LawyerApplication.update({ id: this.lawyerApplication.id, lawyerApplication: data.lawyerApplication });

      return Promise.all([lawyerPromise, applicationPromise])
        .then((response) => {
          this.refetchCurrentUser();
          this.lawyerApplication.officeNumber = response[1].officeNumber;
          NotificationService.clear();
        })
        .catch(() => {
          NotificationService.error('Something went wrong while submitting your contact information. Please try again.');
        });
    },

    submitApplication() {
      if (this.lawyerApplication.submitted) { return; }

      LoadingService.loading('submitApplication');

      LawyerApplication.submit(this.lawyerApplication.id, this.lawyerApplication)
        .then(() => {
          this.lawyerApplication.submitted = true;
          this.lawyerApplication.submittedAt = new Date();

          if (this.lawyerResume && this.lawyerResume.id) {
            this.$nextTick(this.$refs.aiExperienceModal.openModal);
          }

          NotificationService.clear();
        })
        .catch(() => {
          NotificationService.error('Something went wrong while submitting your application. Please try again.');
        })
        .finally(() => {
          LoadingService.done('submitApplication');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    }
  }
};
</script>

<style scoped lang="scss">
  @use "stylesheets/globals/colors";

  :deep(.a-form) {
    .form-control {
      border-color: colors.$gray-6;

      &::placeholder {
        color: colors.$gray-3;
      }
    }

    label {
      font-weight: 700;
    }

    .check-option {
      padding-right: 30px;
    }

    .check-option input:checked ~ .check {
      border-color: colors.$purple-5;
      background-color: colors.$purple-5;
    }
  }

  :deep(i.counter) {
    font-size: 13px;
  }

  :deep(.k-dropdown) {
    .dropdown-toggle {
      border-color: colors.$gray-6;

      &.placeholder {
        color: colors.$gray-3;
      }
    }
  }
</style>
