<template>
  <edit-modal title="Editing Representative Matters" :input="matters" :on-save="save" @on-modal-open="resetForm">
    <template #default="{ inputCopy: mattersCopy }">
      <div class="small-bold-header">
        Representative Matters
      </div>

      <hr class="top-5">

      <field-group class="bottom-20" v-for="matter in mattersCopy" :key="matter.id || matter.localId" item-type="representative matter" :on-remove-item="() => { removeMatter(mattersCopy, matter) }">
        <template #input>
          <div class="bottom-20">
            <text-input-vertical label="Title" :id="`title-${matter.id}`" v-model="matter.name" :rules="{ requiresOneOf: { record: matter, fields: ['name', 'description'] }}"></text-input-vertical>
          </div>

          <div class="bottom-20">
            <text-input-vertical label="Client" :id="`client-${matter.id}`" v-model="matter.client"></text-input-vertical>
          </div>

          <div>
            <validation-provider :rules="{ requiresOneOf: { record: matter, fields: ['name', 'description'] }}" v-slot="{ errors }" :mode="passiveAggressive">
              <label :for="`description-${matter.id}`">Description</label>

              <tiptap-editor :id="`description-${matter.id}`" v-model="matter.description"></tiptap-editor>

              <div class="error-text top-5" v-if="errors.length">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>
        </template>
      </field-group>

      <div class="bottom-30">
        <a href="" class="semibold-weight" @click.prevent="addMatter(mattersCopy)">+ {{ mattersCopy.length ? 'Add Another' : 'Add Representative Matter' }}</a>
      </div>
    </template>

    <template #errorMessage>
      * One or more required fields are empty. Please fill in the required fields indicated above or remove that entry from the form.
    </template>
  </edit-modal>
</template>

<script>
import DataLock from 'resources/data-lock';
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import FieldGroup from 'vue-app/scout/shared/field-group.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import { changes } from 'misc/resource-helpers';
import { some, uniqueId } from 'lodash';
import useFieldGroupsStore from 'vue-app/stores/field-groups.js';
import { mapActions } from 'pinia';
import TiptapEditor from 'vue-app/scout/shared/tiptap-editor.vue';
import { ValidationProvider } from 'vee-validate';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';

export default {
  name: 'EditLawyerRepresentativeMatters',

  components: {
    EditModal,
    FieldGroup,
    TiptapEditor,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    matters: {
      type: Array,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      mattersToDestroy: []
    };
  },

  methods: {
    ...mapActions(useFieldGroupsStore, [
      'initializeIdentifiers'
    ]),

    resetForm() {
      this.mattersToDestroy = [];
      this.initializeIdentifiers();
    },

    addMatter(mattersCopy) {
      mattersCopy.push({
        localId: uniqueId('matter_'),
        name: '',
        clientName: '',
        description: ''
      });
    },

    removeMatter(mattersCopy, matter) {
      const index = mattersCopy.indexOf(matter);

      if (index > -1 && matter.id) {
        this.mattersToDestroy.push({ id: matter.id, '_destroy': true });
        mattersCopy.splice(index, 1);
      }
      else if (index > -1) {
        mattersCopy.splice(index, 1);
      }
    },

    lawyerAttributes(editedMatters) {
      return {
        scoutLawyerProfilesAttributes: {
          id: this.lawyer.scoutLawyerProfileId,
          representativeMattersAttributes: [
            ...editedMatters,
            ...this.mattersToDestroy
          ]
        }
      };
    },

    save(editedMatters) {
      const changedReps = changes(this.matters, editedMatters);

      if (some(changedReps)) {
        LoadingService.loading('representative-matters');

        this.onSave(this.lawyerAttributes(editedMatters)).then(
          () => {
            DataLock.save({
              dataLock: {
                flag:         'representative_matters',
                resourceType: 'lawyer',
                resourceId:   this.lawyer.id
              }
            });
          }
        ).finally(
          () => {
            LoadingService.done('representative-matters');
          });
      }
    }
  }
};
</script>
