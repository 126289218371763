<template>
  <validation-observer ref="observer">
    <form name="employmentForm" class="a-form bottom-40" novalidate>
      <div class="row vertical-center-not-xs">
        <div class="col-sm-6 size-text-18px bold-weight">
          Employment
        </div>

        <div class="col-sm-6 d-flex-not-xs flex-end top-10-xs">
          <div v-if="aiGenerated" class="purple-label bold-weight size-text-15px vertical-center">
            <svg-icon name="priori-ai" class="base-icon medium right-10"></svg-icon>AI Generated
          </div>
        </div>
      </div>

      <hr class="top-10 bottom-40">

      <div class="bottom-40">
        <label for="linked-in-url" class="bold-weight">Your LinkedIn URL</label>

        <div class="row bottom-5">
          <div class="col-sm-7">
            <text-input-vertical
              id="linked-in-url"
              name="linkedInUrl"
              v-model="lawyerApplication.linkedInUrl">
            </text-input-vertical>
          </div>
        </div>

        <div class="dark-gray-text text-italic normal-weight size-text-13px">
          Your LinkedIn URL will not be displayed to Priori clients.
        </div>
      </div>

      <div class="bottom-40">
        <label class="purple-label">
          <span class="bold-weight size-text-15px">Relevant Work History</span>
        </label>

        <div class="top-15">
          <work-history-fields
            v-for="(position, index) in lawyerApplication.employmentHistoriesAttributes"
            :key="index"
            class="list-spacing-30"
            :position-data="position"
            :position-number="index + 1"
            :can-remove="canRemove(position)"
            :on-remove-position="removePosition">
          </work-history-fields>
        </div>

        <div class="top-10">
          <button type="button" class="pseudo-link-purple bold-weight size-text-13px" @click="addPosition">+ Add Another Position</button>
        </div>
      </div>

      <div class="bottom-15">
        <label class="purple-label">
          <span class="bold-weight size-text-15px">Additional Information</span>
        </label>
      </div>

      <div class="bottom-30">
        <label class="bold-weight">
          Do you have experience working as a {{ professionalTypes }} in an Am Law 100 law firm?
        </label>

        <validation-provider rules="required" v-slot="{ errors: radioErrors }" :mode="passiveAggressive">
          <div>
            <label class="check-option medium-large">
              <span class="normal-weight">Yes</span>
              <input type="radio" name="amLawExperience" id="am-law-experience-yes" v-model="lawyerApplication.amLawExperience" :value="true" required>
              <span class="check c-radio"></span>
            </label>

            <label class="check-option medium-large">
              <span class="normal-weight">No</span>
              <input type="radio" name="amLawExperience" id="am-law-experience-no" v-model="lawyerApplication.amLawExperience" :value="false" required>
              <span class="check c-radio"></span>
            </label>

            <span v-if="radioErrors.length" class="error-text top-5">
              {{ radioErrors[0] }}
            </span>
          </div>
        </validation-provider>
      </div>

      <div v-if="!isLegalOperationsProfessional" class="bottom-30">
        <label class="bold-weight">
          Do you have experience working as a Partner at a law firm of 25+ lawyers?
        </label>

        <validation-provider :rules="!isParalegal ? 'required' : null" v-slot="{ errors: radioErrors }" :mode="passiveAggressive">
          <div>
            <label class="check-option medium-large">
              <span class="normal-weight">Yes</span>
              <input type="radio" name="partnerExperience" id="partner-experience-yes" v-model="lawyerApplication.partnerExperience" :value="true" required>
              <span class="check c-radio"></span>
            </label>

            <label class="check-option medium-large">
              <span class="normal-weight">No</span>
              <input type="radio" name="partnerExperience" id="partner-experience-no" v-model="lawyerApplication.partnerExperience" :value="false" required>
              <span class="check c-radio"></span>
            </label>

            <span v-if="radioErrors.length" class="error-text top-5">
              {{ radioErrors[0] }}
            </span>
          </div>
        </validation-provider>
      </div>

      <div class="bottom-30">
        <label class="bold-weight">
          Do you have experience working as a {{ professionalTypes }} in-house?
        </label>

        <validation-provider rules="required" v-slot="{ errors: radioErrors }" :mode="passiveAggressive">
          <div>
            <label class="check-option medium-large">
              <span class="normal-weight">Yes</span>
              <input type="radio" name="inHouseExperience" id="in-house-experience-yes" v-model="lawyerApplication.inHouseExperience" :value="true" required>
              <span class="check c-radio"></span>
            </label>

            <label class="check-option medium-large">
              <span class="normal-weight">No</span>
              <input type="radio" name="inHouseExperience" id="in-house-experience-no" v-model="lawyerApplication.inHouseExperience" :value="false" required>
              <span class="check c-radio"></span>
            </label>

            <span v-if="radioErrors.length" class="error-text top-5">
              {{ radioErrors[0] }}
            </span>
          </div>
        </validation-provider>
      </div>

      <div>
        <label class="bold-weight">
          Do you have experience working as a {{ professionalTypes }} in a Fortune 500 company?
        </label>

        <validation-provider rules="required" v-slot="{ errors: radioErrors }" :mode="passiveAggressive">
          <div>
            <label class="check-option medium-large">
              <span class="normal-weight">Yes</span>
              <input type="radio" name="fortuneExperience" id="fortune-experience-yes" v-model="lawyerApplication.fortuneExperience" :value="true" required>
              <span class="check c-radio"></span>
            </label>

            <label class="check-option medium-large">
              <span class="normal-weight">No</span>
              <input type="radio" name="fortuneExperience" id="fortune-experience-no" v-model="lawyerApplication.fortuneExperience" :value="false" required>
              <span class="check c-radio"></span>
            </label>

            <span v-if="radioErrors.length" class="error-text top-5">
              {{ radioErrors[0] }}
            </span>
          </div>
        </validation-provider>
      </div>
    </form>

    <div class="row md-columns">
      <div class="col-sm-6">
        <button type="button" class="nv-button-clear-alt" @click="onMovePrevious">Back</button>
      </div>

      <div class="col-sm-6 text-right-not-xs">
        <loading-button name="saveApplication" :dark="true" lb-class="nv-button-clear-alt top-10-xs right-10" @lb-click="onSave">Save</loading-button>

        <button type="button" class="primary-btn-purple a-button-size fit-content top-10-xs" @click="onMoveNext">Next</button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import WorkHistoryFields from 'vue-app/marketplace/lawyer/application/work-history-fields.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import EmploymentHistory from 'resources/employment-history.js';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { some, indexOf, without } from 'lodash';

export default {
  name: 'EmploymentStep',

  components: {
    TextInputVertical,
    WorkHistoryFields,
    ValidationObserver,
    ValidationProvider,
    LoadingButton
  },

  mixins: [
    interactionModes
  ],

  props: {
    lawyerApplication: {
      type: Object,
      required: true
    },

    isParalegal: {
      type: Boolean,
      required: true
    },

    isLegalOperationsProfessional: {
      type: Boolean,
      required: true
    },

    aiGenerated: {
      type: Boolean,
      default: false
    },

    onMovePrevious: {
      type: Function,
      required: true
    },

    onMoveNext: {
      type: Function,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      uploading: false,
      uploadError: null
    };
  },

  computed: {
    professionalTypes() {
      if (this.isParalegal) {
        return 'lawyer or paralegal';
      }
      else if (this.isLegalOperationsProfessional) {
        return 'lawyer, paralegal, or legal operations role';
      }
      else {
        return 'lawyer';
      }
    }
  },

  methods: {
    addPosition() {
      this.lawyerApplication.employmentHistoriesAttributes.push({
        country: 'United States',
        state: null,
        city: null,
        title: null,
        firmName: null,
        firmSize: null,
        fromDate: null,
        toDate: null,
        currentPosition: null,
        positionDescription: null,
        webAddress: null
      });
    },

    canRemove(position) {
      return !this.isFirst(position) && (!position.currentPosition || this.hasOtherCurrentPosition(position));
    },

    hasOtherCurrentPosition(position) {
      return some(without(this.lawyerApplication.employmentHistoriesAttributes, position), { currentPosition: true });
    },

    isFirst(position) {
      return indexOf(this.lawyerApplication.employmentHistoriesAttributes, position) === 0;
    },

    removePosition(index) {
      const position = this.lawyerApplication.employmentHistoriesAttributes[index];

      if (!this.canRemove(position)) { return; }

      this.lawyerApplication.employmentHistoriesAttributes.splice(index, 1);

      if (position.id) {
        this.deletePosition(position);
      }
    },

    deletePosition(position) {
      if (!position.id || this.lawyerApplication.submitted) { return; }
      EmploymentHistory.delete({ id: position.id });
    },

    validate(silent = false) {
      return this.$refs.observer.validate(silent);
    }
  }
};
</script>
