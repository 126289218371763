<template>
  <div class="shadowed-box">
    <div class="box-content variable-padded">
      <div class="bottom-40">
        <div class="size-text-28px bold-weight bottom-10">
          {{ isBaseProfile ? 'Get Started With Priori' : 'Create Your Priori Account' }}
        </div>

        <div v-if="selectedAccountType.showLawyerFields" class="size-text-14px">
          Please upload your resume and let us know whether you are applying to join the Priori network as a lawyer, paralegal, legal operations professional or other legal service provider.
        </div>
      </div>

      <div>
        <label for="account-type">In what capacity are you applying to join Priori&rsquo;s network?<span class="inline-help required">&nbsp;*</span></label>

        <div class="row md-columns">
          <div class="col-sm-6">
            <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
              <dropdown-select
                ref="accountTypeDropdown"
                v-model="localSelectedAccountType"
                @change="accountTypeChange"
                :options="accountTypes"
                :required="true"
                rules="required"
                id-label="account-type"
                placeholder="Select One"
                value-key="id"
                label-key="label"
                :initial-label="selectedAccountType?.label">
              </dropdown-select>

              <div class="error-text" v-if="errors.length">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>
        </div>
      </div>

      <div v-if="selectedAccountType.showLawyerFields" class="top-30">
        <div class="bottom-5">
          <label>Upload your PDF resume<span v-if="!invited" class="inline-help required"> *</span></label>
        </div>

        <validation-provider :rules="fileRequired" :mode="passiveAggressive" v-slot="{ errors }">
          <div class="vertical-center d-flex wrap col-gap-20 row-gap-10">
            <file-selector
              accept=".pdf"
              @change="replaceFile"
              @errors="setFileErrors"
              :max-size="50">
              <span :class="['clickable fit-content upload-file-btn', hasResume ? 'secondary-btn-purple' : 'primary-btn-purple']">{{ hasResume ? 'Replace Resume' : 'Upload Resume' }}</span>
            </file-selector>

            <span v-if="hasResume" class="selected-file d-flex size-text-13px darker-gray-text bold-weight">{{ lawyerApplication.resume.name }}</span>
          </div>

          <div class="error-text top-5" v-if="errors.length && !hasResume">
            {{ errors[0] }}
          </div>
        </validation-provider>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import interactionModes       from 'vue-app/shared/mixins/interaction-modes.js';
import DropdownSelect         from 'vue-app/shared/components/dropdown-select.vue';
import FileSelector           from 'vue-app/shared/components/file-selector.vue';
import NotificationService    from 'vue-app/shared/services/notification-service.js';

export default {
  name: 'AccountTypeSection',

  components: {
    ValidationProvider,
    DropdownSelect,
    FileSelector
  },

  mixins: [
    interactionModes
  ],

  props: {
    accountTypes: {
      type: Array,
      required: true
    },

    lawyerApplication: {
      type: Object,
      required: true
    },

    invited: {
      type: Boolean,
      required: true
    },

    isBaseProfile: {
      type: Boolean,
      required: false,
      default: false
    },

    selectedAccountType: {
      type: Object,
      required: true
    },

    onResumeUpdated: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      localSelectedAccountType: this.selectedAccountType,
      fileErrors: []
    };
  },

  computed: {
    hasResume() {
      return this.lawyerApplication.resume && !!this.lawyerApplication.resume.name;
    },

    fileRequired() {
      return (this.invited || this.hasResume) ? '' : 'required';
    }
  },

  watch: {
    selectedAccountType(value) {
      this.localSelectedAccountType = value;
    }
  },

  methods: {
    replaceFile(files) {
      if (this.fileErrors.length) {
        NotificationService.error('Something went wrong while saving your resume. Please try again.');
        return;
      }

      this.lawyerApplication.resume = files[0];
      this.onResumeUpdated();
    },

    setFileErrors(errors) {
      this.fileErrors = errors;
    },

    accountTypeChange() {
      const newAccountType = this.accountTypes.find(accountType => accountType.id === this.localSelectedAccountType);
      this.$emit('account-type-changed', newAccountType);
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/globals/variables";

  .upload-file-btn {
    padding: 11px 24px;
    height: auto;
  }

  .selected-file {
    padding: 5px 15px;
    border-radius: 25px;
    border: 1px solid $medium-gray;
    max-width: 100%;
    width: fit-content;
    height: fit-content;
  }
</style>
