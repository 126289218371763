<template>
  <modal v-model="modalOpen" size="md-sm" :header="false" :footer="false" :backdrop="false" :keyboard="false" append-to-body>
    <div class="notice-modal">
      <modal-close-button :on-dismiss="close"></modal-close-button>

      <div class="notice-regular-text">
        <div class="size-text-15px bold-weight text-center bottom-20">
          How was your experience with Priori’s AI?
        </div>

        <div class="row bottom-20">
          <div class="col-sm-10 col-sm-offset-1">
            <div class="clear-panel">
              <div class="d-flex-not-xs flex-center col-gap-15 bottom-10">
                <button type="button" :class="['experience-btn', { 'selected': thumbsUp }]" @click="setPositive"><svg-icon name="thumbs-up" class="base-icon stroke"></svg-icon>Positive</button>

                <button type="button" :class="['experience-btn top-20-xs', { 'selected': thumbsUp === false }]" @click="setNegative"><svg-icon name="thumbs-down" class="base-icon"></svg-icon>Negative</button>
              </div>

              <div class="text-center">
                We appreciate your feedback!
              </div>
            </div>
          </div>
        </div>

        <div class="row md-columns vertical-center">
          <div class="col-xs-8 col-sm-5 col-sm-offset-3">
            <loading-button name="submitExperience" lb-class="primary-btn-purple a-button-size" :lb-disabled="!hasSelection" @lb-click="submit">Submit</loading-button>
          </div>

          <div class="col-xs-4">
            <button type="button" class="pseudo-link-gray" @click="close">Skip</button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import modalToggle from 'vue-app/shared/mixins/modal-toggle.js';
import LawyerResume from 'resources/lawyer-resume.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';

export default {
  name: 'AiExperienceModal',

  components: {
    SvgIcon,
    ModalCloseButton,
    LoadingButton
  },

  mixins: [
    modalToggle
  ],

  props: {
    resumeIdToken: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      thumbsUp: null
    };
  },

  computed: {
    hasSelection() {
      return this.thumbsUp !== null;
    }
  },

  methods: {
    close() {
      this.closeModal();
      this.reset();
    },

    reset() {
      this.thumbsUp = null;
    },

    setPositive() {
      this.thumbsUp = true;
    },

    setNegative() {
      this.thumbsUp = false;
    },

    submit() {
      if (!this.hasSelection) { return; }

      if (!this.resumeIdToken) {
        this.close();
        return;
      }

      LoadingService.loading('submitExperience');

      LawyerResume.update({ id: '0', idToken: this.resumeIdToken, lawyerResume: { thumbsUp: this.thumbsUp } })
        .catch(() => {
          // Nothing to do, but we don't want to show error
        })
        .finally(() => {
          this.close();
          LoadingService.done('submitExperience');
        });
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .experience-btn {
    display: flex;
    width: 146px;
    padding: 5px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid $purple-5;
    border-radius: 50px;
    background-color: transparent;
    color: $purple-5;
    font-weight: 700;
    outline: none;
    appearance: none;

    &:hover, &.selected {
      background-color: rgba($purple-5, .3);
    }

    .base-icon {
      width: 32px;
      height: 32px;
      stroke: $purple-5;
    }
  }
</style>
