<template>
  <boxed-content title="Highlights" v-if="displayComponent">
    <template #links v-if="canEditProfile">
      <edit-lawyer-highlights :lawyer="lawyer" :on-save="save"></edit-lawyer-highlights>
    </template>

    <loading-section name="lawyer-highlights">
      <display-rules-section-wrapper class="separated-list-items" :data="lawyer.representativeClients" :editing-allowed="canEditProfile">
        <div class="bold-weight bottom-10">
          Representative Clients
        </div>

        <display-rules-data-wrapper :data="lawyer.representativeClients">
          <div class="tag-list">
            <div class="tag-list-item gray compact" v-for="client in lawyer.representativeClients" :key="client.id">
              <div class="text-ellipsis">
                {{ client.name }}
              </div>
            </div>
          </div>
        </display-rules-data-wrapper>
      </display-rules-section-wrapper>

      <display-rules-section-wrapper class="separated-list-items" :data="lawyer.industries" :editing-allowed="canEditProfile">
        <div class="bold-weight bottom-10">
          Industries
        </div>

        <display-rules-data-wrapper :data="sortedIndustries">
          <div class="tag-list">
            <div class="tag-list-item gray compact" v-for="industry in sortedIndustries" :key="industry.id">
              <div class="text-ellipsis">
                {{ industry.name }}
              </div>
            </div>
          </div>
        </display-rules-data-wrapper>
      </display-rules-section-wrapper>
    </loading-section>
  </boxed-content>
</template>

<script>
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import DisplayRulesSectionWrapper from 'vue-app/shared/components/display-rules/display-rules-section-wrapper.vue';
import DisplayRulesDataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import BoxedContent from 'vue-app/shared/components/boxed-content';
import EditLawyerHighlights from './edit-lawyer-highlights';
import LoadingService from 'vue-app/shared/services/loading-service';
import { sortBy } from 'lodash';

export default {
  name: 'LawyerHighlights',

  components: {
    BoxedContent,
    DisplayRulesSectionWrapper,
    DisplayRulesDataWrapper,
    EditLawyerHighlights
  },

  mixins: [displayRules],

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type:     Function,
      default:  () => {}
    }
  },

  computed: {
    displayComponent() {
      const { representativeClients, industries } = this.lawyer;
      return this.anyDataIsPresent([representativeClients, industries]) || this.canEditProfile;
    },

    sortedIndustries() {
      return sortBy(this.lawyer.industries, 'name');
    }
  },

  methods: {
    save(lawyerProfileAttributes) {
      LoadingService.loading('lawyer-highlights');
      return this.onSave(lawyerProfileAttributes).finally(
        () => {
          LoadingService.done('lawyer-highlights');
        });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
