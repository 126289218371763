<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row tight-columns vertical-center">
        <div class="col-xs-9 text-uppercase">
          Compare Average Performance
        </div>

        <div v-if="!isDefaultView" class="col-xs-3 text-right">
          <a href="" @click.prevent="resetToDefault">Close</a>
        </div>
      </div>
    </div>

    <div v-if="isDefaultView" class="box-content">
      <div class="row vertical-center-not-xs">
        <div class="col-sm-8 vertical-center">
          <div class="svg-circle vertical-center flex-center no-shrink">
            <svg-icon name="flying-star" class="base-icon"></svg-icon>
          </div>

          <div class="left-20">
            View comparative performance rankings within this group.
          </div>
        </div>

        <div class="col-sm-4 top-20-xs">
          <loading-section name="listItems">
            <button type="button" class="primary-btn-blue" :disabled="!hasResultIds" @click="expandPerformance"><svg-icon name="flying-star" class="base-icon right-5"></svg-icon>Compare {{ formattedResourceType }}</button>
          </loading-section>
        </div>
      </div>
    </div>

    <performance-comparison
      v-if="isCompareView"
      :result-ids="sortedIds"
      :resource-type="resourceType"
      :on-item-selected="handleItemSelected"
      :on-comparison-data-loaded="handleComparisonData">
    </performance-comparison>

    <item-view
      v-if="isItemView"
      :item-id="selectedItem"
      :resource-type="resourceType"
      :comparison-data="comparisonData"
      :solo-view="soloView"
      :item-count="sortedIds.length"
      :on-item-selected="handleItemSelected"
      :on-back-to-all="handleBackButton">
    </item-view>
  </div>
</template>

<script>
import PerformanceComparison from 'vue-app/scout/client/shared/comparison-module/performance-comparison.vue';
import ItemView from 'vue-app/scout/client/shared/comparison-module/item-view.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import { isEqual } from 'lodash';

export default {
  name: 'ComparisonModule',

  components: {
    PerformanceComparison,
    ItemView,
    SvgIcon,
    LoadingSection
  },

  props: {
    resultIds: {
      type: Array,
      required: true
    },

    resourceType: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      currentView: 'default',
      averages: {},
      comparisonData: {},
      selectedItem: null,
      soloView: false
    };
  },

  computed: {
    hasResultIds() {
      return this.resultIds.length > 0;
    },

    sortedIds() {
      return this.resultIds.slice().sort((a, b) => a - b);
    },

    formattedResourceType() {
      return this.resourceType.replace(/_/g, ' ');
    },

    isDefaultView() {
      return this.currentView === 'default';
    },

    isCompareView() {
      return this.currentView === 'comparePerformance';
    },

    isItemView() {
      return this.currentView === 'itemView';
    }
  },

  watch: {
    sortedIds(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        // reset comparison data, this is set in the performance comparison component and it could go to item component
        this.comparisonData = {};
        this.resetToDefault();
      }
    }
  },

  methods: {
    expandPerformance() {
      if (this.resultIds.length === 1) {
        this.selectedItem = this.resultIds[0];
        this.currentView = 'itemView';
        this.soloView = true;
      }
      else {
        this.soloView = false;
        this.currentView = 'comparePerformance';
      }
    },

    resetToDefault() {
      this.currentView = 'default';
    },

    handleItemSelected(item) {
      this.selectedItem = item;
      this.currentView = 'itemView';
    },

    handleBackButton() {
      this.currentView = 'comparePerformance';
    },

    handleComparisonData(data) {
      this.comparisonData = data;
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .svg-circle {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: $newblue-10;

    .base-icon {
      width: 32px;
      height: 32px;
      stroke: $newblue-5;
      fill: $newblue-9;
    }
  }

  .primary-btn-blue .base-icon {
    stroke: white;
    fill: transparent;
  }
</style>
