<template>
  <validation-observer ref="observer">
    <form name="practiceForm" class="a-form" novalidate>
      <resume-parser
        class="bottom-40"
        :lawyer-application="lawyerApplication"
        :resume-updated="resumeUpdated"
        :on-success="onPopulateResumeData">
        <template #instructions>
          If you would like to use AI to automatically fill out your application using information from your resume, please click the AI Autofill button. Please note that this will fill out all three sections of the application.
        </template>

        <template #confirmation>
          Please confirm that you would like to use AI to fill out your application using information from your resume. Please note that this will overwrite anything you have previously input into your application.
        </template>
      </resume-parser>

      <div class="row vertical-center-not-xs">
        <div class="col-sm-6 size-text-18px bold-weight">
          Practice
        </div>

        <div class="col-sm-6 d-flex-not-xs flex-end top-10-xs">
          <div v-if="aiGenerated" class="purple-label bold-weight size-text-15px vertical-center">
            <svg-icon name="priori-ai" class="base-icon medium right-10"></svg-icon>AI Generated
          </div>
        </div>
      </div>

      <hr class="top-10 bottom-40">

      <div class="bottom-30">
        <label for="practice-description" class="bold-weight">
          Biography
        </label>

        <div class="size-text-13px dark-gray-text text-italic bottom-10">
          Please briefly describe your current practice/experience in terms of the types of legal services you regularly provide. If you are admitted to our network, this description will be displayed on your Priori profile.
        </div>

        <textarea-input-vertical-with-word-count
          name="practiceDescription"
          id="practice-description"
          :rows="3"
          rules="required"
          :max-words="200"
          v-model="lawyerApplication.practiceDescription">
        </textarea-input-vertical-with-word-count>
      </div>

      <div class="bottom-30">
        <label class="bold-weight">
          Bar Licensing<span v-if="isParalegal || isLegalOperationsProfessional"> (if applicable)</span>
        </label>

        <div v-for="(bar, index) in barAdmissions" :key="index" class="bottom-10">
          <hr class="kenny-hr visible-xs-block" v-if="index > 0">

          <div class="row md-columns bottom-10">
            <div class="col-sm-3">
              <typeahead-vertical
                :input-name="`barCountry${index}`"
                :id="`bar-country-${index}`"
                placeholder="Country"
                :options="countriesList"
                :force-select="false"
                :hide-icon="true"
                :rules="{ required: true, valueInOptions: { options: countriesList } }"
                v-model="bar.country"
                :initial-value="bar.country"
                @input="barCountrySelected(bar, index)">
              </typeahead-vertical>
            </div>

            <div class="col-sm-3 top-5-xs">
              <typeahead-vertical
                :ref="`stateTypeahead${index}`"
                :disabled="bar.country !== 'United States'"
                :input-name="`barState${index}`"
                :id="`bar-state-${index}`"
                :hide-icon="true"
                :options="bar.country === 'United States' ? statesList : []"
                :placeholder="statePlaceHolder(bar.country)"
                :rules="stateValidationRules(bar.country)"
                :initial-value="bar.state"
                v-model="bar.state">
              </typeahead-vertical>
            </div>

            <div class="col-sm-3 top-5-xs">
              <year-select-vertical
                :name="`barAdmit${index}`"
                :id="`bar-admit-${index}`"
                placeholder="Year"
                :rules="barAdmissionRequired ? 'required' : null"
                :value="getYear(bar.admitDate)"
                @input="year => setYear(bar, year)">
              </year-select-vertical>
            </div>

            <div class="col-sm-3 vertical-center-not-xs col-gap-20 top-5-xs">
              <text-input-vertical
                :name="`barLicense${index}`"
                :id="`bar-license-${index}`"
                placeholder="License Number (optional)"
                v-model="bar.license">
              </text-input-vertical>

              <div v-if="canRemoveBarAdmission(index)" class="minus-icon clickable top-5-xs" @click="removeBarAdmission(bar)">
                <svg-icon name="minus-circle" class="base-icon"></svg-icon>
              </div>
            </div>
          </div>
        </div>

        <div>
          <button type="button" class="pseudo-link-purple bold-weight size-text-13px" @click="addBarAdmission">+ Add {{ barAdmissions.length ? 'Another ' : '' }}Bar License</button>
        </div>
      </div>

      <div v-if="!isLegalOperationsProfessional" class="bottom-30">
        <label for="k-toggle-button-practice-area-0" class="bold-weight">
          Please choose at least one primary practice area.
        </label>

        <div class="size-text-13px dark-gray-text text-italic bottom-10">
          You can expand on this list once you are accepted to the Priori attorney network.
        </div>

        <div class="row md-columns list-spacing-10" v-for="(lpa, index) in lawyerApplication.lawyersPracticeAreasAttributes" :key="index">
          <div class="col-sm-7">
            <validation-provider :rules="{ 'required': !index && !nonEmptyPracticeAreas.length }" v-slot="{ errors: dropdownErrors }" :mode="passiveAggressive">
              <dropdown-select
                :ref="`practiceArea${index}`"
                :id-label="`practice-area-${index}`"
                :initial-label="lpa.name"
                placeholder="Select Practice Area"
                :options="availablePracticeAreas"
                value-key="id"
                label-key="name"
                :required="!index && !nonEmptyPracticeAreas.length"
                :invalid="!!dropdownErrors.length"
                v-model="lpa.practiceAreaId">
              </dropdown-select>

              <div v-if="dropdownErrors.length" class="error-text top-5">
                {{ dropdownErrors[0] }}
              </div>
            </validation-provider>
          </div>
        </div>
      </div>

      <div class="bottom-30">
        <label for="standard-rate" class="bold-weight">
          If you offer services on an hourly basis, what is your standard rate (USD)?
        </label>

        <div class="row md-columns bottom-15">
          <div class="col-sm-7">
            <div class="input-with-icon">
              <text-input-vertical
                input-type="number"
                name="standardRate"
                id="standard-rate"
                :min="0.01"
                :step="0.01"
                :rules="{ required: !lawyerApplication.onlyFlatRates, minValue: 0.01 }"
                v-model="lawyerApplication.standardRate">
              </text-input-vertical>
              <div class="form-icon">$</div>
            </div>
          </div>
        </div>

        <checkmark-checkbox
          name="onlyFlatRates"
          id="only-flat-rates"
          label="I only offer fixed fees for my services."
          class="vertical medium-large"
          v-model="lawyerApplication.onlyFlatRates">
        </checkmark-checkbox>
      </div>

      <div v-if="!isLegalOperationsProfessional" class="bottom-30">
        <label class="bold-weight">
          Do you carry malpractice insurance?
        </label>

        <div>
          <validation-provider rules="required" v-slot="{ errors: radioErrors }" :mode="passiveAggressive">
            <label class="check-option medium-large">
              <span class="normal-weight">Yes</span>
              <input
                type="radio"
                name="hasMalpractice"
                id="has-malpractice-yes"
                :value="true"
                v-model="lawyerApplication.malpracticeInsurancePolicyAttributes.hasSome">
              <span class="check c-radio"></span>
            </label>

            <label class="check-option medium-large">
              <span class="normal-weight">No</span>
              <input
                type="radio"
                name="hasMalpractice"
                id="has-malpractice-no"
                :value="false"
                v-model="lawyerApplication.malpracticeInsurancePolicyAttributes.hasSome">
              <span class="check c-radio"></span>
            </label>

            <span v-if="radioErrors.length" class="error-text top-5">
              {{ radioErrors[0] }}
            </span>
          </validation-provider>
        </div>
      </div>

      <div class="bottom-40">
        <label class="bold-weight">
          Have you ever received a reprimand, censure, suspension or any other form of discipline from any Bar or Court?
        </label>

        <div class="size-text-13px dark-gray-text text-italic bottom-10">
          This information will not be shared with Priori clients.
        </div>

        <validation-provider rules="required" v-slot="{ errors: radioErrors }" :mode="passiveAggressive">
          <div>
            <label class="check-option medium-large">
              <span class="normal-weight">Yes</span>
              <input
                type="radio"
                name="receivedDiscipline"
                id="received-discipline-yes"
                :value="true"
                v-model="lawyerApplication.receivedDiscipline">
              <span class="check c-radio"></span>
            </label>

            <label class="check-option medium-large">
              <span class="normal-weight">No</span>
              <input
                type="radio"
                name="receivedDiscipline"
                id="received-discipline-no"
                :value="false"
                v-model="lawyerApplication.receivedDiscipline">
              <span class="check c-radio"></span>
            </label>

            <span v-if="radioErrors.length" class="error-text top-5">
              {{ radioErrors[0] }}
            </span>
          </div>
        </validation-provider>
      </div>
    </form>

    <div class="text-right-not-xs">
      <loading-button name="saveApplication" :dark="true" lb-class="nv-button-clear-alt right-10" @lb-click="onSave">Save</loading-button>

      <button type="button" class="primary-btn-purple a-button-size fit-content" @click="onMoveNext">Next</button>
    </div>
  </validation-observer>
</template>

<script>
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import TextareaInputVerticalWithWordCount from 'vue-app/shared/components/textarea-input-vertical-with-word-count.vue';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import YearSelectVertical from 'vue-app/shared/components/year-select-vertical.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import CheckmarkCheckbox from 'vue-app/shared/components/checkmark-checkbox.vue';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import ResumeParser from 'vue-app/marketplace/lawyer/shared/resume-parser.vue';
import CountryState from 'vue-app/shared/services/country-state.js';
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  name: 'PracticeStep',

  components: {
    TextInputVertical,
    TextareaInputVerticalWithWordCount,
    TypeaheadVertical,
    YearSelectVertical,
    ValidationProvider,
    ValidationObserver,
    LoadingButton,
    SvgIcon,
    CheckmarkCheckbox,
    DropdownSelect,
    ResumeParser
  },

  mixins: [
    interactionModes
  ],

  props: {
    lawyerApplication: {
      type: Object,
      required: true
    },

    practiceAreas: {
      type: Array,
      required: true
    },

    isParalegal: {
      type: Boolean,
      required: true
    },

    isLegalOperationsProfessional: {
      type: Boolean,
      required: true
    },

    resumeUpdated: {
      type: Boolean,
      default: true
    },

    aiGenerated: {
      type: Boolean,
      default: false
    },

    onMoveNext: {
      type: Function,
      required: true
    },

    onPopulateResumeData: {
      type: Function,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      countryState: new CountryState
    };
  },

  computed: {
    countriesList() {
      return this.countryState.countries;
    },

    statesList() {
      return this.countryState.statesByCountry('United States').map(state => state.name);
    },

    barAdmissionRequired() {
      return !this.isParalegal && !this.isLegalOperationsProfessional;
    },

    barAdmissions() {
      return this.lawyerApplication.barAdmissionsAttributes.filter(bar => !bar._destroy);
    },

    availablePracticeAreas() {
      return this.practiceAreas.filter((practiceArea) => {
        return !this.lawyerApplication.lawyersPracticeAreasAttributes.some(lpa => lpa.practiceAreaId === practiceArea.id);
      });
    },

    nonEmptyPracticeAreas() {
      return this.lawyerApplication.lawyersPracticeAreasAttributes.filter(lpa => lpa.practiceAreaId);
    }
  },

  methods: {
    barCountrySelected(barAdmission, index) {
      if (barAdmission.country !== 'United States' && barAdmission.state) {
        barAdmission.state = null;
        this.$refs[`stateTypeahead${index}`][0].reset();
      }
    },

    statePlaceHolder(country) {
      return country === 'United States' ? 'State' : 'N/A';
    },

    stateValidationRules(country) {
      if (country !== 'United States') { return null; }
      return { required: true, valueInOptions: this.statesList };
    },

    getYear(date) {
      return date ? Number(DateFilter.year(date)) : null;
    },

    setYear(bar, year) {
      bar.admitDate = `${year}-01-01`;
    },

    addBarAdmission() {
      this.lawyerApplication.barAdmissionsAttributes.push({
        country: 'United States',
        state: null,
        admitDate: null,
        license: null,
        _destroy: false
      });
    },

    canRemoveBarAdmission(index) {
      return !this.barAdmissionRequired || index > 0;
    },

    removeBarAdmission(bar) {
      bar._destroy = true;
    },

    validate(silent = false) {
      return this.$refs.observer.validate(silent);
    }
  }
};
</script>

<style scoped lang="scss">
  .minus-icon svg {
    width: 20px;
    height: 20px;
  }
</style>
