import { render, staticRenderFns } from "./matter-law-firm.vue?vue&type=template&id=7affffd8&scoped=true"
import script from "./matter-law-firm.vue?vue&type=script&lang=js"
export * from "./matter-law-firm.vue?vue&type=script&lang=js"
import style0 from "./matter-law-firm.vue?vue&type=style&index=0&id=7affffd8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7affffd8",
  null
  
)

export default component.exports