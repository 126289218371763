<template>
  <validation-observer ref="observer">
    <form name="educationForm" class="a-form bottom-40" novalidate>
      <div class="row vertical-center-not-xs">
        <div class="col-sm-6 size-text-18px bold-weight">
          Education
        </div>

        <div class="col-sm-6 d-flex-not-xs flex-end top-10-xs">
          <div v-if="aiGenerated" class="purple-label bold-weight size-text-15px vertical-center">
            <svg-icon name="priori-ai" class="base-icon medium right-10"></svg-icon>AI Generated
          </div>
        </div>
      </div>

      <hr class="top-10 bottom-15">

      <div class="size-text-14px bottom-40">
        Please list all schools from which you earned a college-level degree or above. Please do not include schools from which you did not attain a degree.
      </div>

      <div class="bottom-10">
        <school-fields
          v-for="(school, index) in lawyerApplication.schoolsAttributes"
          class="list-spacing-30"
          :school="school"
          :school-number="index + 1"
          :educational-institutes="educationalInstitutes"
          :on-remove-school="removeSchool">
        </school-fields>
      </div>

      <button type="button" class="pseudo-link-purple bold-weight size-text-13px" @click="addSchool">+ Add Another School</button>
    </form>

    <div class="row md-columns">
      <div class="col-sm-6">
        <button type="button" class="nv-button-clear-alt" @click="onMovePrevious">Back</button>
      </div>

      <div class="col-sm-6 text-right-not-xs">
        <loading-button name="saveApplication" :dark="true" lb-class="nv-button-clear-alt top-10-xs right-10" @lb-click="onSave">Save</loading-button>

        <button type="button" class="primary-btn-purple a-button-size fit-content top-10-xs" @click="onMoveToReview">Review</button>
      </div>
    </div>

    <div v-if="fullFormInvalid" class="top-5">
      <div class="red-text semibold-weight size-text-11px text-right-not-xs">
        * Please complete all required information before proceeding to review.
      </div>
    </div>
  </validation-observer>
</template>

<script>
import SchoolFields from 'vue-app/marketplace/lawyer/application/school-fields.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import School from 'resources/school.js';
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'EducationStep',

  components: {
    SchoolFields,
    LoadingButton,
    ValidationObserver
  },

  props: {
    lawyerApplication: {
      type: Object,
      required: true
    },

    educationalInstitutes: {
      type: Array,
      required: true
    },

    fullFormValidationStatus: {
      type: String,
      required: true
    },

    aiGenerated: {
      type: Boolean,
      default: false
    },

    onMovePrevious: {
      type: Function,
      required: true
    },

    onMoveToReview: {
      type: Function,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  computed: {
    fullFormInvalid() {
      return this.fullFormValidationStatus === 'invalid';
    }
  },

  methods: {
    addSchool() {
      this.lawyerApplication.schoolsAttributes.push({
        name: null,
        degree: null,
        dateEntered: null,
        dateAttained: null,
        awards: null,
        educationalInstituteId: null
      });
    },

    removeSchool(index) {
      const school = this.lawyerApplication.schoolsAttributes[index];

      if (index > 0) {
        this.lawyerApplication.schoolsAttributes.splice(index, 1);

        if (school.id) {
          this.deleteSchool(school);
        }
      }
    },

    deleteSchool(school) {
      if (!school.id) { return; }
      School.delete({ id: school.id });
    },

    validate(silent = false) {
      return this.$refs.observer.validate(silent);
    }
  }
};
</script>
