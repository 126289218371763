<template>
  <div class="question-wrapper vertical-center">
    <div class="question-icon right-15">
      <svg-icon :name="findIcon" class="base-icon"></svg-icon>
    </div>

    <div class="width-100 right-15">
      <div class="bold-weight">
        {{ question.questionText }}
      </div>

      <div>
        <span class="sub-text">
          {{ findSubText }}
        </span>
      </div>
    </div>

    <div class="d-flex gap-20">
      <rfq-question-form
        v-if="question.isCustomQuestion && showEdit"
        :rfq="rfq"
        :question="question"
        :is-editing="true"
        :on-update-question="onUpdateQuestion"
        :on-delete-question="onDeleteQuestion">
      </rfq-question-form>

      <toggle-button
        v-if="canDelete"
        :id="`toggle-question-${question.id}`"
        :value="question.isIncluded"
        @click="checkedChanged">
      </toggle-button>
    </div>
  </div>
</template>

<script>
import ToggleButton from 'vue-app/shared/components/toggle-button.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import RfqQuestionForm from 'vue-app/scout/client/rfqs/create/question-form.vue';

export default {
  name: 'RfqQuestionItem',

  components: {
    ToggleButton,
    SvgIcon,
    RfqQuestionForm
  },

  props: {
    question: {
      type: Object,
      required: true
    },

    rfq: {
      type: Object,
      required: true
    },

    showEdit: {
      type: Boolean,
      default: true
    },

    onUpdateQuestion: {
      type: Function,
      default: () => {}
    },

    onDeleteQuestion: {
      type: Function,
      default: () => {}
    },

    groupName: {
      type: String,
      required: true
    }
  },

  computed: {
    findIcon() {
      switch (this.question.questionType) {
        case 'text':
          return 'form-text';
        case 'file_upload':
          return 'form-upload';
        case 'option':
          return 'form-radio-button';
        case 'range':
          return 'form-dollar';
        default:
          return 'form-text';
      }
    },

    findSubText() {
      switch (this.question.questionType) {
        case 'text':
          return 'Short answer text';
        case 'file_upload':
          return 'File Upload';
        case 'option':
          return 'Radio buttons + short answer text';
        case 'range':
          return 'Dollar amount(s)';
        default:
          return '';
      }
    },

    ratesAndPricing() {
      return this.groupName === 'Rates & Pricing';
    },

    canDelete() {
      return !this.ratesAndPricing || this.question.isCustomQuestion;
    }
  },

  methods: {
    checkedChanged() {
      this.question.isIncluded = !this.question.isIncluded;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .question-wrapper {
    border-radius: $border-radius-large;
    padding: 10px 15px;
    border: 1px solid $k-gray;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  .sub-text {
    color: $pill-gray;
    border-bottom: 1px dotted $pill-gray;
    font-size: 12px;
  }
</style>
