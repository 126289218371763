<template>
  <priori-modal ref="modal" :title="statusChangeText(rfqStatusOnOpen)" @on-modal-open="setStatusOnOpen" @on-modal-close="reset">
    <template #modal-trigger="{ openModal }">
      <a href="" @click.prevent="openModal">{{ statusChangeText(rfq.status) }}</a>
    </template>

    <template #default="{ closeModal }">
      <validation-observer v-slot="{ handleSubmit }" ref="form">
        <div class="size-text-14px bottom-30">
          Are you sure you want to {{ confirmationStatusText }} RFP?
        </div>

        <div class="row tight-columns">
          <div class="col-sm-5">
            <loading-button name="handleStatusChange" lb-class="primary-btn-blue" @lb-click="handleSubmit(handleStatusChange)">
              {{ statusChangeText(rfqStatusOnOpen) }}
            </loading-button>
          </div>

          <div class="col-sm-3 top-20-xs">
            <button type="button" class="nv-button-white" @click="closeModal">Cancel</button>
          </div>
        </div>
      </validation-observer>
    </template>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'ChangeRfqStatusModal',

  components: {
    PrioriModal,
    LoadingButton,
    ValidationObserver
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    changeRfqStatus: {
      type: Function,
      required: true
    },

    // Only needed when you want to the ability to delete a rfq in draft
    onDelete: {
      type: Function,
      required: false
    }
  },

  data() {
    return {
      // Immediately after the RFQ has its status changed, but before the modal
      // is closed, the text in the modal switches, which is jarring for the
      // user. In order to prevent this, we need to keep track of what the
      // status was at the time the modal was opened.
      rfqStatusOnOpen: ''
    };
  },

  computed: {
    confirmationStatusText() {
      if (this.rfqStatusOnOpen === 'draft') {
        return 'delete this draft';
      }
      else {
        return 'reopen this';
      }
    }
  },

  methods: {
    setStatusOnOpen() {
      this.rfqStatusOnOpen = this.rfq.status;
    },

    statusChangeText(status) {
      if (status === 'draft') {
        return 'Delete Draft';
      }
      else {
        return 'Reopen RFP';
      }
    },

    handleStatusChange() {
      let promise;
      const modal = this.$refs.modal;

      LoadingService.loading('handleStatusChange');

      if (this.rfq.status === 'draft') {
        promise = this.onDelete({ id: this.rfq.id });
      }
      else {
        promise = this.changeRfqStatus(this.rfq);
      }

      promise.then(() => {
        LoadingService.done('handleStatusChange');
        modal.closeModal();
      });
    },

    reset() {
      this.$refs.form.reset();
    }
  }
};
</script>
