import EmploymentHistory from 'resources/employment-history.js';
import School from 'resources/school.js';
import { capitalize, concat } from 'lodash';

class ApplicationFiller {
  constructor(lawyerApplication, data, opts = {}) {
    this.lawyerApplication = lawyerApplication;
    this.data = data;
    this.isParalegal = !!opts.isParalegal;
    this.isLegalOps = !!opts.isLegalOps;
    this.practiceAreas = opts.practiceAreas || [];
    this.educationalInstitutes = opts.educationalInstitutes || [];
  }

  populateBaseForm(lawyer) {
    const school = this.data.schoolsAttributes[0] || {};
    school.educationalInstituteId = this.educationalInstitutes.find(e => e.name === school.name)?.id;
    this.lawyerApplication.schoolsAttributes = [{ ...school }];

    const address = this.data.address || { country: 'United States' };
    this.lawyerApplication.addressesAttributes = [{ ...address, mainAddress: true }];

    this.data.firstName = capitalize(this.data.firstName);
    this.data.lastName = capitalize(this.data.lastName);

    delete this.data.schoolsAttributes;
    delete this.data.address;

    Object.keys(this.data).forEach((key) => {
      if (Object.hasOwn(lawyer, key)) {
        lawyer[key] = this.data[key];
      }
      else if (Object.hasOwn(this.lawyerApplication, key)) {
        this.lawyerApplication[key] = this.data[key];
      }
    });

    return Promise.resolve();
  }

  populateFullForm() {
    this.lawyerApplication.practiceDescription = this.data.practiceDescription;
    this.lawyerApplication.linkedInUrl = this.data.linkedInUrl;

    this.buildBarAdmissions();
    this.buildPracticeAreas();
    this.buildEmploymentHistory();
    this.buildSchools();

    return Promise.resolve();
  }

  buildBarAdmissions() {
    const destroyable = this.lawyerApplication.barAdmissionsAttributes.filter(b => b.id);
    destroyable.forEach((b) => { b._destroy = true; });

    this.lawyerApplication.barAdmissionsAttributes = (this.data.barAdmissionsAttributes || []).map((bar) => {
      return { ...bar, _destroy: false };
    });

    if (!this.lawyerApplication.barAdmissionsAttributes.length && !this.isParalegal && !this.isLegalOps) {
      this.lawyerApplication.barAdmissionsAttributes.push({
        country: 'United States',
        state: null,
        admitDate: null,
        license: null,
        _destroy: false
      });
    }

    this.lawyerApplication.barAdmissionsAttributes = concat(this.lawyerApplication.barAdmissionsAttributes, destroyable);
  }

  buildPracticeAreas() {
    // Grab existing that we might need to destroy
    const destroyable = this.lawyerApplication.lawyersPracticeAreasAttributes.filter(p => p.id);

    // Look for existing based on name and reuse them
    this.data.lawyersPracticeAreasAttributes.forEach((lpa) => {
      const index = destroyable.findIndex(p => p.name === lpa.name);

      if (index > -1) {
        lpa.id = destroyable[index].id;
        lpa.practiceAreaId = destroyable[index].practiceAreaId;

        destroyable.splice(index, 1);
      }
    });

    destroyable.forEach((p) => { p._destroy = true; });

    // Set data and make sure we have at least three
    this.lawyerApplication.lawyersPracticeAreasAttributes = this.data.lawyersPracticeAreasAttributes || [];
    const newPracticeAreasToAdd = 3 - this.lawyerApplication.lawyersPracticeAreasAttributes.length;

    for (let i = 0; i < newPracticeAreasToAdd; i++) {
      this.lawyerApplication.lawyersPracticeAreasAttributes.push({
        name: null,
        practiceAreaId: null
      });
    }

    // Set practiceAreaId from the list of practice areas
    // If we don't find it in the list, set attrs to null
    this.lawyerApplication.lawyersPracticeAreasAttributes.forEach((lpa) => {
      const practiceArea = this.practiceAreas.find(p => p.name === lpa.name);

      if (practiceArea) {
        lpa.practiceAreaId = practiceArea.id;
      }
      else {
        lpa.name = null;
        lpa.practiceAreaId = null;
      }
    });

    this.lawyerApplication.lawyersPracticeAreasAttributes = concat(this.lawyerApplication.lawyersPracticeAreasAttributes, destroyable);
  }

  buildEmploymentHistory() {
    this.lawyerApplication.employmentHistoriesAttributes.forEach((e) => {
      if (e.id) { EmploymentHistory.delete({ id: e.id }); }
    });

    this.lawyerApplication.employmentHistoriesAttributes = this.data.employmentHistoriesAttributes || [];

    if (!this.lawyerApplication.employmentHistoriesAttributes.length) {
      this.lawyerApplication.employmentHistoriesAttributes.push({
        country: 'United States',
        state: null,
        city: null,
        title: null,
        firmName: null,
        firmSize: null,
        fromDate: null,
        toDate: null,
        currentPosition: false,
        positionDescription: null,
        webAddress: null
      });
    }
  }

  buildSchools() {
    let institute;

    this.lawyerApplication.schoolsAttributes.forEach((s) => {
      if (s.id) { School.delete({ id: s.id }); }
    });

    this.lawyerApplication.schoolsAttributes = this.data.schoolsAttributes || [];

    if (!this.lawyerApplication.schoolsAttributes.length) {
      this.lawyerApplication.schoolsAttributes.push({
        name: null,
        degree: null,
        dateEntered: null,
        dateAttained: null,
        awards: null,
        educationalInstituteId: null
      });
    }

    this.lawyerApplication.schoolsAttributes = this.lawyerApplication.schoolsAttributes.map((school) => {
      institute = this.educationalInstitutes.find(e => e.name === school.name);
      school.educationalInstituteId = (institute?.id || null);
      return school;
    });
  }
}

export default ApplicationFiller;
