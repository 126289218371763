<template>
  <div>
    <div class="bottom-30" v-if="reviewsNote">
      <interface-note :note-text="reviewsNote"></interface-note>
    </div>

    <div class="shadowed-box">
      <div class="box-header">
        <div class="row">
          <div class="col-sm-6 text-uppercase">
            Reviews
          </div>

          <div id="review-tabs" class="col-sm-6 text-right-not-xs">
            <div class="top-20-xs">
              <a href="" :class="['subtab-link', { 'selected': isTabSelected('firm') }]" @click.prevent="selectTab('firm')">Law Firm</a>

              <a href="" :class="['subtab-link', { 'selected': isTabSelected('lawyers') }]" @click.prevent="selectTab('lawyers')" v-if="isScout">Lawyers</a>
            </div>
          </div>
        </div>
      </div>

      <div v-show="isTabSelected('firm')">
        <div class="box-content symmetrical">
          <div class="size-text-14px bottom-20">
            All data incorporated into the charts below are derived from law-firm-level reviews left by you and your colleagues.<span v-if="isScout"> Lawyer-level review information can be found on the Lawyers tab.</span>
          </div>

          <div class="clear-panel">
            <div class="row d-flex-not-xs">
              <div class="col-sm-5 col-md-4 first-column">
                <div class="height-100">
                  <div class="text-uppercase bottom-20 bold-weight text-center">
                    Average Recommendation Score
                  </div>

                  <reviews-average-score-with-range
                    :reviews-summary="firmReviewsSummary"
                    :comp-summary="firmCompSummary"
                    review-type="Law Firm"
                    gradient-color="blue">
                  </reviews-average-score-with-range>
                </div>
              </div>

              <div class="col-sm-7 col-md-8 top-20-xs">
                <div class="height-100">
                  <div class="text-uppercase bottom-20 bold-weight text-center">
                    Average Performance
                  </div>

                  <loading-section name="firmReviewTemplate" :render-after-loading="true">
                    <performance-score-with-range
                      :reviews-summary="firmReviewsSummary"
                      :comp-summary="firmCompSummary"
                      :review-template="firmReviewTemplate"
                      review-type="Law Firm"
                      fill-color-class="light-to-dark-blue-background">
                    </performance-score-with-range>
                  </loading-section>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="no-margin">

        <div class="box-content symmetrical">
          <div class="row tight-columns vertical-center-not-xs">
            <div class="col-sm-5 col-md-4" v-if="isScout">
              <search-input
                class="right-10-not-xs"
                placeholder="Search by Matter Number"
                :on-search="applyFirmMatterNumberFilter"
                :on-reset="resetFirmMatterNumberFilterAndCurrentPage">
              </search-input>
            </div>

            <div class="col-sm-3 col-md-5 top-10-xs" v-if="isScout">
              <filter-button
                class="left-5-not-xs"
                label="Review Type"
                :active="firmReviewTypeFilterIsActive">
                <form-with-radio-buttons
                  header="Select Review Type:"
                  :on-click="applyFirmReviewTypeFilter"
                  :options="reviewTypeOptions"
                  :selected="selectedFirmReviewTypeOption">
                </form-with-radio-buttons>
              </filter-button>
            </div>

            <div :class="['col-sm-4 col-md-3 text-right-not-xs', { 'col-sm-offset-8 col-md-offset-9': !isScout }]">
              <review-form-guide-modal
                :load-firms="loadReviewableFirms"
                :load-firm-lawyers="loadReviewableLawyers"
                :preselected-firm="firm"
                preselected-review-type="firm">
                <template #trigger="modalProps">
                  <button type="button" class="primary-btn-blue smaller fit-content" @click="modalProps.openAndLoadFirms()">Leave a Review</button>
                </template>
              </review-form-guide-modal>
            </div>
          </div>
        </div>

        <hr class="no-margin">

        <div class="clearfix">
          <loading-section name="reviewsList" :class="{ 'top-30 bottom-30': isLoading }">
            <reviews-list
              :reviews="firmReviews"
              :review-template="firmReviewTemplate"
              reviews-type="firm">
            </reviews-list>

            <div v-if="filteredFirmReviews && firmReviews.length === 0" class="box-content gray-text size-text-16px">
              No Results
            </div>

            <div v-else-if="!hasFirmReviews" class="box-content gray-text size-text-16px">
              Your company has not reviewed this law firm yet.
            </div>
          </loading-section>
        </div>
      </div>

      <div v-show="isTabSelected('lawyers')">
        <div class="box-content symmetrical">
          <div class="size-text-14px bottom-20">
            All data incorporated into the charts below are derived from lawyer-level reviews left by you and your colleagues. Law-firm-level review information can be found on the Law Firm tab.
          </div>

          <div class="clear-panel">
            <div class="row d-flex-not-xs">
              <div class="col-sm-5 col-md-4 first-column">
                <div class="height-100">
                  <div class="text-uppercase bottom-20 bold-weight text-center">
                    Average Recommendation Score
                  </div>

                  <loading-section name="lawyerReviewsSummary" :render-after-loading="true">
                    <reviews-average-score-with-range
                      :reviews-summary="lawyerReviewsSummary"
                      :comp-summary="lawyerCompSummary"
                      review-type="Lawyer"
                      gradient-color="purple">
                    </reviews-average-score-with-range>
                  </loading-section>
                </div>
              </div>

              <div class="col-sm-7 col-md-8 top-20-xs">
                <div class="height-100">
                  <div class="text-uppercase bottom-20 bold-weight text-center">
                    Average Performance
                  </div>

                  <loading-section name="lawyerReviewsSummary" :render-after-loading="true">
                    <loading-section name="lawyerReviewTemplate" :render-after-loading="true">
                      <performance-score-with-range
                        :reviews-summary="lawyerReviewsSummary"
                        :comp-summary="lawyerCompSummary"
                        :review-template="lawyerReviewTemplate"
                        review-type="Lawyer"
                        fill-color-class="blue-purple-background">
                      </performance-score-with-range>
                    </loading-section>
                  </loading-section>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="no-margin">

        <div class="box-content symmetrical">
          <div class="row tight-columns vertical-center-not-xs">
            <div class="col-sm-5 col-md-4">
              <search-input
                class="right-10-not-xs"
                placeholder="Search by Matter Number"
                :on-search="applyLawyerMatterNumberFilter"
                :on-reset="resetLawyerMatterNumberFilterAndCurrentPage">
              </search-input>
            </div>

            <div class="col-sm-3 col-md-5 top-10-xs">
              <filter-button
                class="left-5-not-xs"
                label="Review Type"
                :active="lawyerReviewTypeFilterIsActive">
                <form-with-radio-buttons
                  header="Select Review Type:"
                  :on-click="applyLawyerReviewTypeFilter"
                  :options="reviewTypeOptions"
                  :selected="selectedLawyerReviewTypeOption">
                </form-with-radio-buttons>
              </filter-button>
            </div>

            <div class="col-sm-4 col-md-3 text-right-not-xs">
              <review-form-guide-modal
                :load-firms="loadReviewableFirms"
                :load-firm-lawyers="loadReviewableLawyers"
                :preselected-firm="firm"
                preselected-review-type="lawyer">
                <template #trigger="modalProps">
                  <button class="primary-btn-blue smaller fit-content" @click="modalProps.openAndLoadFirms()">Leave a Review</button>
                </template>
              </review-form-guide-modal>
            </div>
          </div>
        </div>

        <hr class="no-margin">

        <div class="clearfix">
          <loading-section name="reviewsList" :class="{ 'top-30 bottom-30': isLoading }">
            <reviews-list
              :reviews="lawyerReviews"
              :review-template="lawyerReviewTemplate"
              reviews-type="lawyers">
            </reviews-list>

            <div v-if="filteredLawyerReviews && lawyerReviews.length === 0" class="box-content gray-text size-text-16px">
              No Results
            </div>

            <div v-else-if="!hasLawyerReviews" class="box-content gray-text size-text-16px">
              Your company has not reviewed any lawyers at this law firm yet.
            </div>
          </loading-section>
        </div>
      </div>
    </div>

    <priori-pagination
      class="scout-pagination top-15"
      :current-page="currentPage"
      :total-entries="currentTotalEntries"
      :per-page="10"
      @change="changeReviewsPage">
    </priori-pagination>
  </div>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import InterfaceNote from 'vue-app/shared/components/interface-note.vue';
import ReviewsAverageScoreWithRange from 'vue-app/scout/client/shared/reviews-average-score-with-range.vue';
import PerformanceScoreWithRange from 'vue-app/scout/shared/performance-score-with-range.vue';
import ReviewsList from 'vue-app/scout/client/law-firms/reviews-list.vue';
import PrioriPagination from 'vue-app/shared/components/priori-pagination.vue';
import SearchInput from 'vue-app/shared/components/search-input.vue';
import FilterButton from 'vue-app/shared/components/filter-button.vue';
import FormWithRadioButtons from 'vue-app/shared/components/form-with-radio-buttons';
import ReviewFormGuideModal from 'vue-app/scout/client/reviews/review-form-guide-modal';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'FirmReviews',

  components: {
    InterfaceNote,
    LoadingSection,
    PerformanceScoreWithRange,
    PrioriPagination,
    ReviewsAverageScoreWithRange,
    ReviewsList,
    SearchInput,
    FilterButton,
    FormWithRadioButtons,
    ReviewFormGuideModal
  },

  props: {
    firm: {
      type: Object,
      required: true
    },

    firmReviews: {
      type: Array,
      required: true
    },

    lawyerReviews: {
      type: Array,
      required: true
    },

    lawyerReviewsSummary: {
      type: Object,
      required: true
    },

    lawyerCompSummary: {
      type: Object,
      required: true
    },

    reviewsNote: {
      type: String,
      required: false
    },

    firmReviewTemplate: {
      type: Object,
      required: true
    },

    lawyerReviewTemplate: {
      type: Object,
      required: true
    },

    reloadReviews: {
      type: Function,
      required: true
    },

    loadReviewableLawyers: {
      type: Function,
      required: true
    },

    totalFirmReviewCount: {
      type: Number,
      required: true
    },

    totalLawyerReviewCount: {
      type: Number,
      required: true
    },

    filterFirmReviewsByMatterNumber: {
      type: Function,
      required: true
    },

    resetFirmMatterNumberFilter: {
      type: Function,
      required: true
    },

    filterFirmReviewsByType: {
      type: Function,
      required: true
    },

    filterLawyerReviewsByMatterNumber: {
      type: Function,
      required: true
    },

    resetLawyerMatterNumberFilter: {
      type: Function,
      required: true
    },

    filterLawyerReviewsByType: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      currentPage: 1,
      currentSubTab: 'firm',
      firmReviewTypeFilterIsActive: false,
      lawyerReviewTypeFilterIsActive: false,
      filteredFirmReviews: false,
      filteredLawyerReviews: false,
      reviewTypeOptions: [
        { label: 'All', value: 'all' },
        { label: 'Matter', value: 'matter' },
        { label: 'General', value: 'general' }
      ],
      selectedFirmReviewTypeOption: 'all',
      selectedLawyerReviewTypeOption: 'all'
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, ['currentProduct']),

    isScout() {
      return this.currentProduct.isScout;
    },

    firmReviewsSummary() {
      return this.firm.reviewsSummary;
    },

    firmCompSummary() {
      return this.firm.workspaceReviewsSummary;
    },

    hasFirmReviews() {
      return this.firmReviewsSummary.count > 0;
    },

    hasLawyerReviews() {
      return this.lawyerReviewsSummary.count > 0;
    },

    isLoading() {
      return LoadingService.isLoading('reviewsList');
    },

    currentTotalEntries() {
      return this.currentSubTab === 'firm' ? this.totalFirmReviewCount : this.totalLawyerReviewCount;
    }
  },

  methods: {
    isTabSelected(tab) {
      return this.currentSubTab === tab;
    },

    selectTab(tab) {
      if (this.isTabSelected(tab)) { return; }
      this.currentPage = 1;
      this.currentSubTab = tab;
      this.reloadReviews(this.currentPage, this.currentSubTab);
    },

    changeReviewsPage(page) {
      this.currentPage = page;
      this.reloadReviews(page, this.currentSubTab);
    },

    applyFirmMatterNumberFilter(value) {
      this.currentPage = 1;
      this.filterFirmReviewsByMatterNumber(value);
      this.filteredFirmReviews = true;
    },

    resetFirmMatterNumberFilterAndCurrentPage() {
      this.currentPage = 1;
      this.resetFirmMatterNumberFilter();
      this.filteredFirmReviews = false;
    },

    applyFirmReviewTypeFilter(value) {
      this.selectedFirmReviewTypeOption = value;
      this.currentPage = 1;
      this.filterFirmReviewsByType(value);
      this.filteredFirmReviews = value !== 'all';
      this.firmReviewTypeFilterIsActive = value !== 'all';
    },

    applyLawyerMatterNumberFilter(value) {
      this.currentPage = 1;
      this.filterLawyerReviewsByMatterNumber(value);
      this.filteredLawyerReviews = true;
    },

    resetLawyerMatterNumberFilterAndCurrentPage() {
      this.currentPage = 1;
      this.resetLawyerMatterNumberFilter();
      this.filteredLawyerReviews = false;
    },

    applyLawyerReviewTypeFilter(value) {
      this.selectedLawyerReviewTypeOption = value;
      this.currentPage = 1;
      this.filterLawyerReviewsByType(value);
      this.filteredLawyerReviews = value !== 'all';
      this.lawyerReviewTypeFilterIsActive = value !== 'all';
    },

    loadReviewableFirms() {
      return Promise.resolve([this.firm]);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .clear-panel {
    padding: 0;
    border: 0;
    line-height: 1.4;

    @media (min-width: $screen-sm-min) {
      padding: 30px 25px 30px 20px;
      border: 1px solid $k-light-gray;
      border-radius: $border-radius-large;
    }
  }

  .first-column {
    padding-bottom: 20px;
    border-bottom: 1px solid $k-light-gray;

    @media (min-width: $screen-sm-min) {
      padding-right: 20px;
      padding-bottom: 0;
      border-right: 1px solid $k-light-gray;
      border-bottom: 0;
    }
  }
</style>
