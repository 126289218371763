<template>
  <div class="box-content">
    <loading-section name="summaryView" :class="{ 'min-height-550': loading === true }">
      <div class="bottom-20" v-if="!soloView">
        <a href="" class="blue-text bold-weight" @click.prevent="onBackToAll"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Back to overview</a>
      </div>

      <div class="clear-panel">
        <div class="text-center bottom-20">
          <div :class="['circle negative-margin right-5', dotColor]"></div>
          <a :href="itemLink" target="_blank" class="darker-gray-text no-hover right-20">{{ fullName }}</a>
          <div class="visible-xs-block top-10"></div>
          <div class="diamond right-5"></div>
          Average Score for the <span class="bold-weight">{{ itemCount }}</span> {{ formattedText }}
        </div>

        <div class="row d-flex-not-xs">
          <div class="col-sm-5 col-md-4 first-column">
            <div class="height-100">
              <div class="text-uppercase bottom-30 bold-weight text-center">
                Average Recommendation Score
              </div>

              <rating-circle-with-range
                class="center-block bottom-20"
                :rating="roundToTenth(itemRecommendationScore)"
                :max-rating="10"
                :range-min="0"
                :range-max="10"
                :comp-score="roundToTenth(compareRecommendationScore)"
                :gradient-color="gradientColor">
                <div v-if="itemRecommendationScore === 0">
                  <span class="size-text-32px">N/A</span>
                </div>

                <div v-else>
                  <span class="size-text-32px">{{ itemRecommendationScore }}</span>
                  <span class="size-text-18px dark-gray-text">/10</span>
                </div>

                <template #popover-content>
                  <div class="size-text-13px content-header bottom-10 semibold-weight no-wrap">
                    Average Recommendation Score
                  </div>

                  <div class="bottom-10 flex-column gap-10">
                    <div class="vertical-center width-100">
                      <div :class="['circle right-10', dotColor]"></div>
                      <div class="flex-1 right-10">{{ fullName }}</div>
                      <div class="bold-weight">
                        {{ getFormattedFieldScore(getIndividualAverage('recommendation'), 10) }}
                      </div>
                    </div>

                    <div class="vertical-center width-100">
                      <div class="right-10"><div class="diamond"></div></div>
                      <div class="flex-1 right-10">
                        Average Score for the <span class="bold-weight">{{ itemCount }}</span> {{ formattedText }}
                      </div>
                      <div class="bold-weight">
                        {{ getFormattedFieldScore(roundToTenth(compareRecommendationScore),10) }}
                      </div>
                    </div>
                  </div>

                  <div class="underlined text-center">
                    Ranked #{{ getIndividualRank('recommendation') }} within this category
                  </div>
                </template>
              </rating-circle-with-range>

              <priori-modal ref="itemRecomendationModal" :title="'Average Recommendation Score'" v-if="!soloView">
                <template #modal-trigger="{ openModal }">
                  <div class="text-center">
                    <a href="" class="dark-gray-link" @click.prevent="openModal">Ranked #{{ getIndividualRank('recommendation') }}</a> within the {{ itemCount }} {{ formattedText }}
                  </div>
                </template>

                <comparison-table
                  :comparison-data="comparisonData.top_by_field.recommendation"
                  :selected-comparison="'recommendation'"
                  :resource-type="resourceType"
                  :on-item-selected="handleItemSelected">
                </comparison-table>
              </priori-modal>

              <div v-else class="text-center">
                Ranked #1 within the {{ itemCount }} {{ formattedText }}
              </div>
            </div>
          </div>

          <div class="col-sm-7 col-md-8 top-20-xs">
            <div class="height-100">
              <div class="text-uppercase bottom-20 bold-weight text-center">
                Average Performance
              </div>

              <div class="grid-container">
                <div class="category-wrapper bottom-20" v-for="(category, index) in templateCategories" :key="index">
                  <div class="row tight-columns vertical-center">
                    <div class="col-xs-3 tight-lines text-right score-label">
                      {{ category }}
                    </div>

                    <div class="col-xs-9">
                      <rating-bar-with-range
                        :rating="getIndividualAverage(category)"
                        :max-rating="5"
                        :range-min="0"
                        :range-max="5"
                        :comp-score="getCompareAverage(category)"
                        :rating-label="`${fullName}`"
                        :comp-label="`Average of all {{ itemCount }} {{ formattedText }}`"
                        :range-label="'Range Max'"
                        :fill-color-class="fillColor"
                        custom-class="tooltip-white">
                        <template #popover-content>
                          <div class="size-text-13px content-header bottom-10 semibold-weight">
                            {{ category }}
                          </div>

                          <div class="bottom-10 flex-column gap-10">
                            <div class="vertical-center width-100">
                              <div :class="['circle right-10', dotColor]"></div>
                              <div class="flex-1 right-10">{{ fullName }}</div>
                              <div class="bold-weight">
                                {{ getFormattedFieldScore(getIndividualAverage(category), 5) }}
                              </div>
                            </div>

                            <div class="vertical-center width-100">
                              <div class="right-10"><div class="diamond"></div></div>
                              <div class="flex-1 right-10">
                                Average Score for the <span class="bold-weight">{{ itemCount }}</span> {{ formattedText }}
                              </div>
                              <div class="bold-weight">
                                {{ getFormattedFieldScore(roundToTenth(getCompareAverage(category)),5) }}
                              </div>
                            </div>
                          </div>

                          <div class="underlined text-center">
                            Ranked #{{ getIndividualRank(category) }} within this category
                          </div>
                        </template>
                      </rating-bar-with-range>
                    </div>
                  </div>
                </div>
                <div class="background-grid"></div>
                <div class="x-axis-row">
                  <div class="x-axis-col" v-for="legend in responseLegend" :key="legend.value">
                    <span :id="`axis-item-${legend.value}`" class="x-axis-item">{{ legend.value }}</span>
                    <tooltip :target="`#axis-item-${legend.value}`" placement="bottom" custom-class="small-text" :text="legend.legend"></tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </loading-section>
  </div>
</template>

<script>
import RatingCircleWithRange from 'vue-app/scout/shared/rating-circle-with-range.vue';
import RatingBarWithRange from 'vue-app/scout/shared/rating-bar-with-range.vue';
import ScoutLawyerBridge from 'resources/scout/scout-lawyer-bridge.js';
import ScoutFirmBridge from 'resources/scout/scout-firm-bridge.js';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ComparisonTable from 'vue-app/scout/client/shared/comparison-module/comparison-table.vue';
import { round } from 'lodash';
import ScoutLawyerReviewTemplate from 'resources/scout/client/scout-lawyer-review-template.js';
import ScoutFirmReviewTemplate from 'resources/scout/client/scout-firm-review-template.js';

export default {
  name: 'ItemView',

  components: {
    ComparisonTable,
    LoadingSection,
    RatingCircleWithRange,
    RatingBarWithRange,
    SvgIcon
  },

  props: {
    itemId: {
      type: Number,
      required: true
    },

    resourceType: {
      type: String,
      required: true
    },

    comparisonData: {
      type: Object,
      required: true
    },

    itemCount: {
      type: Number,
      required: true
    },

    soloView: {
      type: Boolean,
      default: false
    },

    onItemSelected: {
      type: Function,
      required: true
    },

    onBackToAll: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      localItemData: {},
      loading: true,
      stubCompareData: [],
      templateCategories: []
    };
  },

  computed: {
    isLawyer() {
      return this.resourceType === 'lawyers';
    },

    isLawFirm() {
      return this.resourceType === 'law_firms';
    },

    responseLegend() {
      const template = this.isLawFirm ? ScoutFirmReviewTemplate : ScoutLawyerReviewTemplate;
      return template.responseAnswerLegend().filter(ans => ans.value !== 0);
    },

    compareRecommendationScore() {
      return this.comparisonData?.averages?.filter(item => item.field === 'recommendation')[0]?.score ?? this.itemRecommendationScore;
    },

    fullName() {
      if (this.localItemData.data !== undefined) {
        return this.localItemData.data.fullName || this.localItemData.data.name;
      }
      return null;
    },

    itemLink() {
      return `/scout-company/${this.formattedResourceTypeForUrl}/${this.itemId}`;
    },

    itemRecommendationScore() {
      return this.localItemData?.data?.reviewsSummary?.scoreAverages?.find(item => item.field === 'recommendation')?.score ?? 0;
    },

    formattedResourceType() {
      return this.resourceType.replace(/_/g, ' ');
    },

    formattedResourceTypeForUrl() {
      return this.resourceType.replace(/_/g, '-');
    },

    dotColor() {
      return this.isLawyer ? 'blue-dot' : 'purple-dot';
    },

    fillColor() {
      return this.isLawyer ? 'blue-purple-background' : 'light-to-dark-blue-background';
    },

    gradientColor() {
      return this.isLawyer ? 'purple' : 'blue';
    },

    isSingleItem() {
      return this.itemCount === 1;
    },

    formattedTextResourceType() {
      return this.isSingleItem ? this.formattedResourceType.slice(0, -1) : this.formattedResourceType;
    },

    formattedTextVerb() {
      if (this.isLawFirm) {
        return this.isSingleItem ? 'that meets' : 'that meet';
      }
      else {
        return this.isSingleItem ? 'who meets' : 'who meet';
      }
    },

    formattedText() {
      return `${this.formattedTextResourceType} ${this.formattedTextVerb} the filter criteria`;
    }
  },

  watch: {
    itemId: {
      immediate: true,
      handler() {
        this.setLocalItemData();
      }
    }
  },

  methods: {
    setLocalItemData() {
      LoadingService.loading('summaryView');

      const bridge = this.isLawFirm ? ScoutFirmBridge : ScoutLawyerBridge;

      bridge.reviewSummary({ item_id: this.itemId, view: 'review_summary' })
        .then((response) => {
          this.localItemData = response;
          this.getReviewTemplateFields();
        });
    },

    getFormattedFieldScore(score, denominator) {
      return score > 0 ? `${score}/${denominator}` : 'N/A';
    },

    getIndividualRank(field) {
      return this.comparisonData?.['top_by_field']?.[field]?.findIndex(item => item.id === this.itemId) + 1 || 1;
    },

    getReviewTemplateFields() {
      const template = this.isLawFirm ? ScoutFirmReviewTemplate : ScoutLawyerReviewTemplate;

      template.inWorkspace()
        .then((template) => {
          this.templateCategories = template.reviewQuestions().map((field) => field.title);
        })
        .finally(() => {
          LoadingService.done('summaryView');
          this.loading = false;
        });
    },

    getIndividualAverage(field) {
      return this.localItemData?.data?.reviewsSummary?.scoreAverages?.find(item => item.field === field)?.score ?? 0;
    },

    getCompareAverage(field) {
      // if theres nothing to compare, but the individual has a score, the average is the individual
      return this.comparisonData?.averages?.find(item => item.field === field)?.score ?? this.getIndividualAverage(field);
    },

    getFieldCount(field) {
      return this.localItemData?.data?.reviewsSummary?.scoreAverages?.find(item => item.field === field)?.count ?? 0;
    },

    handleItemSelected(item) {
      this.$refs.itemRecomendationModal.closeModal();
      this.onItemSelected(item);
    },

    roundToTenth(num) {
      return round(num, 1);
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  @media (min-width: $screen-sm-min) {
    // avoids the jarring experience during loading of new data
    .min-height-550 {
      min-height: 550px;
    }
  }

  .clear-panel {
    padding: 0;
    border: 0;
    line-height: 1.4;

    @media (min-width: $screen-sm-min) {
      padding: 20px 25px 20px 20px;
      border: 1px solid $k-light-gray;
      border-radius: $border-radius-large;
    }
  }

  .first-column {
    padding-bottom: 20px;
    border-bottom: 1px solid $k-light-gray;

    @media (min-width: $screen-sm-min) {
      padding-right: 20px;
      padding-bottom: 0;
      border-right: 1px solid $k-light-gray;
      border-bottom: 0;
    }
  }

  .content-header {
    border-bottom: 1px solid $medium-gray;
    padding-bottom: 10px;
    text-align: center;
  }

  :deep(.rating-bar-container) .range-bar {
    display: none;
  }

  :deep(.rating-circle) .layers {
    &.range-inner {
      background: $white !important;
    }

    &.range-outer {
      display: none;
    }
  }

  .no-hover:hover {
    color: inherit;
    text-decoration: underline;
  }

  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    position: relative;

    &.negative-margin {
      top: -1px;
    }

    &.blue-dot {
      background-color: $blue-4;
    }

    &.purple-dot {
      background-color: $k-purple;
    }
  }

  .diamond {
    width: 8px;
    height: 8px;
    transform: rotate(-45deg);
    background-color: $pill-gray;
    border-radius: 1px;
    display: inline-block;
  }

  .grid-container {
    position: relative;
    padding-bottom: 30px;

    .category-wrapper {
      position: relative;
      z-index: 1;

      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }

    .background-grid {
      position: absolute;
      top: 0;
      left: calc(25% + 2.5px);
      width: calc(75% - 2.5px);
      height: calc(100% - 28px);
      background-image:
        repeating-linear-gradient(transparent 0 1px, transparent 1px 100%),
        repeating-linear-gradient(90deg, $k-light-gray 0 1px, transparent 1px 100%);
      background-size: 20% 100%;
      border-right: 1px solid $k-light-gray;
      z-index: 0;
    }

    .score-label {
      font-size: 12px;
      overflow-wrap: break-word;
      hyphens: auto;
    }

    .x-axis-row {
      position: absolute;
      bottom: 0;
      left: calc(25% + 7.5px);
      width: calc(75% - 2.5px);
      height: 20px;
      text-align: right;
      font-size: 11px;
      line-height: 1.3;

      .x-axis-col {
        float: left;
        width: calc(100% / 5);
      }

      .x-axis-item {
        display: inline-block;
        width: 10px;
        cursor: pointer;
        text-align: center;
      }
    }
  }
</style>
