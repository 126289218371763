<template>
  <priori-modal ref="modal" modal-size="md" modal-id="agree-to-terms">
    <template #title>
      Your Agreement
    </template>

    <template #default="{ closeModal }">
      <div class="medium-page-heading text-center bottom-30">
        Terms &amp; Conditions
      </div>

      <div class="size-text-13px bottom-30" v-html="lawyerAgreement.content"></div>

      <div class="row">
        <div class="col-sm-4 col-sm-offset-4">
          <button type="button" class="primary-btn-purple a-button-size" @click="closeModal">Close</button>
        </div>
      </div>
    </template>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';

export default {
  name: 'LawyerAgreementModal',

  components: {
    PrioriModal
  },

  props: {
    lawyerAgreement: {
      type: Object,
      required: true
    }
  },

  methods: {
    openModal() {
      this.$refs.modal.openModal();
    }
  }
};
</script>
