import * as docx from 'docx';
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter.js';
import { compact, startCase, orderBy } from 'lodash';
import saveAs from 'file-saver';
import RfqSuggestedLawyer from 'resources/rfq-suggested-lawyer';

class RfqQuoteToDocx {
  constructor(rfq, quotes, fileNameExtension, questionsOnly = false) {
    this.rfq = rfq;
    this.quotes = quotes;
    this.fileNameExtension = fileNameExtension;
    this.questionsOnly = questionsOnly;
  }

  generateDocx() {
    const { quotes, rfq } = this;

    if (!quotes.length || !rfq) {
      return;
    }

    const doc = new docx.Document({
      sections: [
        {
          children: [
            ...this.generateQuotes()
          ]
        }
      ],
      styles: {
        default: {
          document: {
            run: {
              font: 'Helvetica'
            },
            paragraph: {
              font: 'Helvetica'
            }
          }
        }
      }
    });

    docx.Packer.toBlob(doc).then((blob) => {
      saveAs(blob, this.fileName());
    });
  }

  generateQuotes() {
    return this.quotes.reduce((acc, quote) => {
      return acc.concat(this.generateQuote(quote));
    }, []);
  }

  generateQuote(quote) {
    return [
      ...(this.questionsOnly ? [] : [this.lawFirmInfo(quote), this.quoteSubmittedInfo(quote)]),
      ...this.questionGroups(quote),
      new docx.Paragraph({ children: [new docx.PageBreak()] })
    ];
  }

  lawFirmInfo(quote) {
    const children = [];

    children.push(this.newTextRun({ text: this.firmOrLawyerName(quote), size: 36, bold: true }));

    if (!quote.fromMarketplace) {
      if (quote.lawFirmBridge.firmSize && quote.lawFirmBridge.firmSize.length > 0) {
        children.push(this.newTextRun({ text: quote.lawFirmBridge.firmSize, size: 24 }));
      }

      if (quote.lawFirmBridge.locations && quote.lawFirmBridge.locations.length > 0) {
        children.push(this.newTextRun({ text: quote.lawFirmBridge.locations.map(location => location.city).join(' • '), size: 24 }));
      }
    }

    return new docx.Paragraph({ children: children });
  }

  quoteSubmittedInfo(quote) {
    return new docx.Paragraph({
      children: [this.newTextRun({ text: `Quote Submitted on ${DateFilter.filterDate(quote.dateResponded)}`, size: 24, italics: true })]
    });
  }

  questionGroups(quote) {
    const  questionGroups = this.orderedQuestionGroups(quote);

    return questionGroups.flatMap((group) => {
      const elements = [
        new docx.Paragraph({
          children: [
            this.newTextRun({ text: group.name, size: 24, bold: true, underline: { type: 'single', color: '000000', bold: true } }),
            ...this.questions(quote, group)
          ]
        })
      ];

      if (!this.questionsOnly && group.handlesLawyers && quote.suggestedLawyers.length > 0) {
        const includedLawyers = quote.suggestedLawyers.filter(lawyer => lawyer.includedInQuote);
        const excludedLawyers = quote.suggestedLawyers.filter(lawyer => !lawyer.includedInQuote);

        if (includedLawyers.length > 0) {
          elements.push(this.tableHeaderText());

          elements.push(this.suggestedLawyersTable(includedLawyers));
        }

        if (excludedLawyers.length > 0) {
          elements.push(this.tableHeaderText(false));

          elements.push(this.suggestedLawyersTable(excludedLawyers));
        }
      }

      return elements;
    });
  }

  questions(quote, group) {
    const questions = this.orderedQuestions(group);

    const displayableQuestions = questions
      .filter(question => this.shouldDisplayQuestion(quote, question));

    let displayableQuestionTextRuns;

    if (this.questionsOnly) {
      displayableQuestionTextRuns = displayableQuestions
        .filter(question => question.questionType !== 'file_upload')
        .flatMap(question => this.createQuestionTextRuns(quote, question));
    }
    else {
      displayableQuestionTextRuns = displayableQuestions
        .flatMap(question => this.createQuestionTextRuns(quote, question));
    }

    return displayableQuestionTextRuns;
  }

  tableHeaderText(included = true) {
    const text = included ? 'Included In Quote' : 'Not Included In Quote';

    return new docx.Paragraph({
      children: [this.newTextRun({ text: text, italics: true, bold: true })]
    });
  }

  shouldDisplayQuestion(quote, question) {
    if (question.prerequisiteQuestionId) {
      const prerequisiteAnswer = this.findAnswerByQuestionId(quote, question.prerequisiteQuestionId);
      return prerequisiteAnswer?.value === question.prerequisiteQuestionAnswer;
    }
    return true; // Include questions with no prerequisite
  }

  createQuestionTextRuns(quote, question) {
    let text;

    if (question.questionType === 'file_upload') {
      const answer = this.answerValue(quote, question);

      text = `Supporting Documents (${answer?.length || 0})`;
    }
    else {
      text = question.questionText;
    }

    return [
      this.newTextRun({ text: text, bold: true, break: 2 }),
      ...this.answerValue(quote, question)
    ];
  }

  findAnswerByQuestionId(quote, questionId) {
    return quote.answers.find(answer => answer.scoutRfqQuestionId === questionId);
  }

  answerValue(quote, question) {
    const answer = this.findAnswerByQuestionId(quote, question.id);

    if (answer === undefined) {
      return this.noAnswerProvided();
    }

    switch (question.questionType) {
      case 'text':
        return this.textAnswer(answer);
      case 'range':
        return this.rangeAnswer(answer);
      case 'option':
        return this.optionAnswer(answer);
      case 'file_upload':
        return this.fileUploadAnswer(answer);
    }
  }

  textAnswer(answer) {
    if (answer.value === null) {
      return this.noAnswerProvided();
    }
    else {
      return [this.newTextRun({ text: answer.value, break: 1.5 })];
    }
  }

  rangeAnswer(answer) {
    if (!answer.value?.length > 0 || answer.value === null) {
      return this.noAnswerProvided();
    }

    const rangeText = compact([
      CurrencyFilter.filter(answer.value[0]),
      CurrencyFilter.filter(answer.value[1])
    ]).join(' - ');

    return [this.newTextRun({ text: rangeText, break: 1.5 })];
  }

  optionAnswer(answer) {
    if (answer.value == null) {
      return this.noAnswerProvided();
    }

    return [this.newTextRun({ text: startCase(answer.value), break: 1.5 })];
  }

  fileUploadAnswer(answer) {
    return answer.attachments.map((attachment) => {
      return new docx.ExternalHyperlink({
        link: attachment.url,
        children: [this.newTextRun({ text: `• ${attachment.name}`, style: 'Hyperlink', break: 1.5 })]
      });
    });
  }

  noAnswerProvided() {
    return [this.newTextRun({ text: 'No answer provided', italics: true, break: 1.5 })];
  }

  orderedQuestionGroups(quote) {
    const groupsWithQuestions = this.rfq.questionGroups.filter(
      group => group.questions.some(
        question => question.isIncluded && !question.prerequisiteQuestionId
      ) || (group.handlesLawyers && quote.suggestedLawyers.length > 0)
    );

    return orderBy(groupsWithQuestions, 'position');
  }

  orderedQuestions(group) {
    const includedQuestions = group.questions.filter(question => question.isIncluded);

    return orderBy(includedQuestions, 'position');
  }

  suggestedLawyersTable(lawyers) {
    return new docx.Table({
      rows: [
        ...this.lawyerRows(lawyers)
      ],
      columns: 3,
      columnWidths: [2500, 5000, 2500],
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE
      }
    });
  }

  lawyerRows(lawyers) {
    return lawyers.map((lawyer) => {
      return new docx.TableRow({
        children: [
          new docx.TableCell({
            children: [
              new docx.Paragraph({
                children: [...this.lawyerNameAndTitle(lawyer)],
                alignment: docx.AlignmentType.CENTER
              })
            ],
            width: {
              size: 25,
              type: docx.WidthType.PERCENTAGE
            },
            margins: {
              top: docx.convertInchesToTwip(0.1),
              bottom: docx.convertInchesToTwip(0.1),
              left: docx.convertInchesToTwip(0.1),
              right: docx.convertInchesToTwip(0.1)
            },
            verticalAlign: docx.VerticalAlign.CENTER
          }),
          new docx.TableCell({
            children: [
              new docx.Paragraph({
                children: [
                  this.newTextRun({
                    text: this.presenceText(lawyer),
                    italics: true,
                    size: 18,
                    break: 0
                  })
                ],
                alignment: docx.AlignmentType.CENTER
              })
            ],
            width: {
              size: 50,
              type: docx.WidthType.PERCENTAGE
            },
            margins: {
              top: docx.convertInchesToTwip(0.1),
              bottom: docx.convertInchesToTwip(0.1),
              left: docx.convertInchesToTwip(0.1),
              right: docx.convertInchesToTwip(0.1)
            },
            verticalAlign: docx.VerticalAlign.CENTER
          }),
          new docx.TableCell({
            children: [
              new docx.Paragraph({
                children: [this.newTextRun({ text: this.formattedRate(lawyer), size: 18, break: 0 })],
                alignment: docx.AlignmentType.CENTER
              })
            ],
            width: {
              size: 25,
              type: docx.WidthType.PERCENTAGE
            },
            margins: {
              top: docx.convertInchesToTwip(0.1),
              bottom: docx.convertInchesToTwip(0.1),
              left: docx.convertInchesToTwip(0.1),
              right: docx.convertInchesToTwip(0.1)
            },
            verticalAlign: docx.VerticalAlign.CENTER
          })
        ]
      });
    });
  }

  lawyerNameAndTitle(lawyer) {
    const suggestedLawyer = new RfqSuggestedLawyer(lawyer);

    let name = [this.newTextRun({ text: suggestedLawyer.fullName(), size: 18, break: 0 })];

    if (suggestedLawyer.firmTitle()) {
      name.push(this.newTextRun({ text: suggestedLawyer.firmTitle(), break: 0.5, italics: true, size: 14 }));
    }

    return name;
  }

  presenceText(lawyer) {
    if (this.suggestedByPriori(lawyer)) {
      return 'Marketplace';
    }

    if (!this.suggestedByClient(lawyer)) {
      return 'Suggested by law firm';
    }

    if (lawyer.includedInQuote) {
      return 'Suggested by you and law firm agreed';
    }

    return 'Suggested by you; law firm marked not a good fit for this project';
  }

  suggestedByClient(lawyer) {
    return lawyer.suggestedBy === 'client';
  }

  suggestedByPriori(lawyer) {
    return lawyer.suggestedBy === 'priori';
  }

  formattedRate(lawyer) {
    return lawyer.proposedHourlyRate ? `${CurrencyFilter.filter(lawyer.proposedHourlyRate)}/hr` : '—';
  }

  fileName() {
    if (this.fileNameExtension) {
      return `RFP#${this.rfq.id} - ${this.fileNameExtension}.docx`;
    }
    else if (this.quotes.length === 1) {
      return `RFP#${this.rfq.id} - ${this.firmOrLawyerName(this.quotes[0])}.docx`;
    }

    return `RFP#${this.rfq.id} - All Responses.docx`;
  }

  firmOrLawyerName(quote) {
    if (quote.fromMarketplace && quote.marketplaceLawyer.addedAsFirm) {
      return quote.lawFirm.name;
    }
    else if (quote.fromMarketplace) {
      return quote.marketplaceLawyer.fullName;
    }
    else {
      return quote.lawFirmBridge?.name || quote.lawFirm.name;
    }
  }

  newTextRun(attrs = {}) {
    return new docx.TextRun({
      break: 1,
      font: 'Helvetica',
      ...attrs
    });
  }
}

export default RfqQuoteToDocx;
