<template>
  <confirmation-modal-secondary
    ref="modal"
    title="diversity disclosure settings"
    size="lg-md"
    yes-btn-text="request data removal"
    no-btn-text="cancel"
    :on-confirm-action="onRequestRemoval">
    <div class="yellow-message-box bottom-30">
      <svg-icon name="exclamation" class="base-icon warning-icon right-10"></svg-icon>
      <span class="message">Warning: turning off diversity disclosures could result in the removal of user data.</span>
    </div>

    <div>
      <p>
        You are attempting to turn off diversity disclosure for a location for which diversity disclosure was previously allowed. This action can result in the deletion of diversity user data for this location.
      </p>

      <p>
        If you would like to continue with this request, please click the ‘Request Data Removal’ button below, which will trigger an email to all admins at your company and the Priori team to initiate the data removal process. Please note that diversity disclosure functionality and associated data will be available until this process is completed.
      </p>
    </div>
  </confirmation-modal-secondary>
</template>

<script>
import ConfirmationModalSecondary from 'vue-app/shared/components/confirmation-modal-secondary.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'DataRemovalRequestConfirmationModal',

  components: {
    ConfirmationModalSecondary,
    SvgIcon
  },

  props: {
    onRequestRemoval: {
      type: Function,
      required: true
    }
  },

  methods: {
    openModal() {
      this.$refs.modal.openModal();
    }
  }
};
</script>
