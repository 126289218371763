<template>
  <boxed-content v-if="displayComponent" title="Articles">
    <template #links v-if="canEditProfile">
      <edit-lawyer-articles title="Editing Articles" :lawyer="lawyer" :sanitized-articles="sanitizedArticles" :on-save="save"></edit-lawyer-articles>
    </template>

    <loading-section name="lawyer-articles">
      <data-wrapper :data="sanitizedArticles">
        <div>
          <collapsable-text
            class="separated-list-items"
            v-for="(article, index) in renderingArticles"
            :key="`article-${index}`"
            :text="article">
            <template #text="slotProps">
              <div class="text-italic">
                <span v-html="slotProps.renderingText"></span>
              </div>
            </template>
          </collapsable-text>
        </div>

        <div class="top-30" v-if="sanitizedArticles.length > initialMaxArticles">
          <a href="" class="semibold-weight" @click.prevent="toggleShowAll">{{ showAllOrFewer }}</a>
        </div>
      </data-wrapper>
    </loading-section>
  </boxed-content>
</template>

<script>
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import DataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import BoxedContent from 'vue-app/shared/components/boxed-content';
import CollapsableText from 'vue-app/shared/components/collapsable-text.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import EditLawyerArticles from './edit-lawyer-articles';

export default {
  name: 'LawyerArticles',

  components: {
    BoxedContent,
    DataWrapper,
    CollapsableText,
    EditLawyerArticles
  },

  mixins: [displayRules],

  props: {
    initialMaxArticles: {
      type: Number,
      default: 3
    },

    lawyer: {
      type: Object,
      required: true
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type:     Function,
      default:  () => {}
    }
  },

  data() {
    return {
      showAll: false
    };
  },

  computed: {
    displayComponent() {
      return this.anyDataIsPresent([this.sanitizedArticles]) || this.canEditProfile;
    },

    sanitizedArticles() {
      return this.lawyer.sanitizedArticles;
    },

    limitedArticles() {
      return this.sanitizedArticles.slice(0, this.initialMaxArticles);
    },

    renderingArticles() {
      return this.showAll ? this.sanitizedArticles : this.limitedArticles;
    },

    showAllOrFewer() {
      return this.showAll ? 'Show less' : 'Show all';
    }
  },

  methods: {
    toggleShowAll() {
      this.showAll = !this.showAll;
    },

    save(articles) {
      if (!this.canEditProfile) { return; }

      const lawyerProfile = { id: this.lawyer.scoutLawyerProfileId, articles: articles };
      const lawyerEditedAttributes = { ...this.lawyer, scoutLawyerProfilesAttributes: lawyerProfile };

      LoadingService.loading('lawyer-articles');
      this.onSave(lawyerEditedAttributes).finally(
        () => {
          LoadingService.done('lawyer-articles');
        });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
