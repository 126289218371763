<template>
  <validation-provider :rules="rules" v-slot="{ errors }" :mode="passiveAggressive">
    <label v-if="label" :for="id">{{ label }} <span class="red-text" v-if="required">*</span></label>

    <dropdown-select
      :id-label="id"
      :class="[{ 'has-error': errors.length }]"
      label-key="label"
      value-key="key"
      :options="months"
      :placeholder="placeholder"
      :required="required"
      :invalid="!!errors.length"
      :initial-label="(value || value === 0) ? months[value].label : null"
      :value="value"
      @change="onChange">
    </dropdown-select>

    <div class="error-text top-5" v-if="errors.length && showErrorText">
      {{ errors[0] }}
    </div>
  </validation-provider>
</template>

<script>
import DropdownSelect         from 'vue-app/shared/components/dropdown-select.vue';
import interactionModes       from 'vue-app/shared/mixins/interaction-modes.js';
import { ValidationProvider } from 'vee-validate';
import { toNumber }           from 'lodash';

export default {
  name: 'MonthSelectVertical',

  components: {
    ValidationProvider,
    DropdownSelect
  },

  mixins: [
    interactionModes
  ],

  props: {
    id: {
      type: String,
      required: false
    },

    label: {
      type: String,
      required: false
    },

    placeholder: {
      type: String,
      default: 'Month'
    },

    value: {
      type: Number,
      default: null
    },

    rules: {
      type: String,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    showErrorText: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      months: Array
        .from({ length: 12 }, (e, i) => new Date(0, i)
        .toLocaleString('default', { month: 'long' }))
        .map((month, index) => ({ label: month, key: index }))
    };
  },

  computed: {
    required() {
      return this.rules?.includes('required');
    }
  },

  methods: {
    onChange(value) {
      this.$emit('input', toNumber(value));
    }
  }
};
</script>
