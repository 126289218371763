<template>
  <div class="shadowed-box">
    <div class="box-content variable-padded size-text-14px">
      <div v-if="submitted">
        <div class="size-text-28px tight-lines bold-weight bottom-5">
          Application Successfully Submitted!
        </div>

        <div class="dark-gray-text size-text-13px bottom-30">
          Submitted On: {{ submittedDate }}<span class="left-5 right-5">•</span>{{ submittedTime }}
        </div>

        <div class="purple-label bold-weight size-text-15px bottom-30">
          Thank you for submitting your application!
        </div>

        <div>
          We’re looking forward to reviewing it. We typically review applications within 2-3 weeks. In the meantime, if you didn’t fill out all of the details in the application, you can access your Priori profile at any time to provide additional information. It’s not required but helps us review your application more quickly.
        </div>
      </div>

      <div v-else>
        <div class="size-text-28px tight-lines bold-weight bottom-5">
          Your Priori Application
        </div>

        <div class="dark-gray-text size-text-13px bottom-20">
          Last Saved: {{ lastSavedDate }}<span class="left-5 right-5">•</span>{{ lastSavedTime }}
        </div>

        <div class="size-text-15px bold-weight bottom-20">
          Hello, {{ lawyer.fullName }}!
        </div>

        <div class="bottom-20">
          Thank you for your interest in Priori.
        </div>

        <div class="bottom-20">
          As a next step, please take a few moments to tell us more about your professional background and practice. The data you provide in this application will form the basis of your Priori profile, and may be used to identify opportunities which are relevant to your practice.
        </div>

        <div class="bottom-30">
          Once your application is complete, you can update or supplement this information on your Profile or Matching Information pages.
        </div>

        <div class="vertical-center d-flex wrap col-gap-20 row-gap-10">
          <file-selector
            accept=".pdf"
            :max-size="50"
            @change="replaceFile"
            @errors="setFileErrors">
            <span class="secondary-btn-purple fit-content clickable upload-file-btn">{{ lawyerApplication.resume.name ? 'Replace' : 'Upload' }} Resume</span>
          </file-selector>

          <span v-if="uploading" class="size-text-13px">Uploading...</span>

          <span v-else-if="lawyerApplication.resume.name" class="selected-file d-flex bold-weight">
            <div class="text-ellipsis size-text-13px">
              {{ lawyerApplication.resume.name }}
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationService from 'vue-app/shared/services/notification-service.js';
import FileSelector from 'vue-app/shared/components/file-selector.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'TopSection',

  components: {
    FileSelector
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    lawyerApplication: {
      type: Object,
      required: true
    },

    submitted: {
      type: Boolean,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      fileErrors: [],
      uploading: false
    };
  },

  computed: {
    lastSavedDate() {
      return DateFilter.filter(this.lawyerApplication.lastSavedAt);
    },

    lastSavedTime() {
      return DateFilter.shortTime(this.lawyerApplication.lastSavedAt);
    },

    submittedDate() {
      return DateFilter.filter(this.lawyerApplication.submittedAt);
    },

    submittedTime() {
      return DateFilter.shortTime(this.lawyerApplication.submittedAt);
    }
  },

  methods: {
    replaceFile(files) {
      if (this.fileErrors.length) {
        NotificationService.error('Something went wrong while saving your resume. Please try again.');
        throw this.fileErrors[0];
      }

      this.uploading = true;

      return this.onSave({ id: this.lawyerApplication.id, resume: files[0] }).then(() => {
        this.uploading = false;
      });
    },

    setFileErrors(errors) {
      this.fileErrors = errors;
    }
  }
};
</script>

<style scoped lang="scss">
  @use "stylesheets/globals/variables";

  .upload-file-btn {
    padding: 11px 24px;
    height: auto;
  }

  .selected-file {
    padding: 5px 15px;
    border-radius: 25px;
    border: 1px solid variables.$medium-gray;
    max-width: 100%;
    width: fit-content;
    height: fit-content;
  }
</style>
