<template>
  <div>
    <nav class="navbar navbar-fixed-top lawyer-application-nav">
      <div class="nav-container">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle" aria-expanded="false" @click="openSlideNav">
            <span class="sr-only">Toggle Navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>

          <span class="navbar-brand"><svg-icon name="priori-logo" class="brand-icon"></svg-icon></span>
        </div>

        <div class="collapse navbar-collapse">
          <ul class="nav navbar-nav navbar-right">
            <li><a href="/lawyer-app/dashboard" target="_self">Dashboard</a></li>
            <li><a role="button" @click="logout">Sign Out</a></li>
          </ul>
        </div>
      </div>
    </nav>

    <div id="slidenav-overlay" class="slidenav-overlay" @click="closeSlideNav"></div>

    <div id="slidenav" class="slidenav" role="navigation">
      <ul>
        <li><a href="/lawyer-app/dashboard" target="_self">Dashboard</a></li>
        <li><a role="button" @click="logout">Sign Out</a></li>
      </ul>
    </div>

    <div class="padding-because-fixed-nav">
      <notification></notification>

      <div class="container top-20">
        <div class="padded-container">
          <slot></slot>
        </div>
      </div>
    </div>

    <div class="k-footer text-center top-20">
      <svg-icon name="priori" class="base-icon"></svg-icon>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import Notification from 'vue-app/shared/components/notification.vue';
import axios from 'axios';

export default {
  name: 'LawyerApplicationLayout',

  components: {
    SvgIcon,
    Notification
  },

  watch: {
    '$route.path'(_to, _from) {
      this.closeSlideNav();
    }
  },

  methods: {
    openSlideNav() {
      document.getElementById('slidenav').style.width = '220px';
      document.getElementById('slidenav-overlay').style.width = '100%';
      document.getElementById('slidenav-overlay').style.opacity = '0.5';
    },

    closeSlideNav() {
      document.getElementById('slidenav').style.width = '0';
      document.getElementById('slidenav-overlay').style.width = '0';
      document.getElementById('slidenav-overlay').style.opacity = '0';
    },

    logout() {
      axios.delete('/lawyers/sign_out')
        .finally(() => {
          window.location.href = '/';
        });
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .navbar.lawyer-application-nav {
    margin-bottom: 0;
    border-bottom: 1px solid $k-gray;
    background-color: $white;
    box-shadow: none;

    .nav-container {
      padding-right: 30px;
      padding-left: 30px;
    }

    .navbar-header {
      .navbar-brand {
        height: 70px;
        padding: 23px 16px;

        .brand-icon {
          display: inline-block;
          width: 120px;
          height: 24px;
          vertical-align: middle;
          fill: $k-darker-gray;
        }
      }

      .navbar-toggle {
        margin: 24px 0;
        padding: 6px;

        .icon-bar {
          background-color: $body-text-color;
        }
      }
    }

    .navbar-nav {
      display: flex;
      align-items: center;
      padding-right: 15px;

      & > li > a {
        padding: 25px 15px;
        color: $body-text-color;
        font-size: 13px;
        font-weight: 600;
        text-decoration: none;

        &:hover, &:active, &:focus {
          opacity: .75;
          background-color: transparent;
        }
      }
    }
  }

  .padding-because-fixed-nav {
    padding-top: 71px;
  }

  .k-footer {
    background-color: $k-lighter-gray;
  }
</style>
