<template>
  <div class="rating-circle">
    <div class="layers range-outer" :style="rangeBarStyle" v-if="hasRating"></div>

    <div class="layers rating-base" :style="ratingBarStyle"></div>

    <div class="layers rating-mask"></div>

    <div class="layers range-inner" :style="rangeBarStyle" v-if="hasRating"></div>

    <div class="layers inset-content">
      <slot></slot>
    </div>

    <div class="dot-container" :style="dotContainerStyle" v-if="hasRating">
      <div :class="['dot', `${gradientColor}-gradient`]"></div>
    </div>

    <div class="dot-container-tooltip" :style="dotContainerStyle" v-show="hasRating">
      <div id="dot-tooltip" class="dot-tooltip-overlay"></div>
    </div>
    <popover-tooltip custom-class="tooltip-white small-text wide" target="#dot-tooltip">
      <template #tooltip>
        <slot name="popover-content"></slot>
      </template>
    </popover-tooltip>

    <div class="diamond-container" :style="compContainerStyle" v-if="hasCompScore">
      <div class="diamond"></div>
    </div>

    <div class="diamond-container-tooltip" :style="compContainerStyle" v-show="showTooltip">
      <div id="diamond-tooltip" class="diamond-tooltip-overlay"></div>
    </div>
    <popover-tooltip custom-class="tooltip-white small-text wide" target="#diamond-tooltip">
      <template #tooltip>
        <slot name="popover-content"></slot>
      </template>
    </popover-tooltip>
  </div>
</template>

<script>
import { map } from 'lodash';
import PopoverTooltip from 'vue-app/shared/components/popover-tooltip.vue';

export default {
  name: 'RatingCircleWithRange',

  components: {
    PopoverTooltip
  },

  props: {
    rating: {
      type: Number,
      required: true
    },

    maxRating: {
      type: Number,
      required: true
    },

    rangeMin: {
      type: Number,
      required: true
    },

    rangeMax: {
      type: Number,
      required: true
    },

    compScore: {
      type: Number,
      required: true
    },

    gradientColor: {
      type: String,
      default: 'purple'
    }
  },

  data() {
    return {
      gapFactor: 0.75, // since we're drawing a 3/4 circle
      supportedGradients: {
        'purple': { start: '#0069c7', end: '#9213b2' }, // $k-blue to $k-purple
        'blue':   { start: '#0069c7', end: '#0a1370' }  // $k-blue to $night-blue
      },
      ratingBarBgColor: '#eee', // $k-light-gray
      rangeBarColor: '#dfdfdf' // $grey-3
    };
  },

  computed: {
    hasRating() {
      return this.rating > 0;
    },

    hasCompScore() {
      return this.compScore > 0;
    },

    gradient() {
      return this.supportedGradients[this.gradientColor] ||
        this.supportedGradients['purple'];
    },

    ratingEndPercent() {
      return (this.rating / this.maxRating) * 100 * this.gapFactor;
    },

    ratingBarStyle() {
      return {
        background: this.buildConicGradient([
          { color: this.gradient.start,   percent: 0 },
          { color: this.gradient.end,     percent: this.ratingEndPercent },
          { color: this.ratingBarBgColor, percent: 0 },
          { color: this.ratingBarBgColor, percent: 75 },
          { color: '#fff',                percent: 75 },
          { color: '#fff',                percent: 100 }
        ])
      };
    },

    dotRotationDegrees() {
      return 225 + (this.ratingEndPercent * 360 / 100);
    },

    dotContainerStyle() {
      return { transform: `rotate(${this.dotRotationDegrees}deg)` };
    },

    rangeStartPercent() {
      return (this.rangeMin / this.maxRating) * 100 * this.gapFactor;
    },

    rangeEndPercent() {
      return (this.rangeMax / this.maxRating) * 100 * this.gapFactor;
    },

    rangeBarStyle() {
      return {
        background: this.buildConicGradient([
          { color: '#fff',             percent: 0 },
          { color: '#fff',             percent: this.rangeStartPercent },
          { color: this.rangeBarColor, percent: this.rangeStartPercent },
          { color: this.rangeBarColor, percent: this.rangeEndPercent },
          { color: '#fff',             percent: this.rangeEndPercent },
          { color: '#fff',             percent: 100 }
        ])
      };
    },

    compEndPercent() {
      return (this.compScore / this.maxRating) * 100 * this.gapFactor;
    },

    compRotationDegrees() {
      return 225 + (this.compEndPercent * 360 / 100);
    },

    compContainerStyle() {
      return { transform: `rotate(${this.compRotationDegrees}deg)` };
    },

    showTooltip() {
      return this.hasCompScore && this.$slots['popover-content'];
    }
  },

  methods: {
    buildConicGradient(stopList) {
      let colorStops = map(stopList, (stop) => `${stop.color} ${stop.percent}%`).join(', ');
      return `conic-gradient(${colorStops})`;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .rating-circle {
    position: relative;
    width: 170px;
    height: 170px;

    .layers {
      border-radius: 50%;

      &:not(.range-outer) {
        position: absolute;
        left: 50%;
        top: 50%;
      }

      &.range-outer {
        width: 170px;
        height: 170px;
        transform: rotate(225deg);
        background: $grey-3;
      }

      &.rating-base {
        width: 150px;
        height: 150px;
        transform: translate(-50%, -50%) rotate(225deg);
        background: $k-blue;
        clip-path: path("M12.78,81.25c0-3.45178-2.79822-6.25-6.25-6.25s-6.25,2.79822-6.25,6.25h-.28L0,150h150v-150h-68.75v.28C77.79822,0.28,75,3.07822,75,6.53s2.79822,6.25,6.25,6.25v4.95775L17.73775,81.25h-4.95775ZM81.25,17.73775M17.73775,81.25");
        z-index: 0;
      }

      &.rating-mask {
        width: 126px;
        height: 126px;
        transform: translate(-50%, -50%);
        background: $white;
        z-index: 1;
      }

      &.range-inner {
        width: 126px;
        height: 126px;
        transform: translate(-50%, -50%) rotate(225deg);
        background: $grey-3;
        z-index: 2;
      }

      &.inset-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 104px;
        height: 104px;
        transform: translate(-50%, -50%);
        background: $white;
        z-index: 3;
      }
    }

    .dot-container, .diamond-container {
      width: 150px;
      height: 150px;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 3;
    }

    .diamond-container-tooltip, .dot-container-tooltip {
      width: 150px;
      height: 150px;
      position: absolute;
      top: 10px;
      left: 10px;
      pointer-events: none;
      z-index: 5;
    }

    .dot {
      width: 22px;
      height: 22px;
      position: absolute;
      top: 6px;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $k-blue;
      border: 4px solid $white;
      border-radius: 50%;

      &.purple-gradient {
        background-color: $k-blue;
      }

      &.blue-gradient {
        background-color: $k-purple;
      }
    }

    .diamond {
      width: 10px;
      height: 10px;
      position: absolute;
      top: -7px;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      background-color: $pill-gray;
      border-radius: 1px;
      z-index: 0;
    }

    .diamond-tooltip-overlay {
      width: 10px;
      height: 10px;
      position: absolute;
      top: -7px;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      background-color: transparent;
      border-radius: 1px;
      z-index: 7;
      pointer-events: auto;
    }

    .dot-tooltip-overlay {
      width: 14px;
      height: 14px;
      position: absolute;
      top: 6px;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: transparent;
      border-radius: 50%;
      z-index: 7;
      pointer-events: auto;
    }
  }
</style>
