<template>
  <div>
    <div class="row md-columns">
      <div class="col-sm-6 bottom-30">
        <text-input-vertical
          id="lawyer-first-name"
          label="First Name"
          rules="required"
          v-model="lawyer.firstName">
        </text-input-vertical>
      </div>

      <div class="col-sm-6 bottom-30">
        <text-input-vertical
          id="lawyer-last-name"
          label="Last Name"
          rules="required"
          v-model="lawyer.lastName">
        </text-input-vertical>
      </div>
    </div>

    <div class="row md-columns">
      <div class="col-sm-6">
        <text-input-vertical
          id="lawyer-email"
          type="email"
          label="Email"
          rules="required|email"
          :disabled="invited && hasEmail"
          v-model="lawyer.email">
        </text-input-vertical>
      </div>

      <div class="col-sm-6 top-30-xs">
        <text-input-vertical
          id="lawyer-office-number"
          input-type="tel"
          label="Phone Number"
          rules="required"
          v-model="lawyerApplication.officeNumber">
        </text-input-vertical>
      </div>
    </div>
  </div>
</template>

<script>
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';

export default {
  name: 'ContactInfoSection',

  components: {
    TextInputVertical
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    lawyerApplication: {
      type: Object,
      required: true
    },

    hasEmail: {
      type: Boolean,
      required: true
    },

    invited: {
      type: Boolean,
      required: true
    }
  }
};
</script>
