<template>
  <div>
    <div class="inline-block right-20-not-xs" @click.prevent="editProfilePic">
      <profile-photo :user="lawyer" :larger="true" :editable="canEditProfile"></profile-photo>

      <profile-photo-edit-modal
        ref="modalComponent"
        title="Editing Photo"
        :profile-id="lawyer.scoutLawyerProfileId"
        :initial-image-url="lawyer.photoLarge"
        @profile-photo-updated="$emit('profile-photo-updated', $event)">
      </profile-photo-edit-modal>
    </div>

    <div class="text-img-adjacent">
      <div class="medium-page-heading text-ellipsis">
        {{ lawyer.fullName }}
      </div>

      <div class="size-text-16px text-ellipsis">
        {{ titleAndFirm }}
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/scout/shared/profile-photo.vue';
import ProfilePhotoEditModal from 'vue-app/scout/firm/lawyers/edit-profile-photo.vue';
import { compact } from 'lodash';

export default {
  name: 'LawyerProfileHeader',

  components: {
    ProfilePhoto,
    ProfilePhotoEditModal
  },

  props: {
    lawyer: {
      type:     Object,
      required: true
    },

    canEditProfile: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    titleAndFirm() {
      let hasFirmName = this.lawyer.firmName && this.lawyer.firmName !== this.lawyer.fullName;
      let display = this.lawyer.firmTitle || '';

      if (hasFirmName) {
        display = compact([display, this.lawyer.firmName]).join(' at ');
      }

      return display;
    }
  },

  methods: {
    editProfilePic() {
      if (this.canEditProfile) {
        this.$refs.modalComponent.openModal();
      }
    }
  }
};
</script>
