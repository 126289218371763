<template>
  <div class="clear-panel">
    <div class="row tight-columns">
      <div class="col-sm-8 semibold-weight text-ellipsis">
        {{ lawyerProficiency.name }}
      </div>

      <div class="col-sm-4 bold-weight text-uppercase text-right-not-xs">
        <a href="" :class="isExpanded ? 'dark-gray-text' : 'darker-gray-text'" @click.prevent="toggleExpanded()">{{ isExpanded ? 'Hide' : 'View' }} Details</a>
      </div>
    </div>

    <div v-if="isExpanded">
      <div class="top-20 bottom-20">
        <hr class="kenny-hr no-margin">
      </div>

      <div class="row tight-columns">
        <div class="col-sm-6 bottom-15 text-ellipsis" v-for="(proficiency, key) in proficiencies" :key="key">
          <svg-icon name="checkmark" class="base-icon text-top green-text"></svg-icon>
          <span class="semibold-weight left-5">{{ proficiency }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ScoutAccountProficiency',

  components: {
    SvgIcon
  },

  props: {
    lawyerProficiency: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isExpanded: false
    };
  },

  computed: {
    proficiencies() {
      return this.lawyerProficiency.proficiencies;
    }
  },

  methods: {
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    }
  }
};
</script>
