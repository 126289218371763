<template>
  <priori-modal ref="addMarketplace" modal-id="add-marketplace-modal" title="Add Marketplace">
    <template #modal-trigger="{ openModal }">
      <button type="button" class="pseudo-link-blue purple-link bold-weight size-text-13px" @click="openModal">+ Send RFP to Marketplace</button>
    </template>

    <div class="bottom-30">
      <underlined-header>
        <div class="row tight-columns vertical-center-not-xs">
          <div class="col-sm-8">
            Include Priori’s Marketplace Network
          </div>

          <div class="col-sm-4 vertical-center flex-end-not-xs top-15-xs">
            <svg-icon name="marketplace-logo" class="marketplace-icon"></svg-icon>
          </div>
        </div>
      </underlined-header>

      <div class="size-text-14px bottom-15">
        Please confirm you would like to send this RFP to Priori’s Marketplace.
      </div>

      <div class="size-text-14px">
        We’ll identify the best-fit options in our 8000+ legal network and send you a short-list to compare with your firms.
      </div>
    </div>

    <div class="row tight-columns">
      <div class="col-sm-6">
        <loading-button name="rfqSendToMarketplace" lb-class="primary-btn-blue" @lb-click="proceedToAdd">Yes, Add Marketplace</loading-button>
      </div>

      <div class="col-sm-4 top-20-xs">
        <button type="button" class="tertiary-button-compact" @click="closeModal">Cancel</button>
      </div>
    </div>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import UnderlinedHeader from 'vue-app/shared/components/underlined-header.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';

export default {
  name: 'RfqAddToMarketplace',

  components: {
    PrioriModal,
    SvgIcon,
    LoadingButton,
    UnderlinedHeader
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    sendToMarketplace: {
      type: Function,
      required: true
    }
  },

  methods: {
    proceedToAdd() {
      const modal = this.$refs.addMarketplace;

      LoadingService.loading('rfqSendToMarketplace');
      this.sendToMarketplace(this.rfq)
        .finally(() => {
          modal.closeModal();
          LoadingService.done('rfqSendToMarketplace');
        });
    },

    closeModal() {
      this.$refs.addMarketplace.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
  .marketplace-icon {
    width: 122px;
  }
</style>
