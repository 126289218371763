<template>
  <div :id="`question-wrapper-${question.id}`">
    <div v-if="isTextType">
      <quote-form-question-text
        :question="question"
        :value="answer.value"
        :on-change="handleChange"
        :show-question-update-tag="showQuestionUpdateTag">
      </quote-form-question-text>
    </div>

    <div v-else-if="isRangeType">
      <quote-form-question-range
        :question="question"
        :min-value="answer.value[0]"
        :max-value="answer.value[1]"
        :is-range="!!answer.value[1]"
        :on-change="handleChange"
        :show-question-update-tag="showQuestionUpdateTag"
        :is-admin-form="isAdminForm">
      </quote-form-question-range>
    </div>

    <div v-else-if="isOptionType">
      <quote-form-question-option
        :question="question"
        :value="answer.value"
        :on-change="handleChange"
        :show-question-update-tag="showQuestionUpdateTag"
        :is-admin-form="isAdminForm">
      </quote-form-question-option>
    </div>

    <div v-else-if="isFileType">
      <quote-form-question-file
        :question="question"
        :value="answer.attachments"
        :on-change="handleChange"
        :show-question-update-tag="showQuestionUpdateTag"
        :is-admin-form="isAdminForm">
      </quote-form-question-file>
    </div>

    <div class="top-20" v-if="hasDisplayableDependents">
      <div class="list-spacing-20" v-for="dependent in orderedDependents" :key="dependent.id">
        <quote-form-question
          :question-group="questionGroup"
          :question="dependent"
          :answers="answers"
          :on-change="onChange"
          :quote-last-submitted-at="quoteLastSubmittedAt"
          :is-admin-form="isAdminForm">
        </quote-form-question>
      </div>
    </div>
  </div>
</template>

<script>
import QuoteFormQuestionText from './quote-form-question-text.vue';
import QuoteFormQuestionRange from './quote-form-question-range.vue';
import QuoteFormQuestionOption from './quote-form-question-option.vue';
import QuoteFormQuestionFile from './quote-form-question-file.vue';
import dateComparisons from 'vue-app/shared/mixins/date-comparisons.js';
import { each, find, orderBy } from 'lodash';

export default {
  name: 'QuoteFormQuestion',

  components: {
    QuoteFormQuestionText,
    QuoteFormQuestionRange,
    QuoteFormQuestionOption,
    QuoteFormQuestionFile
  },

  mixins: [dateComparisons],

  props: {
    questionGroup: {
      type: Object,
      required: true
    },

    question: {
      type: Object,
      required: true
    },

    answers: {
      type: Array,
      required: true
    },

    onChange: {
      type: Function,
      required: true
    },

    quoteLastSubmittedAt: {
      type: String,
      required: false
    },

    quoteReceivedAt: {
      type: String,
      required: true
    },

    isAdminForm: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isTextType() {
      return this.question.questionType === 'text';
    },

    isRangeType() {
      return this.question.questionType === 'range';
    },

    isOptionType() {
      return this.question.questionType === 'option';
    },

    isFileType() {
      return this.question.questionType === 'file_upload';
    },

    answer() {
      return find(this.answers, { scoutRfqQuestionId: this.question.id });
    },

    dependents() {
      return this.questionGroup.questions.filter(q => q.prerequisiteQuestionId === this.question.id);
    },

    displayableDependents() {
      return this.dependents.filter(q => this.answer.value === q.prerequisiteQuestionAnswer);
    },

    hiddenDependents() {
      return this.dependents.filter(q => this.answer.value !== q.prerequisiteQuestionAnswer);
    },

    orderedDependents() {
      return orderBy(this.displayableDependents, 'position');
    },

    hasDisplayableDependents() {
      return this.displayableDependents.length > 0;
    },

    showQuestionUpdateTag() {
      if (this.updatedBeforeQuoteReceived) { return false; }
      if (!this.quoteLastSubmittedAt && this.question.latestActivity) { return true; }

      return this.updatedAfterLastSubmission;
    },

    updatedBeforeQuoteReceived() {
      return this.dateIsBefore(this.question.latestActivity?.createdAt, this.quoteReceivedAt);
    },

    updatedAfterLastSubmission() {
      return this.dateIsAfter(this.question.latestActivity?.createdAt, this.quoteLastSubmittedAt);
    }
  },

  methods: {
    handleChange(value) {
      return this.onChange(this.question.id, value).then((response) => {
        this.clearDependents();
        return response;
      });
    },

    clearDependents() {
      each(this.dependents, (dependent) => {
        this.onChange(dependent.id, (this.isRangeType ? [] : null));
      });
    }
  }
};
</script>
