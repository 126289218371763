<template>
  <div class="rating-bar-container">
    <div class="rating-bar">
      <div v-if="showRates">
        <div v-for="suggestedLawyer in includedSuggestedLawyers">
          <div v-if="hasRate(suggestedLawyer)" :class="['dot', {'from-marketplace': quote.fromMarketplace}]" :style="getLeftEdgePercentage(suggestedLawyer)" :id="`dot-${suggestedLawyer.id}`"></div>

          <popover-tooltip
            :target="`#dot-${suggestedLawyer.id}`"
            custom-class="tooltip-white">
            <template #tooltip>
              <div class="semibold-weight">{{ quoteLabel }}</div>
              <div class="text-muted">{{ formattedTitleWithRate(suggestedLawyer) }}</div>
            </template>
          </popover-tooltip>
        </div>
      </div>
      <div v-else class="no-data-text">{{ noDataText }}</div>
    </div>
  </div>
</template>

<script>
import PopoverTooltip from 'vue-app/shared/components/popover-tooltip.vue';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter.js';

export default {
  name: 'HourlyRateChartBar',

  components: {
    PopoverTooltip
  },

  props: {
    quote: {
      type: Object,
      required: true
    },

    rangeData: {
      type: Object,
      required: true
    },

    feeStructureQuestionIncluded: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    quoteLabel() {
      return this.quote.fromMarketplace ? this.quote.marketplaceLawyer.fullName : this.quote.lawFirm.name;
    },

    includedSuggestedLawyers() {
      return this.quote.suggestedLawyers.filter(suggestedLawyer => suggestedLawyer.includedInQuote);
    },

    hasSuggestedLawyers() {
      return this.includedSuggestedLawyers?.length > 0;
    },

    hasAlternativeFee() {
      return this.quote.feeStructure?.value == 'alternative_fee';
    },

    hasHourlyFee() {
      return this.quote.feeStructure?.value == 'hourly';
    },

    quoteSubmitted() {
      return ['quote_submitted', 'selected_by_client', 'declined_by_client'].includes(this.quote.status);
    },

    showRates() {
      return this.feeStructureQuestionIncluded && !this.hasAlternativeFee && this.hasSuggestedLawyers && this.quoteSubmitted;
    },

    noDataText() {
      if (this.quote.status === 'draft' || this.quote.status === 'new_rfq') {
        return 'Pending Response';
      }
      else if (!this.feeStructureQuestionIncluded) {
        return 'Pricing data not requested';
      }
      else if (this.hasHourlyFee && !this.hasSuggestedLawyers) {
        return 'Timekeeper data not provided';
      }
      else if (this.hasAlternativeFee) {
        return 'Alternative Fee';
      }
      else if (this.quote.status === 'declined_by_firm') {
        return 'Declined';
      }
      else {
        return '';
      }
    }
  },

  methods: {
    hasRate(suggestedLawyer) {
      return suggestedLawyer.proposedHourlyRate && suggestedLawyer.proposedHourlyRate > 0;
    },

    formattedTitleWithRate(suggestedLawyer) {
      const lawyer = suggestedLawyer.scoutLawyerBridge || suggestedLawyer.lawyer;

      return [
        (lawyer?.firmTitle || ''), this.formattedRate(suggestedLawyer.proposedHourlyRate)
      ].filter(Boolean).join(', ');
    },

    formattedRate(rate) {
      return `${CurrencyFilter.filter(rate)}/hr`;
    },

    getLeftEdgePercentage(suggestedLawyer) {
      const max = this.rangeData.max;
      const min = this.rangeData.min;
      const range = max - min;
      const leftEdge = (suggestedLawyer.proposedHourlyRate - min) / range * 100;

      return `left: ${leftEdge}%`;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .rating-bar-container {
    position: relative;

    .rating-bar{
      position: relative;
      z-index: 1;

      .dot {
        position: absolute;
        top: 50%;
        right: 0;
        width: 8px;
        height: 8px;
        margin-left: 0.5px;
        transform: translate(-50%, -50%);
        background: $high-match-score-blue;
        border-radius: 50%;

        &.from-marketplace {
          background: $k-purple;
        }
      }

      .no-data-text {
        position: absolute;
        top: -6px;
        width: 100%;
        text-align: center;
        font-size: 11px;
        font-style: italic;
        color: $pill-gray;
      }
    }
  }
</style>
