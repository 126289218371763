<template>
  <div class="rating-bar" @mouseover="showTooltip" @mouseleave="hideTooltip">
    <div :class="ratingFillClass" ref="rating" :style="cssStyle">
      <div :class="['dot', dotColorClass]" :id="`legend-item-${legendId}`"></div>
    </div>
    <tooltip
      :target="`#legend-item-${legendId}`"
      placement="bottom"
      :text="legendName"
      v-if="legendName"
      trigger="manual"
      v-model="tooltipVisible">
    </tooltip>
  </div>
</template>

<script>
import { compact, uniqueId } from 'lodash';

export default {
  name: 'RatingBar',

  props: {
    rating: {
      type: Number,
      required: true
    },

    maxRating: {
      type: Number,
      required: true
    },

    fillColorClass: {
      type: String,
      default: null
    },

    dotColorClass: {
      type: String,
      default: null
    },

    legendName: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      tooltipVisible: false
    };
  },

  computed: {
    ratingFillClass() {
      return compact(['rating-fill', this.fillColorClass]).join(' ');
    },

    widthPercent() {
      const maxRating = this.maxRating > 0 ? this.maxRating : this.rating;
      return this.rating / maxRating * 100;
    },

    cssStyle() {
      return { 'width': `${this.widthPercent}%` };
    },

    legendId() {
      return uniqueId();
    }
  },

  methods: {
    showTooltip() {
      this.tooltipVisible = true;
    },

    hideTooltip() {
      this.tooltipVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .rating-fill {
    position: relative;
  }

  .dot {
    background: $k-darker-gray;
    border: 2px solid $white;
    border-radius: 50%;
    height: 12px;
    position: absolute;
    right: -6px;
    top: 50%;
    transform: translate(0, -50%);
    width: 12px;

    &.purple-dot {
      background: $k-purple;
    }

    &.blue-dot {
      background: $k-blue;
    }
  }
</style>
