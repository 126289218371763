<template>
  <boxed-content title="Statistics" id="lawyer-statistics" v-if="displayComponent">
    <template #links v-if="canEditProfile && hasStatistics">
      <edit-modal title="Editing Statistics" :input="statistics" :on-save="save">
        <template #default="{ inputCopy: editedStatistics }">
          <div class="box-content">
            <div class="small-bold-header">
              Statistics
            </div>

            <hr>

            <p class="pill-gray-text size-text-12px text-italic">
              If you choose to hide these statistics, only you and admins at your law firm will be able to see this information on your profile. You may un-hide statistics at any time.
            </p>

            <p class="pill-gray-text size-text-12px text-italic bottom-20">
              If you flag statistics for review, you will be prompted to provide a brief reason for the review.
            </p>

            <div class="bottom-10">
              <input-checkbox id="hidden" v-model="showStats" size="medium-large" v-if="statsHidden">
                Display these statistics
              </input-checkbox>
              <input-checkbox id="hidden" v-model="editedStatistics.hidden" size="medium-large" v-else>
                Hide these statistics
              </input-checkbox>
            </div>

            <div>
              <input-checkbox id="flagged" size="medium-large" v-model="flagForReview">
                Flag these statistics for review
              </input-checkbox>
            </div>

            <div class="top-10" v-if="flagForReview">
              <textarea-input-vertical id="review-reason" label="Add a note (optional)" v-model="reviewRequestReason" :char-limit="400"></textarea-input-vertical>
            </div>
          </div>
        </template>
      </edit-modal>
    </template>

    <pop-up-modal ref="noticeModal">
      <template #message>
        <div class="size-text-16px">
          Thank you for requesting a review of your statistics. A Priori team member will reach out to you shortly with more information.
        </div>
      </template>
    </pop-up-modal>

    <loading-section name="lawyer-statistics">
      <div class="pill-gray-text size-text-12px text-italic">
        These statistics reflect publicly available data only. Certain information may be missing, especially state-level courts and judges data.
      </div>

      <color-tag v-if="statistics.hidden" color-pattern="purple" class="top-15">
        Hidden
      </color-tag>

      <div class="top-20" v-if="judges.length">
        <hr>

        <lawyer-statistic :statistic-data="judges" title="Judge Appearances" attribute-key="displayName"></lawyer-statistic>
      </div>

      <div class="top-20" v-if="courthouses.length">
        <hr>

        <lawyer-statistic :statistic-data="courthouses" title="Cases By Court" attribute-key="name"></lawyer-statistic>
      </div>

      <div class="top-20" v-if="mattersByYear.length">
        <hr>

        <lawyer-statistic :statistic-data="mattersByYear" title="Cases By Year" attribute-key="year"></lawyer-statistic>
      </div>

      <div class="gray-text size-text-16px top-20" v-if="!judges.length && !courthouses.length && !mattersByYear.length">
        None found
      </div>
    </loading-section>
  </boxed-content>
</template>

<script>
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import BoxedContent from 'vue-app/shared/components/boxed-content';
import LawyerStatistic from './lawyer-statistic';
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import InputCheckbox from 'vue-app/shared/components/input-checkbox.vue';
import TextareaInputVertical from 'vue-app/shared/components/textarea-input-vertical.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import ColorTag from 'vue-app/shared/components/color-tag';

export default {
  name: 'LawyerStatistics',

  components: {
    BoxedContent,
    ColorTag,
    LawyerStatistic,
    EditModal,
    InputCheckbox,
    TextareaInputVertical
  },

  mixins: [displayRules],

  props: {
    lawyer: {
      type:     Object,
      required: true
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      statsHidden: undefined,
      showStats: false,
      flagForReview: false,
      reviewRequestReason: ''
    };
  },

  computed: {
    statistics() {
      return this.lawyer.scoutUnicourtStats || {};
    },

    judges() {
      return _.orderBy(this.statistics.judges, 'count', 'desc');
    },

    courthouses() {
      return _.orderBy(this.statistics.courthouses, 'count', 'desc');
    },

    mattersByYear() {
      return _.orderBy(this.statistics.mattersByYear, 'year', 'desc');
    },

    hasStatistics() {
      return this.anyDataIsPresent([this.statistics]);
    },

    displayComponent() {
      return this.hasStatistics;

      // TODO:
      // Use the logic below when Unicourt is implemented
      // return this.canEditProfile || (!this.statistics.hidden && this.hasStatistics);
    }
  },

  mounted() {
    this.statsHidden = this.statistics.hidden;
  },

  methods: {
    openNoticeModal() {
      this.$refs.noticeModal.openModal();
    },

    save(editedStatistics) {
      if (this.canEditProfile) {
        LoadingService.loading('lawyer-statistics');

        if (this.showStats) { editedStatistics.hidden = false; }

        if (this.flagForReview) {
          editedStatistics.scoutReviewRequestsAttributes = [{ reason: this.reviewRequestReason }];
        }

        this.onSave({ scoutUnicourtStatsAttributes: editedStatistics }).then(
          () => {
            if (this.flagForReview) { this.openNoticeModal(); }

            this.statsHidden = editedStatistics.hidden;
            this.showStats = false;
            this.flagForReview = false;
            this.reviewRequestReason = '';
          }
        ).finally(() => {
          LoadingService.done('lawyer-statistics');
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  hr {
    margin: 10px 0;
  }
</style>
