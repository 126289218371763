<template>
  <div>
    <div class="row md-columns vertical-center-not-xs">
      <div class="col-sm-4 col-md-3">
        <button type="button" id="resume-autofill" class="primary-btn-purple a-button-size vertical-center flex-center" :disabled="!hasResume || !resumeUpdated" @click="openConfirmation"><svg-icon name="priori-ai" class="base-icon ai-icon medium no-shrink right-10"></svg-icon>AI&nbsp;Autofill</button>

        <confirmation-modal-secondary
          ref="confirmationModal"
          title="AI Autofill"
          yes-btn-text="Confirm"
          no-btn-text="Cancel"
          :on-confirm-action="parseResume">
          <slot name="confirmation">
            Please confirm that you would like to use AI to fill out the account creation form using the content from your resume. Please note that this will overwrite anything you have previously input into the account creation form. Your use of Priori AI is subject to our <a href="https://resources.priorilegal.com/supplemental-ai-terms" target="_blank">Supplemental Terms for AI Features</a>.
          </slot>
        </confirmation-modal-secondary>
      </div>

      <div class="col-sm-9 size-text-14px top-20-xs">
        <slot name="instructions">
          If you would like to use AI to automatically fill out the form below, please upload your resume above and click the AI Autofill button.
        </slot>
      </div>
    </div>

    <modal v-model="modalOpen" size="md-sm" :header="false" :footer="false" :backdrop="false" :keyboard="false" append-to-body>
      <modal-close-button :on-dismiss="cancel"></modal-close-button>

      <div class="notice-modal">
        <div class="notice-header">
          AI Autofill
        </div>

        <div class="notice-regular-text">
          <div v-if="loading">
            <div class="size-text-14px bottom-30">
              Please wait while our AI uses the information from your resume to fill out your account creation form.
            </div>

            <div class="status-icon bottom-30">
              <span class="spinner"></span>
            </div>

            <div class="row">
              <div class="col-sm-4">
                <button type="button" class="nv-button-white a-button-size" @click="cancel">Cancel</button>
              </div>
            </div>
          </div>

          <div v-else-if="success">
            <div class="size-text-14px bottom-30">
              The AI has completed processing your resume. Please review your application for accuracy and edit or adjust your answers as appropriate before submitting.
            </div>

            <div class="status-icon success bottom-30">
              <svg-icon name="check" class="base-icon"></svg-icon>
            </div>

            <div class="row">
              <div class="col-sm-4">
                <button type="button" class="primary-btn-purple a-button-size" @click="closeModal">OK</button>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="size-text-14px bottom-30">
              Unfortunately our AI has failed to process your resume. Please click the ‘Close’ button to return to the account creation form.
            </div>

            <div class="status-icon failure bottom-30">
              <svg-icon name="x4" class="base-icon"></svg-icon>
            </div>

            <div class="row">
              <div class="col-sm-4">
                <button type="button" class="primary-btn-purple a-button-size" @click="closeModal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ConfirmationModalSecondary from 'vue-app/shared/components/confirmation-modal-secondary.vue';
import LawyerResume from 'resources/lawyer-resume.js';
import { isNil } from 'lodash';

export default {
  name: 'ResumeParser',

  components: {
    ModalCloseButton,
    SvgIcon,
    ConfirmationModalSecondary
  },

  props: {
    lawyerApplication: {
      type: Object,
      required: true
    },

    resumeUpdated: {
      type: Boolean,
      default: true
    },

    onSuccess: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      modalOpen: false,
      idToken: null,
      loading: false,
      success: false,
      maxWait: 30, // seconds
      finalCheckTimeout: null
    };
  },

  computed: {
    resume() {
      return this.lawyerApplication.resume;
    },

    hasResume() {
      return !isNil(this.resume) && this.resume.name;
    }
  },

  watch: {
    loading(val) {
      if (val) { this.finalCheckTimeout = setTimeout(this.getResumeData, this.maxWait * 1000); }
    }
  },

  mounted() {
    this.$cable.subscribe({ channel: 'LawyerResumeChannel' });
  },

  destroyed() {
    this.$cable.unsubscribe({ channel: 'LawyerResumeChannel' });
  },

  channels: {
    LawyerResumeChannel: {
      received(payload) {
        if (!this.loading) { return; }

        if (this.idToken && payload['id_token'] === this.idToken) {
          this.getResumeData();
        }
        else {
          this.setFailed();
        }
      },

      rejected() {
        this.setFailed();
      },

      disconnected() {
        this.setFailed();
      }
    }
  },

  methods: {
    openModal() {
      this.modalOpen = true;
    },

    closeModal() {
      this.modalOpen = false;
    },

    openConfirmation() {
      this.$refs.confirmationModal.openModal();
    },

    parseResume() {
      if (!this.hasResume) { return; }

      this.loading = true;
      this.success = false;
      this.idToken = null;
      this.openModal();

      LawyerResume.postForm({ lawyerResume: { resume: this.resume } })
        .then((response) => {
          this.idToken = response.idToken;
          this.lawyerApplication.resumeIdToken = response.idToken;
        })
        .catch(() => {
          this.setFailed();
        });
    },

    stopTimer() {
      if (this.finalCheckTimeout) { clearTimeout(this.finalCheckTimeout); }
    },

    setFailed() {
      this.stopTimer();
      this.success = false;
      this.loading = false;
    },

    cancel() {
      this.stopTimer();
      this.closeModal();

      this.idToken = null;
      this.success = false;
      this.loading = false;
    },

    getResumeData() {
      if (!this.loading || !this.idToken) { return; }

      this.stopTimer();

      LawyerResume.get({ id_token: this.idToken })
        .then((response) => {
          this.success = true;
          this.onSuccess(response);
        })
        .catch(() => {
          this.success = false;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .status-icon {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    text-align: center;

    .spinner {
      display: inline-block;
      width: 80px;
      height: 80px;
      animation: spinner 1.3s linear infinite;
      border: 8px solid $purple-6;
      border-radius: 50%;
      border-top-color: $white;
    }

    &.success {
      border: 8px solid $green-5;

      .base-icon {
        width: 30px;
        height: 30px;
        margin-top: 17px;
        fill: $green-5;
      }
    }

    &.failure {
      border: 8px solid $red-4;

      .base-icon {
        width: 26px;
        height: 26px;
        margin-top: 19px;
        fill: $red-4;
      }
    }
  }
</style>
