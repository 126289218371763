<template>
  <div :class="['shadowed-box interface-note-box', variant]">
    <svg-icon :name="icon" class="base-icon note-icon"></svg-icon>

    <slot>
      {{ noteText }}
    </slot>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'StatusBanner',

  components: {
    SvgIcon
  },

  props: {
    noteText: {
      type: String,
      required: false
    },

    icon: {
      type: String,
      default: 'note'
    },

    variant: {
      type: String,
      default: 'note'
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .note-icon {
    position: absolute;
    top: 17px;
    left: 13px;
  }

  .interface-note-box {
    padding: 15px 15px 15px 40px;
    border-left: 7px solid $k-blue;

    &.selected {
      .note-icon {
        fill: $scout-green;
      }
    }

    &.declined {
      .note-icon {
        fill: $pill-gray;
      }
    }
  }
</style>
