<template>
  <div class="work-history-fields">
    <div class="d-flex-not-xs flex-space-between bottom-20">
      <div class="bold-weight size-text-15px">
        Position #{{ positionNumber }} Details
      </div>

      <div v-if="canRemove" class="minus-icon clickable top-20-xs" @click="onRemovePosition(positionNumber - 1)">
        <svg-icon name="minus-circle" class="base-icon right-10"></svg-icon><span class="size-text-13px semibold-weight">Remove</span>
      </div>
    </div>

    <div class="row md-columns">
      <div class="col-sm-6 bottom-20">
        <text-input-vertical
          label="Firm/Company/Solo Practice Name"
          :id="`firm-name-${positionNumber}`"
          :name="`firmName${positionNumber}`"
          v-model="positionData.firmName"
          rules="required">
        </text-input-vertical>
      </div>

      <div class="col-sm-6 bottom-20">
        <text-input-vertical
          label="Title"
          :id="`firm-title-${positionNumber}`"
          :name="`firmTitle${positionNumber}`"
          v-model="positionData.title"
          rules="required">
        </text-input-vertical>
      </div>
    </div>

    <div class="row md-columns">
      <label class="col-xs-12">
        Start Date <span class="red-text">*</span>
      </label>

      <div class="col-sm-6">
        <month-select-vertical
          :name="`firmStart${positionNumber}Month`"
          :id="`firm-start-${positionNumber}-month`"
          :rules="monthFromRules"
          :value="monthFrom"
          @input="month => setMonth(positionData, 'fromDate', month)">
        </month-select-vertical>
      </div>

      <div class="col-sm-6 top-20-xs">
        <year-select-vertical
          :name="`firmStart${positionNumber}Year`"
          :id="`firm-start-${positionNumber}-year`"
          placeholder="Year"
          :rules="yearFromRules"
          :value="yearFrom"
          @input="year => setYear(positionData, 'fromDate', year)">
        </year-select-vertical>
      </div>
    </div>

    <div v-if="!positionData.currentPosition" class="row md-columns top-20">
      <label class="col-xs-12">
        End Date <span class="red-text">*</span>
      </label>

      <div class="col-sm-6">
        <month-select-vertical
          :name="`firmEnd${positionNumber}Month`"
          :id="`firm-end-${positionNumber}-month`"
          :rules="monthToRules"
          :value="monthTo"
          @input="month => setMonth(positionData, 'toDate', month)">
        </month-select-vertical>
      </div>

      <div class="col-sm-6 top-20-xs">
        <year-select-vertical
          :name="`firmEnd${positionNumber}Year`"
          :id="`firm-end-${positionNumber}-year`"
          placeholder="Year"
          :rules="yearToRules"
          :value="yearTo"
          @input="year => setYear(positionData, 'toDate', year)">
        </year-select-vertical>
      </div>
    </div>

    <div class="top-10 bottom-20">
      <checkmark-checkbox
        :name="`firmCurrent${positionNumber}`"
        :id="`firm-current-${positionNumber}`"
        label="I currently work here"
        class="vertical medium-large"
        v-model="positionData.currentPosition"
        @change="currentPositionClicked">
      </checkmark-checkbox>
    </div>

    <div class="bottom-20">
      <textarea-input-vertical-with-word-count
        label="Description"
        :id="`firm-position-${positionNumber}`"
        :name="`firmPosition${positionNumber}`"
        :rows="4"
        :max-words="200"
        rules="required"
        v-model="positionData.positionDescription">
      </textarea-input-vertical-with-word-count>
    </div>

    <div class="row md-columns bottom-20">
      <label class="col-xs-12">
        Location
      </label>

      <div class="col-sm-4">
        <typeahead-vertical
          :input-name="`firmCountry${positionNumber}`"
          :id="`firm-country-${positionNumber}`"
          placeholder="Country"
          :initial-value="positionData.country"
          :options="countriesList"
          :force-select="true"
          :hide-icon="true"
          :rules="{ required: true, valueInOptions: { options: countriesList } }"
          v-model="positionData.country"
          @input="positionCountrySelected">
        </typeahead-vertical>
      </div>

      <div class="col-sm-4 top-10-xs">
        <typeahead-vertical
          :ref="`firmState${positionNumber}`"
          :disabled="!locationIsUnitedStates"
          :id="`firm-state-${positionNumber}`"
          :force-select="true"
          :hide-icon="true"
          :options="locationIsUnitedStates ? statesList : []"
          :placeholder="statePlaceHolder"
          :rules="stateValidationRules"
          :initial-value="positionData.state"
          v-model="positionData.state">
        </typeahead-vertical>
      </div>

      <div class="col-sm-4 top-10-xs">
        <text-input-vertical
          :id="`firm-city-${positionNumber}`"
          :name="`firmCity${positionNumber}`"
          placeholder="City"
          v-model="positionData.city"
          rules="required">
        </text-input-vertical>
      </div>
    </div>

    <div class="row md-columns bottom-20">
      <div class="col-sm-7">
        <validation-provider rules="required" v-slot="{ errors: dropdownErrors }" :mode="passiveAggressive">
          <dropdown-select
            label="What is the size of your law firm/company?"
            :id-label="`firm-size-${positionNumber}`"
            :name="`firmSize${positionNumber}`"
            :required="true"
            :invalid="!!dropdownErrors.length"
            :options="companySizes"
            :initial-label="positionData.firmSize"
            v-model="positionData.firmSize">
          </dropdown-select>

          <div v-if="dropdownErrors.length" class="error-text top-5">
            {{ dropdownErrors[0] }}
          </div>
        </validation-provider>
      </div>
    </div>

    <div class="row md-columns">
      <div class="col-sm-7">
        <text-input-vertical
          label="Website URL"
          :name="`webAddress${positionNumber}`"
          :id="`web-address-${positionNumber}`"
          v-model="positionData.webAddress">
        </text-input-vertical>
      </div>
    </div>
  </div>
</template>

<script>
import TextareaInputVerticalWithWordCount from 'vue-app/shared/components/textarea-input-vertical-with-word-count.vue';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import YearSelectVertical from 'vue-app/shared/components/year-select-vertical.vue';
import MonthSelectVertical from 'vue-app/shared/components/month-select-vertical.vue';
import CheckmarkCheckbox from 'vue-app/shared/components/checkmark-checkbox.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import SharedListsService from 'vue-app/shared/services/shared-lists-service.js';
import CountryState from 'vue-app/shared/services/country-state.js';
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'WorkHistoryFields',

  components: {
    TextareaInputVerticalWithWordCount,
    DropdownSelect,
    TypeaheadVertical,
    YearSelectVertical,
    MonthSelectVertical,
    CheckmarkCheckbox,
    TextInputVertical,
    ValidationProvider,
    SvgIcon
  },

  mixins: [
    interactionModes
  ],

  props: {
    positionData: {
      type: Object,
      required: true
    },

    positionNumber: {
      type: Number,
      required: true
    },

    canRemove: {
      type: Boolean,
      default: true
    },

    onRemovePosition: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      countryState: new CountryState(),
      companySizes: SharedListsService.companySizes
    };
  },

  computed: {
    countriesList() {
      return this.countryState.countries;
    },

    statesList() {
      return this.countryState.statesByCountry('United States').map(state => state.name);
    },

    locationIsUnitedStates() {
      return this.positionData.country === 'United States';
    },

    statePlaceHolder() {
      return this.locationIsUnitedStates ? 'State' : 'N/A';
    },

    stateValidationRules() {
      if (!this.locationIsUnitedStates) { return null; }
      return { required: true, valueInOptions: this.statesList };
    },

    monthFrom() {
      return this.getMonth(this.positionData.fromDate);
    },

    yearFrom() {
      return this.getYear(this.positionData.fromDate);
    },

    monthTo() {
      return this.getMonth(this.positionData.toDate);
    },

    yearTo() {
      return this.getYear(this.positionData.toDate);
    },

    monthFromRules() {
      if (!this.positionData.toDate || this.positionData.currentPosition) {
        return 'required';
      }
      else {
        return `required|maxDate:${this.yearTo > this.yearFrom ? 12 : this.monthTo}`;
      }
    },

    yearFromRules() {
      if (!this.positionData.toDate || this.positionData.currentPosition) {
        return 'required';
      }
      else {
        return this.yearTo ? `required|maxDate:${this.yearTo}` : 'required';
      }
    },

    monthToRules() {
      if (this.positionData.currentPosition) { return null; }

      if (!this.positionData.fromDate) {
        return 'required';
      }
      else {
        return `required|minDate:${this.yearFrom < this.yearTo ? 0 : this.monthFrom}`;
      }
    },

    yearToRules() {
      if (this.positionData.currentPosition) { return null; }

      if (!this.positionData.fromDate) {
        return 'required';
      }
      else {
        return this.yearFrom ? `required|minDate:${this.yearFrom}` : 'required';
      }
    }
  },

  methods: {
    positionCountrySelected() {
      if (!this.locationIsUnitedStates && this.positionData.state) {
        this.positionData.state = null;
        this.$refs[`firmState${this.positionNumber}`].reset();
      }
    },

    getYear(date) {
      return date ? Number(DateFilter.year(date)) : null;
    },

    setYear(positionData, field, year) {
      if (!positionData[field]) {
        positionData[field] = new Date((new Date()).getFullYear(), 0, 1);
      }

      const newDate = new Date(positionData[field]);

      newDate.setYear(year);
      newDate.setMonth(this.getMonth(positionData[field]) || 0);
      newDate.setDate(1);

      this.$set(positionData, field, newDate);
    },

    getMonth(date) {
      return date ? Number(DateFilter.month(date)) - 1 : null;
    },

    setMonth(positionData, field, month) {
      if (!positionData[field]) {
        positionData[field] = new Date();
      }

      const newDate = new Date(positionData[field]);

      newDate.setMonth(month);
      newDate.setDate(1);

      this.$set(positionData, field, newDate);
    },

    currentPositionClicked() {
      if (this.positionData.currentPosition) { this.positionData.toDate = null; }
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/globals/variables";

  .work-history-fields {
    padding: 20px;
    background-color: $gray-10;
    border: 1px solid $gray-7;
    border-radius: $border-radius-large;

    @media (min-width: $screen-sm-min) {
      padding: 30px;
    }
  }
</style>
