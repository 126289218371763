<template>
  <div class="row top-20">
    <div class="col-sm-10 col-sm-offset-1">
      <div class="shadowed-box">
        <div class="box-content variable-padded">
          <div class="bold-weight size-text-28px bottom-30">
            Welcome to Priori!
          </div>

          <div class="purple-label bold-weight bottom-30 size-text-15px">
            Great, we&rsquo;ve received your profile. Check your email for next steps!
          </div>

          <div class="bold-weight size-text-18px">
            Our Process
          </div>

          <hr>

          <div class="row equal-height">
            <div class="col-sm-6 d-flex bottom-30">
              <div class="gray-box flex-grow-1">
                <div class="purple-circle svg-container bottom-20">
                  <svg-icon name="file-up-arrow" class="responsive-svg"></svg-icon>
                </div>

                <div class="bold-weight size-text-15px bottom-5">
                  Submit Your Application
                </div>

                <div class="size-text-13px">
                  After you&rsquo;ve submitted your profile, you&rsquo;ll receive a confirmation email and be prompted to log in to Priori to complete a short application.
                </div>
              </div>
            </div>

            <div class="col-sm-6 d-flex bottom-30">
              <div class="gray-box flex-grow-1">
                <div class="purple-circle svg-container bottom-20">
                  <svg-icon name="video-call" class="responsive-svg"></svg-icon>
                </div>

                <div class="bold-weight size-text-15px bottom-5">
                  Video Call
                </div>

                <div class="size-text-13px">
                  After submitting your application and if you progress to the next stage, we&rsquo;ll set up a call so we can get to know you and answer questions about joining.
                </div>
              </div>
            </div>
          </div>

          <div class="row equal-height">
            <div class="col-sm-6 d-flex bottom-30">
              <div class="gray-box flex-grow-1">
                <div class="purple-circle svg-container bottom-20">
                  <svg-icon name="id-card" class="responsive-svg"></svg-icon>
                </div>

                <div class="bold-weight size-text-15px bottom-5">
                  Reference Check
                </div>

                <div class="size-text-13px">
                  Next, we&rsquo;ll need a reference from one attorney and one client you&rsquo;re working with or have worked with. We send them an email including a link for them to give you a review.
                </div>
              </div>
            </div>

            <div class="col-sm-6 d-flex bottom-30">
              <div class="gray-box flex-grow-1">
                <div class="purple-circle svg-container bottom-20">
                  <svg-icon name="check-circle" class="responsive-svg"></svg-icon>
                </div>

                <div class="bold-weight size-text-15px bottom-5">
                  Acceptance Notification
                </div>

                <div class="size-text-13px">
                  Once approved, you&rsquo;ll receive weekly project opportunity emails. You&rsquo;ll also be considered for projects that match your skill set and notified when a client wants to connect.
                </div>
              </div>
            </div>
          </div>

          <div class="size-text-18px bold-weight">
            Next Steps
          </div>

          <hr>

          <div class="bottom-30 size-text-14px">
            Below are the next steps for getting started and submitting your application. We will reach out to you via email to schedule your video call and reference check once you’ve submitted your application.
          </div>

          <div class="d-flex col-gap-20 bottom-30">
            <div class="purple-label nowrap bold-weight size-text-15px">
              Step 1
            </div>

            <div>
              <div class="size-text-15px bold-weight bottom-5">
                Confirm Email Address &amp; Add Priori to Your Contacts
              </div>

              <div class="size-text-14px">
                Please confirm your email address by clicking the link in the email we just sent you. Sometimes this email goes into spam or junk folders, so please check there if you don&rsquo;t see the email. While you&rsquo;re at it, please also add <a href="mailto:admin@priorilegal.com" class="pseudo-link-purple bold-weight">admin@priorilegal.com</a> to your contacts list to ensure that future emails arrive in the proper inbox.
              </div>
            </div>
          </div>

          <div class="d-flex col-gap-20 bottom-30">
            <div class="purple-label nowrap bold-weight size-text-15px">
              Step 2
            </div>

            <div>
              <div class="size-text-15px bold-weight bottom-5">
                Complete Application
              </div>

              <div class="size-text-14px">
                In order to process your application to the Priori network, we need to learn a bit about your legal practice, expertise and education. You must complete and submit your application in order to join Priori’s network of attorneys.
              </div>
            </div>
          </div>

          <div class="d-flex col-gap-20 bottom-30">
            <div class="purple-label nowrap bold-weight size-text-15px">
              Step 3
            </div>

            <div>
              <div class="size-text-15px bold-weight bottom-5">
                Save Progress, If Needed
              </div>

              <div class="size-text-14px">
                The application shouldn&rsquo;t take long to fill out, but if you need to pause at any time, you can always save your progress and return to the application later.
              </div>
            </div>
          </div>

          <div class="d-flex col-gap-20 bottom-30">
            <div class="purple-label nowrap bold-weight size-text-15px">
              Step 4
            </div>

            <div>
              <div class="size-text-15px bold-weight bottom-5">
                Learn More
              </div>

              <div class="size-text-14px">
                Please don&rsquo;t hesitate to message us if you have any questions as you complete the application. You can also learn more about our platform by checking out our <a href="https://docs.lawyer.priorilegal.com" class="pseudo-link-purple bold-weight">Guide</a>.
              </div>
            </div>
          </div>

          <div class="d-flex col-gap-20">
            <div class="purple-label nowrap bold-weight size-text-15px">
              Step 5
            </div>

            <div>
              <div class="size-text-15px bold-weight bottom-5">
                What&rsquo;s Next?
              </div>

              <div class="size-text-14px">
                After you submit your application, we&rsquo;ll review it, then reach out to you if we&rsquo;d like you to progress to set up a video call. If that goes well, we&rsquo;ll need a reference from one attorney and one client you&rsquo;re working with now or have worked with in the past. We simply shoot them an email including a link for them to give you a review.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'LawyerSignupSubmitted',

  components: {
    SvgIcon
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/globals/variables";

  hr {
    margin-top: 10px;
    border-color: $medium-gray;
  }

  .gray-box {
    background-color: $gray-10;
    padding: 20px;
    border: 1px solid $gray-7;
    border-radius: 6px
  }

  .row.equal-height {
    display: flex;
    flex-wrap: wrap;
  }

  .col-sm-6.d-flex {
    display: flex;
    flex-direction: column;
  }

  .flex-grow-1 {
    flex-grow: 1;
  }

  .equal-height {
    align-items: stretch;
  }

  .purple-circle {
    background: $purple-9;
    border-radius: 50%;
    height: 56px;
    width: 56px;
  }

  .svg-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .responsive-svg {
    width: 32px;
    height: 32px;
    stroke: $purple-5;
  }
</style>
