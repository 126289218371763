<template>
  <div class="school-fields">
    <div class="d-flex-not-xs flex-space-between bottom-20">
      <div class="bold-weight size-text-15px">
        School #{{ schoolNumber }} Details
      </div>

      <div v-if="schoolNumber > 1" class="minus-icon clickable top-20-xs" @click="onRemoveSchool(schoolNumber - 1)">
        <svg-icon name="minus-circle" class="base-icon right-10"></svg-icon><span class="size-text-13px semibold-weight">Remove</span>
      </div>
    </div>

    <div class="row md-columns bottom-20">
      <div class="col-sm-6">
        <label>School <span class="red-text">*</span></label>

        <typeahead-vertical
          placeholder="School Name"
          :id="`school-name-${schoolNumber}`"
          :input-name="`schoolName${schoolNumber}`"
          :options="schoolNames"
          :limit="8"
          :force-select="false"
          :hide-icon="true"
          v-model="school.name"
          :initial-value="school.name"
          rules="required"
          @input="onSchoolSelected">
        </typeahead-vertical>
      </div>

      <div class="col-sm-6 top-20-xs">
        <label>Degree <span class="red-text">*</span></label>

        <typeahead-vertical
          placeholder="Degree"
          :id="`school-degree-${schoolNumber}`"
          :input-name="`schoolDegree${schoolNumber}`"
          option-label-key="label"
          option-value-key="abbreviation"
          :options="degrees"
          :limit="8"
          :force-select="false"
          :hide-icon="true"
          v-model="school.degree"
          :initial-value="school.degree"
          rules="required">
        </typeahead-vertical>
      </div>
    </div>

    <div class="row md-columns bottom-20">
      <div class="col-sm-6">
        <year-select-vertical
          label="Start Year"
          :name="`schoolStart${schoolNumber}`"
          :id="`school-start-${schoolNumber}`"
          class="start-year"
          :rules="`required|maxDate:${getYear(school.dateAttained)}`"
          :value="getYear(school.dateEntered)"
          @input="year => setYear(school, 'dateEntered', year)">
        </year-select-vertical>
      </div>

      <div class="col-sm-6 top-20-xs">
        <year-select-vertical
          label="End Year"
          :name="`schoolEnd${schoolNumber}`"
          :id="`school-end-${schoolNumber}`"
          :rules="`required|minDate:${getYear(school.dateEntered)}`"
          :value="getYear(school.dateAttained)"
          @input="year => setYear(school, 'dateAttained', year)">
        </year-select-vertical>
      </div>
    </div>

    <textarea-input-vertical-with-word-count
      label="Achievements"
      :name="`schoolAwards${schoolNumber}`"
      :id="`school-awards-${schoolNumber}`"
      v-model="school.awards"
      :rows="3"
      :max-words="50">
    </textarea-input-vertical-with-word-count>
  </div>
</template>

<script>
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import YearSelectVertical from 'vue-app/shared/components/year-select-vertical.vue';
import SharedListsService from 'vue-app/shared/services/shared-lists-service.js';
import TextareaInputVerticalWithWordCount from 'vue-app/shared/components/textarea-input-vertical-with-word-count.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'SchoolFields',

  components: {
    TypeaheadVertical,
    YearSelectVertical,
    TextareaInputVerticalWithWordCount,
    SvgIcon
  },

  props: {
    school: {
      type: Object,
      required: true
    },

    schoolNumber: {
      type: Number,
      required: true
    },

    educationalInstitutes: {
      type: Array,
      required: true
    },

    onRemoveSchool: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      degrees: SharedListsService.degreesWithNames
    };
  },

  computed: {
    schoolNames() {
      return this.educationalInstitutes.map(institute => institute.name);
    }
  },

  methods: {
    onSchoolSelected(value) {
      const institute = this.educationalInstitutes.find(e => e.name === value);
      this.school.educationalInstituteId = (institute?.id || null);
    },

    getYear(date) {
      return date ? Number(DateFilter.year(date)) : null;
    },

    setYear(data, field, year) {
      data[field] = `${year}-01-01`;
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/globals/variables";

  .school-fields {
    padding: 20px;
    background-color: $gray-10;
    border: 1px solid $gray-7;
    border-radius: $border-radius-large;

    @media (min-width: $screen-sm-min) {
      padding: 30px;
    }
  }

  .start-year {
    :deep(.k-dropdown)::after {
      @media (min-width: $screen-sm-min) {
        content: '–';
        position: absolute;
        top: 9px;
        right: -14px;
      }
    }
  }
</style>
