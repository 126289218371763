<template>
  <validation-provider :rules="rules" v-slot="{ errors }" :mode="passiveAggressive">
    <label v-if="label" :for="id">{{ label }}<span v-if="required" class="red-text"> *</span></label>

    <dropdown-select
      :id-label="id"
      :class="{ 'has-error': errors.length, 'disabled': disabled }"
      label-key="label"
      value-key="key"
      :options="years"
      :placeholder="placeholder"
      :required="required"
      :invalid="!!errors.length"
      :initial-label="value?.toString()"
      :value="value"
      @change="onChange">
    </dropdown-select>

    <div v-if="errors.length && showErrorText">
      <slot name="errors" :errors="errors[0]">
        <div class="error-text top-5">
          {{ errors[0] }}
        </div>
      </slot>
    </div>
  </validation-provider>
</template>

<script>
import DropdownSelect         from 'vue-app/shared/components/dropdown-select.vue';
import interactionModes       from 'vue-app/shared/mixins/interaction-modes';
import { ValidationProvider } from 'vee-validate';
import { toNumber }           from 'lodash';

export default {
  name: 'YearSelectVertical',

  components: {
    ValidationProvider,
    DropdownSelect
  },

  mixins: [
    interactionModes
  ],

  props: {
    id: {
      type: String,
      required: false
    },

    label: {
      type: String,
      required: false
    },

    placeholder: {
      type: String,
      required: false
    },

    value: {
      type: Number,
      default: null
    },

    endingYear: {
      type: Number,
      default: new Date().getFullYear()
    },

    startingYear: {
      type: Number,
      default: new Date().getFullYear() - 100
    },

    showErrorText: {
      type: Boolean,
      default: true
    },

    rules: {
      type: String,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    required() {
      return this.rules?.includes('required');
    },

    years() {
      return Array.from({ length: (this.endingYear - this.startingYear) + 1 }, (_, i) => this.endingYear - i);
    }
  },

  methods: {
    onChange(value) {
      this.$emit('input', toNumber(value));
    }
  }
};
</script>
